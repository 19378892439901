@charset "UTF-8";
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-rgb: 33, 37, 41;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0087be;
  text-decoration: underline;
}
a:hover {
  color: #006c98;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0d6efd !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #198754 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
a.mat-button:hover, a.mat-raised-button:hover, a.mat-fab:hover, a.mat-mini-fab:hover, a.mat-list-item:hover {
  color: currentColor;
}

input,
button,
select,
optgroup,
textarea {
  line-height: normal;
}

:root {
  --ceres-primary-color-1: #009999;
  --ceres-primary-color-1-opaque: #ebfafa;
  --ceres-primary-color-2: #000028;
  --ceres-primary-color-3: #333353;
  --ceres-primary-color-4: #66667e;
  --ceres-primary-color-5: #9999a9;
  --ceres-primary-color-6: #ccccd4;
  --ceres-primary-color-7: #e5e5e9;
  --ceres-primary-color-8: #e9e9e9dd;
  --ceres-secondary-color-1: #00646e;
  --ceres-secondary-color-1-opacity-8: rgba(0, 100, 110, 0.8);
  --ceres-secondary-color-2: #00af8e;
  --ceres-secondary-color-3: #00d7a0;
  --ceres-secondary-color-4: #00ffb9;
  --ceres-secondary-color-5: #00557c;
  --ceres-secondary-color-5-opacity-8: rgba(0, 85, 124, 0.8);
  --ceres-secondary-color-6: #0087be;
  --ceres-secondary-color-6-opacity-8: rgba(0, 135, 190, 0.8);
  --ceres-secondary-color-7: #00bedc;
  --ceres-secondary-color-8: #00e6dc;
  --ceres-secondary-color-9: #500078;
  --ceres-secondary-color-10: #aa32be;
  --ceres-secondary-color-11: #ffd732;
  --ceres-secondary-color-12: #ec6602;
  --ceres-secondary-color-12-opacity-8: rgba(236, 102, 2, 0.8);
  --ceres-secondary-color-13: #ff9000;
  --ceres-secondary-color-13-opacity-5: rgba(255, 144, 0, 0.5);
  --ceres-secondary-color-14: #ef0137;
  --ceres-secondary-color-14-opaque-1: rgba(239, 1, 55, 0.1);
  --ceres-secondary-color-15: #fe8389;
  --ceres-secondary-color-16: #aaaa96;
  --ceres-secondary-color-17: #c5c5b8;
  --ceres-secondary-color-18: #dfdfd9;
  --ceres-secondary-color-19: #e5e5e9;
  --ceres-feedback-blue: #007eb1;
  --ceres-feedback-green: #01893a;
  --ceres-feedback-green-text: #018136;
  --ceres-feedback-yellow: #e9c32a;
  --ceres-feedback-orange: #e96401;
  --ceres-feedback-red: #d72339;
  --ceres-feedback-red-text: #b81e31;
  --ceres-input-background: #f6f6f6;
  --ceres-input: var(--ceres-primary-color-2);
  --ceres-footer-color: var(--ceres-primary-color-7);
  --ceres-sidenav-color: var(--ceres-primary-color-3);
  --ceres-wizard-background: var(--ceres-primary-color-6);
  --ceres-gradient-1-1: var(--ceres-secondary-color-4);
  --ceres-gradient-1-2: var(--ceres-secondary-color-8);
  --ceres-gradient-2-1: var(--ceres-secondary-color-3);
  --ceres-gradient-2-2: var(--ceres-secondary-color-7);
  --ceres-gradient-3-1: var(--ceres-primary-color-2);
  --ceres-gradient-3-2: var(--ceres-primary-color-1);
  --ceres-gradient-4-1: var(--ceres-primary-color-2);
  --ceres-gradient-4-2: var(--ceres-secondary-color-2);
  --ceres-gradient-5-1: var(--ceres-primary-color-2);
  --ceres-gradient-5-2: var(--ceres-secondary-color-6);
  --ceres-gradient-news: linear-gradient(93deg, transparent 50%, rgba(0, 0, 0, 0.5) 80%, transparent 90%);
  --ceres-opacity-pre-staffing: 0.5;
  --ceres-black: #000000;
  --ceres-white: #ffffff;
  --ceres-static-black: #000;
  --ceres-static-white: #fff;
  --ceres-white-opaque: rgba(256, 256, 256, 0.5);
  --ceres-black-opaque-0: rgba(0, 0, 0, 0);
  --ceres-black-opaque-05: rgba(0, 0, 0, 0.05);
  --ceres-black-opaque-1: rgba(0, 0, 0, 0.1);
  --ceres-black-opaque-12: rgba(0, 0, 0, 0.12);
  --ceres-black-opaque-14: rgba(0, 0, 0, 0.14);
  --ceres-black-opaque-2: rgba(0, 0, 0, 0.2);
  --ceres-black-opaque-25: rgba(0, 0, 0, 0.25);
  --ceres-black-opaque-35: rgba(0, 0, 0, 0.35);
  --ceres-black-opaque-5: rgba(0, 0, 0, 0.5);
  --ceres-black-opaque-54: rgba(0, 0, 0, 0.54);
  --ceres-black-opaque-57: rgba(0, 0, 0, 0.57);
  --ceres-black-opaque-6: rgba(0, 0, 0, 0.6);
  --ceres-classification-green-1: #ccffcc;
  --ceres-classification-green-2: #00ff00;
  --ceres-classification-green-3: #339966;
  --ceres-classification-green-4: #008000;
  --ceres-classification-green-5: #003300;
  --ceres-mat-select-background: #fff;
  --ceres-form-field-background: #f6f6f6;
  --ceres-slide-toggle-thumb: #fafafa;
  --ceres-slide-toggle-track: #00000061;
  --ceres-timesheets-header-background: #e5e5e9;
  --ceres-disabled-form-field-opacity: 0.5;
  --ceres-label-color: #4a4a4a;
}

[data-theme=dark] {
  --ceres-primary-color-1: #00ffb9;
  --ceres-primary-color-1-opaque: #172c40;
  --ceres-primary-color-2: #fff;
  --ceres-primary-color-3: #dfdfd9;
  --ceres-primary-color-4: #66667e;
  --ceres-primary-color-5: #fff;
  --ceres-primary-color-6: #ccccd4;
  --ceres-primary-color-7: #e5e5e9;
  --ceres-primary-color-8: #00183b;
  --ceres-secondary-color-1: #00646e;
  --ceres-secondary-color-1-opacity-8: rgba(0, 100, 110, 0.8);
  --ceres-secondary-color-2: #00af8e;
  --ceres-secondary-color-3: #00d7a0;
  --ceres-secondary-color-4: #00ffb9;
  --ceres-secondary-color-5: #00557c;
  --ceres-secondary-color-5-opacity-8: rgba(0, 85, 124, 0.8);
  --ceres-secondary-color-6: #0087be;
  --ceres-secondary-color-6-opacity-8: rgba(0, 135, 190, 0.8);
  --ceres-secondary-color-7: #00bedc;
  --ceres-secondary-color-8: #00e6dc;
  --ceres-secondary-color-9: #500078;
  --ceres-secondary-color-10: #aa32be;
  --ceres-secondary-color-11: #ffd732;
  --ceres-secondary-color-12: #ec6602;
  --ceres-secondary-color-12-opacity-8: rgba(236, 102, 2, 0.8);
  --ceres-secondary-color-13: #ff9000;
  --ceres-secondary-color-13-opacity-5: rgba(255, 144, 0, 0.5);
  --ceres-secondary-color-14: #ef0137;
  --ceres-secondary-color-14-opaque-1: rgba(239, 1, 55, 0.1);
  --ceres-secondary-color-15: #fe8389;
  --ceres-secondary-color-16: #aaaa96;
  --ceres-secondary-color-17: #c5c5b8;
  --ceres-secondary-color-18: #dfdfd9;
  --ceres-secondary-color-19: #030d31;
  --ceres-input-background: #f6f6f626;
  --ceres-input: #fff;
  --ceres-footer-color: #00183b;
  --ceres-sidenav-color: #00183b;
  --ceres-wizard-background: var(--ceres-primary-color-6);
  --ceres-opacity-pre-staffing: 0.9;
  --ceres-white: #000228;
  --ceres-black: #ffffff;
  --ceres-feedback-blue: #00bedc;
  --ceres-feedback-green: #01d65a;
  --ceres-feedback-green-text: #01d65a;
  --ceres-feedback-yellow: #ffd732;
  --ceres-feedback-orange: #ff9000;
  --ceres-feedback-red: #ff2640;
  --ceres-feedback-red-text: #ff7687;
  --ceres-mat-select-background: #424242;
  --ceres-form-field-background: #f6f6f626;
  --ceres-slide-toggle-thumb: #bdbdbd;
  --ceres-slide-toggle-track: #ffffff80;
  --ceres-timesheets-header-background: #000228;
  --ceres-disabled-form-field-opacity: 0.06;
  --ceres-label-color: #ffffff99;
}

html {
  --mat-badge-text-font: Siemens Sans;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1,
.mat-typography .h1 {
  font: 400 24px / 32px Siemens Sans;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2,
.mat-typography .h2 {
  font: 500 20px / 32px Siemens Sans;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3,
.mat-typography .h3 {
  font: 400 16px / 28px Siemens Sans;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4,
.mat-typography .h4 {
  font: 400 14px / 20px Siemens Sans;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5,
.mat-typography .h5 {
  font: 400 calc(14px * 0.83) / 20px Siemens Sans;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6,
.mat-typography .h6 {
  font: 400 calc(14px * 0.67) / 20px Siemens Sans;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font: 500 14px / 22px Siemens Sans;
  letter-spacing: normal;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font: 400 14px / 20px Siemens Sans;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Siemens Sans;
  letter-spacing: normal;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font: 300 96px / 96px Siemens Sans;
  letter-spacing: normal;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font: 300 60px / 60px Siemens Sans;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font: 400 48px / 48px Siemens Sans;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font: 400 34px / 40px Siemens Sans;
  letter-spacing: normal;
  margin: 0 0 64px;
}

html {
  --mat-bottom-sheet-container-text-font: Siemens Sans;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-label-text-font: Siemens Sans;
  --mat-legacy-button-toggle-label-text-line-height: 20px;
  --mat-legacy-button-toggle-label-text-size: 14px;
  --mat-legacy-button-toggle-label-text-tracking: normal;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: Siemens Sans;
  --mat-standard-button-toggle-label-text-line-height: 20px;
  --mat-standard-button-toggle-label-text-size: 14px;
  --mat-standard-button-toggle-label-text-tracking: normal;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-text-font: Siemens Sans;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-expansion-header-text-font: Siemens Sans;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Siemens Sans;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-stepper-container-text-font: Siemens Sans;
  --mat-stepper-header-label-text-font: Siemens Sans;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-toolbar-title-text-font: Siemens Sans;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: normal;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-node-text-font: Siemens Sans;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}

html {
  --mat-option-label-text-font: Siemens Sans;
  --mat-option-label-text-line-height: 20px;
  --mat-option-label-text-size: 14px;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Siemens Sans;
  --mat-optgroup-label-text-line-height: 20px;
  --mat-optgroup-label-text-size: 14px;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mat-card-title-text-font: Siemens Sans;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: normal;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Siemens Sans;
  --mat-card-subtitle-text-line-height: 22px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: normal;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Siemens Sans;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
}

html {
  --mdc-filled-text-field-label-text-font: Siemens Sans;
  --mdc-filled-text-field-label-text-size: 14px;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Siemens Sans;
  --mdc-outlined-text-field-label-text-size: 14px;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Siemens Sans;
  --mat-form-field-container-text-line-height: 20px;
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 14px;
  --mat-form-field-subscript-text-font: Siemens Sans;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: normal;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-trigger-text-font: Siemens Sans;
  --mat-select-trigger-text-line-height: 20px;
  --mat-select-trigger-text-size: 14px;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mdc-dialog-subhead-font: Siemens Sans;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: Siemens Sans;
  --mdc-dialog-supporting-text-line-height: 20px;
  --mdc-dialog-supporting-text-size: 14px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Siemens Sans;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: normal;
  --mdc-chip-label-text-weight: 400;
}

html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-font: Siemens Sans;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: normal;
  --mdc-form-field-label-text-weight: 400;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-font: Siemens Sans;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: normal;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-slider-label-label-text-font: Siemens Sans;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: normal;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-item-label-text-font: Siemens Sans;
  --mat-menu-item-label-text-size: 14px;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: 20px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-label-text-font: Siemens Sans;
  --mdc-list-list-item-label-text-line-height: 20px;
  --mdc-list-list-item-label-text-size: 14px;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Siemens Sans;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Siemens Sans;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: normal;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 16px / 28px Siemens Sans;
  letter-spacing: normal;
}

html {
  --mat-paginator-container-text-font: Siemens Sans;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: normal;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Siemens Sans;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: normal;
  --mat-tab-header-label-text-line-height: 14px;
  --mat-tab-header-label-text-weight: 500;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-font: Siemens Sans;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: normal;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-text-button-label-text-font: Siemens Sans;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: normal;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: Siemens Sans;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: normal;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: Siemens Sans;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: normal;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: Siemens Sans;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: normal;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
}

html {
  --mdc-extended-fab-label-text-font: Siemens Sans;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: normal;
  --mdc-extended-fab-label-text-weight: 500;
}

html {
  --mdc-snackbar-supporting-text-font: Siemens Sans;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-header-headline-font: Siemens Sans;
  --mat-table-header-headline-line-height: 22px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: normal;
  --mat-table-row-item-label-text-font: Siemens Sans;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-font: Siemens Sans;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: normal;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, transparent);
  color: var(--mat-app-text-color, inherit);
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #009688;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #009688;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #009688;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #009688;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #009688;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #009688;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #009688;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #009688;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #009688;
  --mdc-linear-progress-track-color: rgba(0, 150, 136, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #009688;
  --mdc-linear-progress-track-color: rgba(0, 150, 136, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: #009688;
  --mdc-filled-text-field-focus-active-indicator-color: #009688;
  --mdc-filled-text-field-focus-label-text-color: rgba(0, 150, 136, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #f44336;
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #009688;
  --mdc-outlined-text-field-focus-outline-color: #009688;
  --mdc-outlined-text-field-focus-label-text-color: rgba(0, 150, 136, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-error-hover-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-focus-select-arrow-color: rgba(0, 150, 136, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #f44336;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #009688;
  --mdc-filled-text-field-focus-active-indicator-color: #009688;
  --mdc-filled-text-field-focus-label-text-color: rgba(0, 150, 136, 0.87);
  --mdc-outlined-text-field-caret-color: #009688;
  --mdc-outlined-text-field-focus-outline-color: #009688;
  --mdc-outlined-text-field-focus-label-text-color: rgba(0, 150, 136, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(0, 150, 136, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(0, 150, 136, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(0, 150, 136, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #009688;
  --mdc-chip-elevated-selected-container-color: #009688;
  --mdc-chip-elevated-disabled-container-color: #009688;
  --mdc-chip-flat-disabled-selected-container-color: #009688;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #009688;
  --mdc-chip-elevated-selected-container-color: #009688;
  --mdc-chip-elevated-disabled-container-color: #009688;
  --mdc-chip-flat-disabled-selected-container-color: #009688;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-selected-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-flat-disabled-selected-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-selected-focus-state-layer-color: #00897b;
  --mdc-switch-selected-handle-color: #00897b;
  --mdc-switch-selected-hover-state-layer-color: #00897b;
  --mdc-switch-selected-pressed-state-layer-color: #00897b;
  --mdc-switch-selected-focus-handle-color: #004d40;
  --mdc-switch-selected-hover-handle-color: #004d40;
  --mdc-switch-selected-pressed-handle-color: #004d40;
  --mdc-switch-selected-focus-track-color: #4db6ac;
  --mdc-switch-selected-hover-track-color: #4db6ac;
  --mdc-switch-selected-pressed-track-color: #4db6ac;
  --mdc-switch-selected-track-color: #4db6ac;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #00897b;
  --mdc-switch-selected-handle-color: #00897b;
  --mdc-switch-selected-hover-state-layer-color: #00897b;
  --mdc-switch-selected-pressed-state-layer-color: #00897b;
  --mdc-switch-selected-focus-handle-color: #004d40;
  --mdc-switch-selected-hover-handle-color: #004d40;
  --mdc-switch-selected-pressed-handle-color: #004d40;
  --mdc-switch-selected-focus-track-color: #4db6ac;
  --mdc-switch-selected-hover-track-color: #4db6ac;
  --mdc-switch-selected-pressed-track-color: #4db6ac;
  --mdc-switch-selected-track-color: #4db6ac;
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

html {
  --mdc-switch-state-layer-size: 40px;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #009688;
  --mdc-radio-selected-hover-icon-color: #009688;
  --mdc-radio-selected-icon-color: #009688;
  --mdc-radio-selected-pressed-icon-color: #009688;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #009688;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #009688;
  --mdc-radio-selected-hover-icon-color: #009688;
  --mdc-radio-selected-icon-color: #009688;
  --mdc-radio-selected-pressed-icon-color: #009688;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #009688;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-radio-state-layer-size: 40px;
  --mat-radio-touch-target-display: block;
}

html {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
}

html {
  --mdc-slider-handle-color: #009688;
  --mdc-slider-focus-handle-color: #009688;
  --mdc-slider-hover-handle-color: #009688;
  --mdc-slider-active-track-color: #009688;
  --mdc-slider-inactive-track-color: #009688;
  --mdc-slider-with-tick-marks-inactive-container-color: #009688;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #009688;
  --mat-slider-hover-state-layer-color: rgba(0, 150, 136, 0.05);
  --mat-slider-focus-state-layer-color: rgba(0, 150, 136, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mat-slider-ripple-color: #009688;
  --mat-slider-hover-state-layer-color: rgba(0, 150, 136, 0.05);
  --mat-slider-focus-state-layer-color: rgba(0, 150, 136, 0.2);
  --mdc-slider-handle-color: #009688;
  --mdc-slider-focus-handle-color: #009688;
  --mdc-slider-hover-handle-color: #009688;
  --mdc-slider-active-track-color: #009688;
  --mdc-slider-inactive-track-color: #009688;
  --mdc-slider-with-tick-marks-inactive-container-color: #009688;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
html .mat-warn {
  --mat-slider-ripple-color: #f44336;
  --mat-slider-hover-state-layer-color: rgba(244, 67, 54, 0.05);
  --mat-slider-focus-state-layer-color: rgba(244, 67, 54, 0.2);
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: white;
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #009688;
  --mdc-radio-selected-hover-icon-color: #009688;
  --mdc-radio-selected-icon-color: #009688;
  --mdc-radio-selected-pressed-icon-color: #009688;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #009688;
  --mdc-radio-selected-hover-icon-color: #009688;
  --mdc-radio-selected-icon-color: #009688;
  --mdc-radio-selected-pressed-icon-color: #009688;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #009688;
  --mdc-checkbox-selected-hover-icon-color: #009688;
  --mdc-checkbox-selected-icon-color: #009688;
  --mdc-checkbox-selected-pressed-icon-color: #009688;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #009688;
  --mdc-checkbox-selected-hover-state-layer-color: #009688;
  --mdc-checkbox-selected-pressed-state-layer-color: #009688;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #009688;
  --mdc-checkbox-selected-hover-icon-color: #009688;
  --mdc-checkbox-selected-icon-color: #009688;
  --mdc-checkbox-selected-pressed-icon-color: #009688;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #009688;
  --mdc-checkbox-selected-hover-state-layer-color: #009688;
  --mdc-checkbox-selected-pressed-state-layer-color: #009688;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #009688;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #009688;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
}

html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #009688;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #009688;
  --mat-tab-header-active-ripple-color: #009688;
  --mat-tab-header-inactive-ripple-color: #009688;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #009688;
  --mat-tab-header-active-hover-label-text-color: #009688;
  --mat-tab-header-active-focus-indicator-color: #009688;
  --mat-tab-header-active-hover-indicator-color: #009688;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #009688;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #009688;
  --mat-tab-header-active-ripple-color: #009688;
  --mat-tab-header-inactive-ripple-color: #009688;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #009688;
  --mat-tab-header-active-hover-label-text-color: #009688;
  --mat-tab-header-active-focus-indicator-color: #009688;
  --mat-tab-header-active-hover-indicator-color: #009688;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #009688;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #009688;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #009688;
  --mdc-checkbox-selected-hover-icon-color: #009688;
  --mdc-checkbox-selected-icon-color: #009688;
  --mdc-checkbox-selected-pressed-icon-color: #009688;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #009688;
  --mdc-checkbox-selected-hover-state-layer-color: #009688;
  --mdc-checkbox-selected-pressed-state-layer-color: #009688;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #009688;
  --mdc-checkbox-selected-hover-icon-color: #009688;
  --mdc-checkbox-selected-icon-color: #009688;
  --mdc-checkbox-selected-pressed-icon-color: #009688;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #009688;
  --mdc-checkbox-selected-hover-state-layer-color: #009688;
  --mdc-checkbox-selected-pressed-state-layer-color: #009688;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-touch-target-display: block;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #009688;
  --mat-text-button-state-layer-color: #009688;
  --mat-text-button-ripple-color: rgba(0, 150, 136, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #009688;
  --mat-text-button-state-layer-color: #009688;
  --mat-text-button-ripple-color: rgba(0, 150, 136, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #009688;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #009688;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #009688;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #009688;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #009688;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #009688;
  --mat-outlined-button-ripple-color: rgba(0, 150, 136, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #009688;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #009688;
  --mat-outlined-button-ripple-color: rgba(0, 150, 136, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mdc-text-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
  --mat-text-button-touch-target-display: block;
  --mat-filled-button-touch-target-display: block;
  --mat-protected-button-touch-target-display: block;
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #009688;
  --mat-icon-button-state-layer-color: #009688;
  --mat-icon-button-ripple-color: rgba(0, 150, 136, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #009688;
  --mat-icon-button-state-layer-color: #009688;
  --mat-icon-button-ripple-color: rgba(0, 150, 136, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-icon-button-state-layer-color: #f44336;
  --mat-icon-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mat-icon-button-touch-target-display: block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
}

html {
  --mdc-fab-container-color: white;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
}
html .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #009688;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #009688;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #009688;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #009688;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #f44336;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mat-fab-touch-target-display: block;
  --mat-fab-small-touch-target-display: block;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #009688;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #009688;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #009688;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-background-color: #009688;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #009688;
  --mat-badge-text-color: white;
}

.mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #009688;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 150, 136, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(0, 150, 136, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(0, 150, 136, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #009688;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(0, 150, 136, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #009688;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 150, 136, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(0, 150, 136, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(0, 150, 136, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(0, 150, 136, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #009688;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #009688;
}
.mat-icon.mat-accent {
  --mat-icon-color: #009688;
}
.mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #009688;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #009688;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #009688;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #009688;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #009688;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #009688;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-sort-arrow-color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #009688;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #009688;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-tree-node-min-height: 48px;
}

[data-theme=dark] {
  --mat-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-option-selected-state-label-text-color: #64ffda;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-optgroup-label-text-color: white;
  --mat-full-pseudo-checkbox-selected-icon-color: #64ffda;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64ffda;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  --mat-app-background-color: #303030;
  --mat-app-text-color: white;
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: #424242;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #424242;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(255, 255, 255, 0.7);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #64ffda;
  --mdc-filled-text-field-focus-active-indicator-color: #64ffda;
  --mdc-filled-text-field-focus-label-text-color: rgba(100, 255, 218, 0.87);
  --mdc-filled-text-field-container-color: #4a4a4a;
  --mdc-filled-text-field-disabled-container-color: #464646;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #f44336;
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(255, 255, 255, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(255, 255, 255, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #64ffda;
  --mdc-outlined-text-field-focus-outline-color: #64ffda;
  --mdc-outlined-text-field-focus-label-text-color: rgba(100, 255, 218, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-error-hover-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(255, 255, 255, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-focus-select-arrow-color: rgba(100, 255, 218, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(255, 255, 255, 0.38);
  --mat-form-field-state-layer-color: rgba(255, 255, 255, 0.87);
  --mat-form-field-error-text-color: #f44336;
  --mat-form-field-select-option-text-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-select-disabled-option-text-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-focus-state-layer-opacity: 0.24;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(100, 255, 218, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: #424242;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: #424242;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: #4db6ac;
  --mdc-switch-selected-handle-color: #4db6ac;
  --mdc-switch-selected-hover-state-layer-color: #4db6ac;
  --mdc-switch-selected-pressed-state-layer-color: #4db6ac;
  --mdc-switch-selected-focus-handle-color: #80cbc4;
  --mdc-switch-selected-hover-handle-color: #80cbc4;
  --mdc-switch-selected-pressed-handle-color: #80cbc4;
  --mdc-switch-selected-focus-track-color: #00897b;
  --mdc-switch-selected-hover-track-color: #00897b;
  --mdc-switch-selected-pressed-track-color: #00897b;
  --mdc-switch-selected-track-color: #00897b;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: #64ffda;
  --mdc-slider-focus-handle-color: #64ffda;
  --mdc-slider-hover-handle-color: #64ffda;
  --mdc-slider-active-track-color: #64ffda;
  --mdc-slider-inactive-track-color: #64ffda;
  --mdc-slider-with-tick-marks-inactive-container-color: #64ffda;
  --mdc-slider-with-tick-marks-active-container-color: rgba(0, 0, 0, 0.87);
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-label-container-color: #fff;
  --mdc-slider-label-label-text-color: #000;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #64ffda;
  --mat-slider-hover-state-layer-color: rgba(100, 255, 218, 0.05);
  --mat-slider-focus-state-layer-color: rgba(100, 255, 218, 0.2);
  --mat-slider-value-indicator-opacity: 0.9;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #424242;
  --mat-menu-divider-color: rgba(255, 255, 255, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: rgba(255, 255, 255, 0.7);
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #424242;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: black;
  --mdc-checkbox-selected-focus-icon-color: #64ffda;
  --mdc-checkbox-selected-hover-icon-color: #64ffda;
  --mdc-checkbox-selected-icon-color: #64ffda;
  --mdc-checkbox-selected-pressed-icon-color: #64ffda;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64ffda;
  --mdc-checkbox-selected-hover-state-layer-color: #64ffda;
  --mdc-checkbox-selected-pressed-state-layer-color: #64ffda;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
  --mat-checkbox-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: white;
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mat-text-button-state-layer-color: white;
  --mat-text-button-disabled-state-layer-color: white;
  --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.24;
  --mat-text-button-pressed-state-layer-opacity: 0.24;
  --mdc-filled-button-container-color: #424242;
  --mdc-filled-button-label-text-color: white;
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-disabled-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.24;
  --mat-filled-button-pressed-state-layer-opacity: 0.24;
  --mdc-protected-button-container-color: #424242;
  --mdc-protected-button-label-text-color: white;
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-disabled-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.24;
  --mat-protected-button-pressed-state-layer-opacity: 0.24;
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-label-text-color: white;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: white;
  --mat-outlined-button-disabled-state-layer-color: white;
  --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.24;
  --mat-outlined-button-pressed-state-layer-opacity: 0.24;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
  --mat-icon-button-state-layer-color: white;
  --mat-icon-button-disabled-state-layer-color: white;
  --mat-icon-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.24;
  --mat-icon-button-pressed-state-layer-opacity: 0.24;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-fab-container-color: #424242;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-disabled-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.24;
  --mat-fab-pressed-state-layer-opacity: 0.24;
  --mat-fab-disabled-state-container-color: rgba(255, 255, 255, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(255, 255, 255, 0.5);
  --mdc-fab-small-container-color: #424242;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-disabled-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.24;
  --mat-fab-small-pressed-state-layer-opacity: 0.24;
  --mat-fab-small-disabled-state-container-color: rgba(255, 255, 255, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(255, 255, 255, 0.5);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #d9d9d9;
  --mdc-snackbar-supporting-text-color: rgba(66, 66, 66, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: #424242;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #64ffda;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #64ffda;
  --mat-badge-text-color: rgba(0, 0, 0, 0.87);
  --mat-badge-disabled-state-background-color: #6e6e6e;
  --mat-badge-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #424242;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: rgba(255, 255, 255, 0.5);
  --mat-legacy-button-toggle-state-layer-color: rgba(255, 255, 255, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(255, 255, 255, 0.7);
  --mat-legacy-button-toggle-selected-state-background-color: #212121;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-legacy-button-toggle-disabled-state-background-color: black;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #424242;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #424242;
  --mat-standard-button-toggle-state-layer-color: white;
  --mat-standard-button-toggle-selected-state-background-color: #212121;
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-standard-button-toggle-disabled-state-background-color: #424242;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #424242;
  --mat-standard-button-toggle-divider-color: #595959;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-selected-state-background-color: #64ffda;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(100, 255, 218, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(100, 255, 218, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(100, 255, 218, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #64ffda;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(100, 255, 218, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: white;
  --mat-datepicker-calendar-body-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-period-button-text-color: white;
  --mat-datepicker-calendar-period-button-icon-color: white;
  --mat-datepicker-calendar-navigation-button-icon-color: white;
  --mat-datepicker-calendar-header-divider-color: rgba(255, 255, 255, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-date-today-outline-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(255, 255, 255, 0.3);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(255, 255, 255, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-container-background-color: #424242;
  --mat-datepicker-calendar-container-text-color: white;
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(255, 255, 255, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: #424242;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(255, 255, 255, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: rgba(255, 255, 255, 0.7);
  --mat-expansion-header-indicator-color: rgba(255, 255, 255, 0.7);
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(255, 255, 255, 0.12);
  --mat-sidenav-container-background-color: #424242;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #303030;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(189, 189, 189, 0.6);
  --mat-stepper-header-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-selected-state-icon-background-color: #64ffda;
  --mat-stepper-header-selected-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-done-state-icon-background-color: #64ffda;
  --mat-stepper-header-done-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-edit-state-icon-background-color: #64ffda;
  --mat-stepper-header-edit-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-container-color: #424242;
  --mat-stepper-line-color: rgba(255, 255, 255, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-optional-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #c6c6c6;
  --mat-toolbar-container-background-color: #212121;
  --mat-toolbar-container-text-color: white;
  --mat-tree-container-background-color: #424242;
  --mat-tree-node-text-color: white;
}
[data-theme=dark] .mat-accent {
  --mat-option-selected-state-label-text-color: #64ffda;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
[data-theme=dark] .mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
[data-theme=dark] .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #64ffda;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64ffda;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
[data-theme=dark] .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #64ffda;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #64ffda;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
[data-theme=dark] .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
[data-theme=dark] .mat-elevation-z0, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
[data-theme=dark] .mat-elevation-z1, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
[data-theme=dark] .mat-elevation-z2, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
[data-theme=dark] .mat-elevation-z3, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
[data-theme=dark] .mat-elevation-z4, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
[data-theme=dark] .mat-elevation-z5, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
[data-theme=dark] .mat-elevation-z6, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
[data-theme=dark] .mat-elevation-z7, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
[data-theme=dark] .mat-elevation-z8, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
[data-theme=dark] .mat-elevation-z9, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
[data-theme=dark] .mat-elevation-z10, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
[data-theme=dark] .mat-elevation-z11, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
[data-theme=dark] .mat-elevation-z12, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
[data-theme=dark] .mat-elevation-z13, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
[data-theme=dark] .mat-elevation-z14, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
[data-theme=dark] .mat-elevation-z15, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
[data-theme=dark] .mat-elevation-z16, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
[data-theme=dark] .mat-elevation-z17, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
[data-theme=dark] .mat-elevation-z18, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
[data-theme=dark] .mat-elevation-z19, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
[data-theme=dark] .mat-elevation-z20, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
[data-theme=dark] .mat-elevation-z21, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
[data-theme=dark] .mat-elevation-z22, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
[data-theme=dark] .mat-elevation-z23, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
[data-theme=dark] .mat-elevation-z24, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

[data-theme=dark] .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #64ffda;
  --mdc-linear-progress-track-color: rgba(100, 255, 218, 0.25);
}
[data-theme=dark] .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #64ffda;
  --mdc-linear-progress-track-color: rgba(100, 255, 218, 0.25);
}
[data-theme=dark] .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}
[data-theme=dark] .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #64ffda;
  --mdc-filled-text-field-focus-active-indicator-color: #64ffda;
  --mdc-filled-text-field-focus-label-text-color: rgba(100, 255, 218, 0.87);
  --mdc-outlined-text-field-caret-color: #64ffda;
  --mdc-outlined-text-field-focus-outline-color: #64ffda;
  --mdc-outlined-text-field-focus-label-text-color: rgba(100, 255, 218, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(100, 255, 218, 0.87);
}
[data-theme=dark] .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(244, 67, 54, 0.87);
}
[data-theme=dark] .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(100, 255, 218, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
[data-theme=dark] .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
[data-theme=dark] .mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
[data-theme=dark] .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #595959;
  --mdc-chip-elevated-selected-container-color: #595959;
  --mdc-chip-elevated-disabled-container-color: #595959;
  --mdc-chip-flat-disabled-selected-container-color: #595959;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-selected-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mat-chip-selected-disabled-trailing-icon-color: #fafafa;
  --mat-chip-selected-trailing-icon-color: #fafafa;
}
[data-theme=dark] .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, [data-theme=dark] .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-elevated-container-color: #64ffda;
  --mdc-chip-elevated-selected-container-color: #64ffda;
  --mdc-chip-elevated-disabled-container-color: #64ffda;
  --mdc-chip-flat-disabled-selected-container-color: #64ffda;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-selected-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-disabled-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mat-chip-selected-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mat-chip-selected-trailing-icon-color: rgba(0, 0, 0, 0.87);
}
[data-theme=dark] .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, [data-theme=dark] .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-elevated-container-color: #64ffda;
  --mdc-chip-elevated-selected-container-color: #64ffda;
  --mdc-chip-elevated-disabled-container-color: #64ffda;
  --mdc-chip-flat-disabled-selected-container-color: #64ffda;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-selected-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-disabled-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mat-chip-selected-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mat-chip-selected-trailing-icon-color: rgba(0, 0, 0, 0.87);
}
[data-theme=dark] .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, [data-theme=dark] .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-selected-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-flat-disabled-selected-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
[data-theme=dark] .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: white;
}
[data-theme=dark] .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #4db6ac;
  --mdc-switch-selected-handle-color: #4db6ac;
  --mdc-switch-selected-hover-state-layer-color: #4db6ac;
  --mdc-switch-selected-pressed-state-layer-color: #4db6ac;
  --mdc-switch-selected-focus-handle-color: #80cbc4;
  --mdc-switch-selected-hover-handle-color: #80cbc4;
  --mdc-switch-selected-pressed-handle-color: #80cbc4;
  --mdc-switch-selected-focus-track-color: #00897b;
  --mdc-switch-selected-hover-track-color: #00897b;
  --mdc-switch-selected-pressed-track-color: #00897b;
  --mdc-switch-selected-track-color: #00897b;
}
[data-theme=dark] .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e57373;
  --mdc-switch-selected-handle-color: #e57373;
  --mdc-switch-selected-hover-state-layer-color: #e57373;
  --mdc-switch-selected-pressed-state-layer-color: #e57373;
  --mdc-switch-selected-focus-handle-color: #ef9a9a;
  --mdc-switch-selected-hover-handle-color: #ef9a9a;
  --mdc-switch-selected-pressed-handle-color: #ef9a9a;
  --mdc-switch-selected-focus-track-color: #e53935;
  --mdc-switch-selected-hover-track-color: #e53935;
  --mdc-switch-selected-pressed-track-color: #e53935;
  --mdc-switch-selected-track-color: #e53935;
}
[data-theme=dark] .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: white;
}
[data-theme=dark] .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #64ffda;
  --mdc-radio-selected-hover-icon-color: #64ffda;
  --mdc-radio-selected-icon-color: #64ffda;
  --mdc-radio-selected-pressed-icon-color: #64ffda;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #64ffda;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
[data-theme=dark] .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #64ffda;
  --mdc-radio-selected-hover-icon-color: #64ffda;
  --mdc-radio-selected-icon-color: #64ffda;
  --mdc-radio-selected-pressed-icon-color: #64ffda;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #64ffda;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
[data-theme=dark] .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
[data-theme=dark] .mat-accent {
  --mat-slider-ripple-color: #64ffda;
  --mat-slider-hover-state-layer-color: rgba(100, 255, 218, 0.05);
  --mat-slider-focus-state-layer-color: rgba(100, 255, 218, 0.2);
  --mdc-slider-handle-color: #64ffda;
  --mdc-slider-focus-handle-color: #64ffda;
  --mdc-slider-hover-handle-color: #64ffda;
  --mdc-slider-active-track-color: #64ffda;
  --mdc-slider-inactive-track-color: #64ffda;
  --mdc-slider-with-tick-marks-inactive-container-color: #64ffda;
  --mdc-slider-with-tick-marks-active-container-color: rgba(0, 0, 0, 0.87);
}
[data-theme=dark] .mat-warn {
  --mat-slider-ripple-color: #f44336;
  --mat-slider-hover-state-layer-color: rgba(244, 67, 54, 0.05);
  --mat-slider-focus-state-layer-color: rgba(244, 67, 54, 0.2);
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
[data-theme=dark] .mdc-list-item__start,
[data-theme=dark] .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #64ffda;
  --mdc-radio-selected-hover-icon-color: #64ffda;
  --mdc-radio-selected-icon-color: #64ffda;
  --mdc-radio-selected-pressed-icon-color: #64ffda;
}
[data-theme=dark] .mat-accent .mdc-list-item__start,
[data-theme=dark] .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #64ffda;
  --mdc-radio-selected-hover-icon-color: #64ffda;
  --mdc-radio-selected-icon-color: #64ffda;
  --mdc-radio-selected-pressed-icon-color: #64ffda;
}
[data-theme=dark] .mat-warn .mdc-list-item__start,
[data-theme=dark] .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}
[data-theme=dark] .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: black;
  --mdc-checkbox-selected-focus-icon-color: #64ffda;
  --mdc-checkbox-selected-hover-icon-color: #64ffda;
  --mdc-checkbox-selected-icon-color: #64ffda;
  --mdc-checkbox-selected-pressed-icon-color: #64ffda;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64ffda;
  --mdc-checkbox-selected-hover-state-layer-color: #64ffda;
  --mdc-checkbox-selected-pressed-state-layer-color: #64ffda;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
[data-theme=dark] .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: black;
  --mdc-checkbox-selected-focus-icon-color: #64ffda;
  --mdc-checkbox-selected-hover-icon-color: #64ffda;
  --mdc-checkbox-selected-icon-color: #64ffda;
  --mdc-checkbox-selected-pressed-icon-color: #64ffda;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64ffda;
  --mdc-checkbox-selected-hover-state-layer-color: #64ffda;
  --mdc-checkbox-selected-pressed-state-layer-color: #64ffda;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
[data-theme=dark] .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
[data-theme=dark] .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
[data-theme=dark] .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #64ffda;
}
[data-theme=dark] .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
[data-theme=dark] .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #64ffda;
}
[data-theme=dark] .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
[data-theme=dark] .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
[data-theme=dark] .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
[data-theme=dark] .mat-mdc-tab-group, [data-theme=dark] .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #64ffda;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #64ffda;
  --mat-tab-header-active-ripple-color: #64ffda;
  --mat-tab-header-inactive-ripple-color: #64ffda;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #64ffda;
  --mat-tab-header-active-hover-label-text-color: #64ffda;
  --mat-tab-header-active-focus-indicator-color: #64ffda;
  --mat-tab-header-active-hover-indicator-color: #64ffda;
}
[data-theme=dark] .mat-mdc-tab-group.mat-accent, [data-theme=dark] .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #64ffda;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #64ffda;
  --mat-tab-header-active-ripple-color: #64ffda;
  --mat-tab-header-inactive-ripple-color: #64ffda;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #64ffda;
  --mat-tab-header-active-hover-label-text-color: #64ffda;
  --mat-tab-header-active-focus-indicator-color: #64ffda;
  --mat-tab-header-active-hover-indicator-color: #64ffda;
}
[data-theme=dark] .mat-mdc-tab-group.mat-warn, [data-theme=dark] .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
[data-theme=dark] .mat-mdc-tab-group.mat-background-primary, [data-theme=dark] .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #64ffda;
  --mat-tab-header-with-background-foreground-color: rgba(0, 0, 0, 0.87);
}
[data-theme=dark] .mat-mdc-tab-group.mat-background-accent, [data-theme=dark] .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #64ffda;
  --mat-tab-header-with-background-foreground-color: rgba(0, 0, 0, 0.87);
}
[data-theme=dark] .mat-mdc-tab-group.mat-background-warn, [data-theme=dark] .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}
[data-theme=dark] .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: white;
}
[data-theme=dark] .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: black;
  --mdc-checkbox-selected-focus-icon-color: #64ffda;
  --mdc-checkbox-selected-hover-icon-color: #64ffda;
  --mdc-checkbox-selected-icon-color: #64ffda;
  --mdc-checkbox-selected-pressed-icon-color: #64ffda;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #64ffda;
  --mdc-checkbox-selected-hover-state-layer-color: #64ffda;
  --mdc-checkbox-selected-pressed-state-layer-color: #64ffda;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
[data-theme=dark] .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
[data-theme=dark] .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #64ffda;
  --mat-text-button-state-layer-color: #64ffda;
  --mat-text-button-ripple-color: rgba(100, 255, 218, 0.1);
}
[data-theme=dark] .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #64ffda;
  --mat-text-button-state-layer-color: #64ffda;
  --mat-text-button-ripple-color: rgba(100, 255, 218, 0.1);
}
[data-theme=dark] .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}
[data-theme=dark] .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #64ffda;
  --mdc-filled-button-label-text-color: black;
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}
[data-theme=dark] .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #64ffda;
  --mdc-filled-button-label-text-color: black;
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}
[data-theme=dark] .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #64ffda;
  --mdc-protected-button-label-text-color: black;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
[data-theme=dark] .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #64ffda;
  --mdc-protected-button-label-text-color: black;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
[data-theme=dark] .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #64ffda;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: #64ffda;
  --mat-outlined-button-ripple-color: rgba(100, 255, 218, 0.1);
}
[data-theme=dark] .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #64ffda;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: #64ffda;
  --mat-outlined-button-ripple-color: rgba(100, 255, 218, 0.1);
}
[data-theme=dark] .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}
[data-theme=dark] .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #64ffda;
  --mat-icon-button-state-layer-color: #64ffda;
  --mat-icon-button-ripple-color: rgba(100, 255, 218, 0.1);
}
[data-theme=dark] .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #64ffda;
  --mat-icon-button-state-layer-color: #64ffda;
  --mat-icon-button-ripple-color: rgba(100, 255, 218, 0.1);
}
[data-theme=dark] .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-icon-button-state-layer-color: #f44336;
  --mat-icon-button-ripple-color: rgba(244, 67, 54, 0.1);
}
[data-theme=dark] .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #64ffda;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
[data-theme=dark] .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #64ffda;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
[data-theme=dark] .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #64ffda;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
}
[data-theme=dark] .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #64ffda;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
}
[data-theme=dark] .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #f44336;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
[data-theme=dark] .mat-accent {
  --mdc-circular-progress-active-indicator-color: #64ffda;
}
[data-theme=dark] .mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}
[data-theme=dark] .mat-badge-accent {
  --mat-badge-background-color: #64ffda;
  --mat-badge-text-color: rgba(0, 0, 0, 0.87);
}
[data-theme=dark] .mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}
[data-theme=dark] .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-selected-state-background-color: #64ffda;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(100, 255, 218, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(100, 255, 218, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(100, 255, 218, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(100, 255, 218, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
[data-theme=dark] .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
[data-theme=dark] .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #64ffda;
}
[data-theme=dark] .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}
[data-theme=dark] .mat-icon.mat-primary {
  --mat-icon-color: #64ffda;
}
[data-theme=dark] .mat-icon.mat-accent {
  --mat-icon-color: #64ffda;
}
[data-theme=dark] .mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}
[data-theme=dark] .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-selected-state-icon-background-color: #64ffda;
  --mat-stepper-header-selected-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-done-state-icon-background-color: #64ffda;
  --mat-stepper-header-done-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-edit-state-icon-background-color: #64ffda;
  --mat-stepper-header-edit-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
}
[data-theme=dark] .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
[data-theme=dark] .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #64ffda;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}
[data-theme=dark] .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #64ffda;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}
[data-theme=dark] .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(../../fonts/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(../../fonts/MaterialIcons-Regular.woff2) format("woff2"), url(../../fonts/MaterialIcons-Regular.woff) format("woff"), url(../../fonts/MaterialIcons-Regular.ttf) format("truetype");
}
.material-icons {
  font-family: "Material Icons", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}

@font-face {
  font-family: "Siemens Sans";
  src: url("../../fonts/siemenssans-roman-webfont.woff2") format("woff2"), url("../../fonts/siemenssans-roman-webfont.woff") format("woff"), url("../../fonts/SiemensSans-Roman.otf");
}
@font-face {
  font-family: "Siemens Sans Bold";
  src: url("../../fonts/siemenssans-bold-webfont.woff2") format("woff2"), url("../../fonts/siemenssans-bold-webfont.woff") format("woff"), url("../../fonts/SiemensSans-Bold.otf");
}
@font-face {
  font-family: "Siemens Serif Bold";
  src: url("../../fonts/siemensserif-bold-webfont.woff2") format("woff2"), url("../../fonts/siemensserif-bold-webfont.woff") format("woff"), url("../../fonts/SiemensSerif-Bold.otf");
}
@font-face {
  font-family: "Siemens Sans Italic";
  src: url("../../fonts/siemenssans-italic-webfont.woff2") format("woff2"), url("../../fonts/siemenssans-italic-webfont.woff") format("woff"), url("../../fonts/SiemensSans-Italic.otf");
}
@font-face {
  font-family: "siemens-webicons-webfont";
  src: url("../../fonts/siemens-webicons-webfont.eot?avcggw");
  src: url("../../fonts/siemens-webicons-webfont.eot?avcggw#iefix") format("embedded-opentype"), url("../../fonts/siemens-webicons-webfont.ttf?avcggw") format("truetype"), url("../../fonts/siemens-webicons-webfont.woff?avcggw") format("woff"), url("../../fonts/siemens-webicons-webfont.svg?avcggw#siemens-webicons-webfont") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SH Headline";
  src: url("../../fonts/SH-Bree-Headline-Regular.woff2") format("woff2");
}
/**
 *  ====================== Fontstack ======================
 */
.gap-5_ {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-20 {
  gap: 20px;
}

#sie-boilerplate,
.mat-mdc-dialog-container,
.cdk-overlay-container,
.cdk-drag-preview {
  /*mat-select {
    &.margins {
      margin: 0 10px;
    }
  }*/
  /* Handle */
  /* Handle on hover */
  /* Override for chip grid/set fields */
  /* Make chip grids/sets transparent */
  /* Style for disabled text fields */
  /* For labels specifically */
}
#sie-boilerplate mat-header-cell,
.mat-mdc-dialog-container mat-header-cell,
.cdk-overlay-container mat-header-cell,
.cdk-drag-preview mat-header-cell {
  color: var(--ceres-primary-color-2);
}
#sie-boilerplate mat-header-cell button,
.mat-mdc-dialog-container mat-header-cell button,
.cdk-overlay-container mat-header-cell button,
.cdk-drag-preview mat-header-cell button {
  text-align: inherit;
}
#sie-boilerplate .mat-progress-spinner circle,
#sie-boilerplate .mat-spinner circle,
.mat-mdc-dialog-container .mat-progress-spinner circle,
.mat-mdc-dialog-container .mat-spinner circle,
.cdk-overlay-container .mat-progress-spinner circle,
.cdk-overlay-container .mat-spinner circle,
.cdk-drag-preview .mat-progress-spinner circle,
.cdk-drag-preview .mat-spinner circle {
  stroke: var(--ceres-primary-color-1);
}
#sie-boilerplate .a-text-link-content:hover,
.mat-mdc-dialog-container .a-text-link-content:hover,
.cdk-overlay-container .a-text-link-content:hover,
.cdk-drag-preview .a-text-link-content:hover {
  border-bottom: none !important;
  color: var(--ceres-primary-color-1) !important;
}
#sie-boilerplate .no-link,
.mat-mdc-dialog-container .no-link,
.cdk-overlay-container .no-link,
.cdk-drag-preview .no-link {
  cursor: auto;
}
#sie-boilerplate .no-link:hover,
.mat-mdc-dialog-container .no-link:hover,
.cdk-overlay-container .no-link:hover,
.cdk-drag-preview .no-link:hover {
  color: var(--ceres-primary-color-1) !important;
}
#sie-boilerplate mat-header-cell,
#sie-boilerplate mat-footer-cell,
#sie-boilerplate mat-cell,
.mat-mdc-dialog-container mat-header-cell,
.mat-mdc-dialog-container mat-footer-cell,
.mat-mdc-dialog-container mat-cell,
.cdk-overlay-container mat-header-cell,
.cdk-overlay-container mat-footer-cell,
.cdk-overlay-container mat-cell,
.cdk-drag-preview mat-header-cell,
.cdk-drag-preview mat-footer-cell,
.cdk-drag-preview mat-cell {
  font-size: 14px !important;
}
#sie-boilerplate mat-header-cell span,
#sie-boilerplate mat-footer-cell span,
#sie-boilerplate mat-cell span,
.mat-mdc-dialog-container mat-header-cell span,
.mat-mdc-dialog-container mat-footer-cell span,
.mat-mdc-dialog-container mat-cell span,
.cdk-overlay-container mat-header-cell span,
.cdk-overlay-container mat-footer-cell span,
.cdk-overlay-container mat-cell span,
.cdk-drag-preview mat-header-cell span,
.cdk-drag-preview mat-footer-cell span,
.cdk-drag-preview mat-cell span {
  font-size: 14px !important;
  max-width: 100%;
}
#sie-boilerplate .number-cell,
.mat-mdc-dialog-container .number-cell,
.cdk-overlay-container .number-cell,
.cdk-drag-preview .number-cell {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}
#sie-boilerplate .button-cell,
.mat-mdc-dialog-container .button-cell,
.cdk-overlay-container .button-cell,
.cdk-drag-preview .button-cell {
  display: flex;
  justify-content: center;
}
#sie-boilerplate .table-container,
.mat-mdc-dialog-container .table-container,
.cdk-overlay-container .table-container,
.cdk-drag-preview .table-container {
  overflow: auto;
  min-height: 500px;
  height: 65vh;
  max-height: 65vh;
  border: 1px solid var(--ceres-primary-color-7);
}
#sie-boilerplate .table-container:not(.not-list):not(.not-services) mat-row,
#sie-boilerplate .table-container:not(.not-list):not(.not-services) mat-header-row,
#sie-boilerplate .table-container:not(.not-list):not(.not-services) mat-footer-row,
.mat-mdc-dialog-container .table-container:not(.not-list):not(.not-services) mat-row,
.mat-mdc-dialog-container .table-container:not(.not-list):not(.not-services) mat-header-row,
.mat-mdc-dialog-container .table-container:not(.not-list):not(.not-services) mat-footer-row,
.cdk-overlay-container .table-container:not(.not-list):not(.not-services) mat-row,
.cdk-overlay-container .table-container:not(.not-list):not(.not-services) mat-header-row,
.cdk-overlay-container .table-container:not(.not-list):not(.not-services) mat-footer-row,
.cdk-drag-preview .table-container:not(.not-list):not(.not-services) mat-row,
.cdk-drag-preview .table-container:not(.not-list):not(.not-services) mat-header-row,
.cdk-drag-preview .table-container:not(.not-list):not(.not-services) mat-footer-row {
  gap: 1.5rem;
}
#sie-boilerplate .table-container:not(.no-padding) mat-row,
#sie-boilerplate .table-container:not(.no-padding) mat-header-row,
#sie-boilerplate .table-container:not(.no-padding) mat-footer-row,
.mat-mdc-dialog-container .table-container:not(.no-padding) mat-row,
.mat-mdc-dialog-container .table-container:not(.no-padding) mat-header-row,
.mat-mdc-dialog-container .table-container:not(.no-padding) mat-footer-row,
.cdk-overlay-container .table-container:not(.no-padding) mat-row,
.cdk-overlay-container .table-container:not(.no-padding) mat-header-row,
.cdk-overlay-container .table-container:not(.no-padding) mat-footer-row,
.cdk-drag-preview .table-container:not(.no-padding) mat-row,
.cdk-drag-preview .table-container:not(.no-padding) mat-header-row,
.cdk-drag-preview .table-container:not(.no-padding) mat-footer-row {
  display: flex;
  padding: 0 16px !important;
}
#sie-boilerplate .table-container mat-row,
.mat-mdc-dialog-container .table-container mat-row,
.cdk-overlay-container .table-container mat-row,
.cdk-drag-preview .table-container mat-row {
  min-height: 65px;
  border-bottom: 1px solid var(--ceres-primary-color-7);
}
#sie-boilerplate .table-container mat-header-row,
.mat-mdc-dialog-container .table-container mat-header-row,
.cdk-overlay-container .table-container mat-header-row,
.cdk-drag-preview .table-container mat-header-row {
  height: 50px;
  background-color: var(--ceres-primary-color-7);
  color: var(--ceres-primary-color-3);
  border-bottom: 1px solid var(--ceres-primary-color-7);
}
#sie-boilerplate .table-container .mat-sort-header-button,
.mat-mdc-dialog-container .table-container .mat-sort-header-button,
.cdk-overlay-container .table-container .mat-sort-header-button,
.cdk-drag-preview .table-container .mat-sort-header-button {
  color: var(--ceres-primary-color-3);
  font-size: 14px;
}
#sie-boilerplate .table-container--projects-view,
.mat-mdc-dialog-container .table-container--projects-view,
.cdk-overlay-container .table-container--projects-view,
.cdk-drag-preview .table-container--projects-view {
  border: 0;
  height: auto !important;
  min-height: auto;
}
#sie-boilerplate .table-container--projects-view mat-table,
.mat-mdc-dialog-container .table-container--projects-view mat-table,
.cdk-overlay-container .table-container--projects-view mat-table,
.cdk-drag-preview .table-container--projects-view mat-table {
  border: 1.5px solid var(--ceres-primary-color-8);
}
#sie-boilerplate .table-container ::-webkit-scrollbar,
.mat-mdc-dialog-container .table-container ::-webkit-scrollbar,
.cdk-overlay-container .table-container ::-webkit-scrollbar,
.cdk-drag-preview .table-container ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
#sie-boilerplate .table-container .a-text-link-content,
.mat-mdc-dialog-container .table-container .a-text-link-content,
.cdk-overlay-container .table-container .a-text-link-content,
.cdk-drag-preview .table-container .a-text-link-content {
  color: var(--ceres-primary-color-1);
  font-weight: bold !important;
  font-size: 14px !important;
}
#sie-boilerplate .table-container .a-text-link-content.inactive,
.mat-mdc-dialog-container .table-container .a-text-link-content.inactive,
.cdk-overlay-container .table-container .a-text-link-content.inactive,
.cdk-drag-preview .table-container .a-text-link-content.inactive {
  color: var(--ceres-black) !important;
  cursor: default !important;
}
#sie-boilerplate .table-container mat-table,
.mat-mdc-dialog-container .table-container mat-table,
.cdk-overlay-container .table-container mat-table,
.cdk-drag-preview .table-container mat-table {
  min-width: 100%;
}
#sie-boilerplate .table-container.not-list mat-row,
.mat-mdc-dialog-container .table-container.not-list mat-row,
.cdk-overlay-container .table-container.not-list mat-row,
.cdk-drag-preview .table-container.not-list mat-row {
  min-height: 50px;
}
#sie-boilerplate .table-container.not-list mat-cell,
#sie-boilerplate .table-container.not-list mat-header-cell,
#sie-boilerplate .table-container.not-list mat-footer-cell,
.mat-mdc-dialog-container .table-container.not-list mat-cell,
.mat-mdc-dialog-container .table-container.not-list mat-header-cell,
.mat-mdc-dialog-container .table-container.not-list mat-footer-cell,
.cdk-overlay-container .table-container.not-list mat-cell,
.cdk-overlay-container .table-container.not-list mat-header-cell,
.cdk-overlay-container .table-container.not-list mat-footer-cell,
.cdk-drag-preview .table-container.not-list mat-cell,
.cdk-drag-preview .table-container.not-list mat-header-cell,
.cdk-drag-preview .table-container.not-list mat-footer-cell {
  padding: 7px !important;
}
#sie-boilerplate mat-header-cell,
#sie-boilerplate mat-cell,
#sie-boilerplate mat-footer-cell,
.mat-mdc-dialog-container mat-header-cell,
.mat-mdc-dialog-container mat-cell,
.mat-mdc-dialog-container mat-footer-cell,
.cdk-overlay-container mat-header-cell,
.cdk-overlay-container mat-cell,
.cdk-overlay-container mat-footer-cell,
.cdk-drag-preview mat-header-cell,
.cdk-drag-preview mat-cell,
.cdk-drag-preview mat-footer-cell {
  padding: 0px;
  word-break: break-word;
}
#sie-boilerplate mat-header-cell,
.mat-mdc-dialog-container mat-header-cell,
.cdk-overlay-container mat-header-cell,
.cdk-drag-preview mat-header-cell {
  align-self: stretch;
  display: flex;
  align-items: center;
  user-select: none;
  font-size: 13px !important;
  font-weight: 600;
}
#sie-boilerplate mat-header-cell.text-header-cell,
.mat-mdc-dialog-container mat-header-cell.text-header-cell,
.cdk-overlay-container mat-header-cell.text-header-cell,
.cdk-drag-preview mat-header-cell.text-header-cell {
  justify-content: flex-start;
  text-align: left;
}
#sie-boilerplate mat-header-cell.text-header-cell .mat-sort-header-content,
.mat-mdc-dialog-container mat-header-cell.text-header-cell .mat-sort-header-content,
.cdk-overlay-container mat-header-cell.text-header-cell .mat-sort-header-content,
.cdk-drag-preview mat-header-cell.text-header-cell .mat-sort-header-content {
  text-align: left;
}
#sie-boilerplate mat-header-cell.number-header-cell,
.mat-mdc-dialog-container mat-header-cell.number-header-cell,
.cdk-overlay-container mat-header-cell.number-header-cell,
.cdk-drag-preview mat-header-cell.number-header-cell {
  justify-content: end;
  text-align: right;
}
#sie-boilerplate mat-header-cell.number-header-cell .mat-sort-header-content,
.mat-mdc-dialog-container mat-header-cell.number-header-cell .mat-sort-header-content,
.cdk-overlay-container mat-header-cell.number-header-cell .mat-sort-header-content,
.cdk-drag-preview mat-header-cell.number-header-cell .mat-sort-header-content {
  text-align: right;
}
#sie-boilerplate mat-header-cell.button-header-cell,
.mat-mdc-dialog-container mat-header-cell.button-header-cell,
.cdk-overlay-container mat-header-cell.button-header-cell,
.cdk-drag-preview mat-header-cell.button-header-cell {
  justify-content: center;
  text-align: center;
}
#sie-boilerplate mat-header-cell.button-header-cell .mat-sort-header-content,
.mat-mdc-dialog-container mat-header-cell.button-header-cell .mat-sort-header-content,
.cdk-overlay-container mat-header-cell.button-header-cell .mat-sort-header-content,
.cdk-drag-preview mat-header-cell.button-header-cell .mat-sort-header-content {
  text-align: center;
}
#sie-boilerplate mat-header-cell.week-header,
.mat-mdc-dialog-container mat-header-cell.week-header,
.cdk-overlay-container mat-header-cell.week-header,
.cdk-drag-preview mat-header-cell.week-header {
  flex-direction: column;
}
#sie-boilerplate mat-header-cell.week-header b,
.mat-mdc-dialog-container mat-header-cell.week-header b,
.cdk-overlay-container mat-header-cell.week-header b,
.cdk-drag-preview mat-header-cell.week-header b {
  margin-bottom: 5px;
  font-size: 13px !important;
}
#sie-boilerplate mat-header-cell span,
.mat-mdc-dialog-container mat-header-cell span,
.cdk-overlay-container mat-header-cell span,
.cdk-drag-preview mat-header-cell span {
  font-size: 13px !important;
}
#sie-boilerplate mat-header-row,
.mat-mdc-dialog-container mat-header-row,
.cdk-overlay-container mat-header-row,
.cdk-drag-preview mat-header-row {
  position: sticky;
  top: 0;
}
#sie-boilerplate .pwo-label-with-select,
.mat-mdc-dialog-container .pwo-label-with-select,
.cdk-overlay-container .pwo-label-with-select,
.cdk-drag-preview .pwo-label-with-select {
  display: flex;
}
#sie-boilerplate .pwo-label-with-select .pwo-label,
.mat-mdc-dialog-container .pwo-label-with-select .pwo-label,
.cdk-overlay-container .pwo-label-with-select .pwo-label,
.cdk-drag-preview .pwo-label-with-select .pwo-label {
  margin-top: 7px;
  margin-bottom: 7px;
  user-select: none;
  height: 30px;
  max-height: 30px;
  font-size: 15px;
  font-family: "Siemens Sans", sans-serif;
  display: flex;
  align-items: center;
  font-weight: 600;
  width: 25%;
  padding: 5px;
  background-color: var(--ceres-white);
  border-left: var(--ceres-primary-color-3) 1.5px solid;
  border-top: var(--ceres-primary-color-3) 1.5px solid;
  border-bottom: var(--ceres-primary-color-3) 1.5px solid;
  border-right: var(--ceres-primary-color-3) 1.5px solid;
  color: var(--ceres-primary-color-3);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#sie-boilerplate .pwo-label-with-select .pwo-select,
.mat-mdc-dialog-container .pwo-label-with-select .pwo-select,
.cdk-overlay-container .pwo-label-with-select .pwo-select,
.cdk-drag-preview .pwo-label-with-select .pwo-select {
  width: 75%;
}
#sie-boilerplate .pwo-input,
.mat-mdc-dialog-container .pwo-input,
.cdk-overlay-container .pwo-input,
.cdk-drag-preview .pwo-input {
  height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  border: 1.5px solid var(--ceres-primary-color-8);
  background-color: var(--ceres-white);
}
#sie-boilerplate .pwo-input--auto,
.mat-mdc-dialog-container .pwo-input--auto,
.cdk-overlay-container .pwo-input--auto,
.cdk-drag-preview .pwo-input--auto {
  min-width: 150px;
  display: inline-block;
  width: fit-content;
}
#sie-boilerplate .pwo-input.margins,
.mat-mdc-dialog-container .pwo-input.margins,
.cdk-overlay-container .pwo-input.margins,
.cdk-drag-preview .pwo-input.margins {
  margin: 0 7px;
}
#sie-boilerplate .pwo-input input,
#sie-boilerplate .pwo-input textarea,
.mat-mdc-dialog-container .pwo-input input,
.mat-mdc-dialog-container .pwo-input textarea,
.cdk-overlay-container .pwo-input input,
.cdk-overlay-container .pwo-input textarea,
.cdk-drag-preview .pwo-input input,
.cdk-drag-preview .pwo-input textarea {
  height: 26px;
  width: 100%;
  border: none;
  padding: 10px;
  color: var(--ceres-secondary-color-16);
  font-family: "Siemens Sans", sans-serif;
}
#sie-boilerplate .pwo-input button,
.mat-mdc-dialog-container .pwo-input button,
.cdk-overlay-container .pwo-input button,
.cdk-drag-preview .pwo-input button {
  height: 26px;
  color: var(--ceres-secondary-color-16);
}
#sie-boilerplate .pwo-input button.highlighted,
.mat-mdc-dialog-container .pwo-input button.highlighted,
.cdk-overlay-container .pwo-input button.highlighted,
.cdk-drag-preview .pwo-input button.highlighted {
  border: none !important;
  color: var(--ceres-white);
  background-color: var(--ceres-primary-color-3);
}
#sie-boilerplate .pwo-input button.highlighted:hover,
.mat-mdc-dialog-container .pwo-input button.highlighted:hover,
.cdk-overlay-container .pwo-input button.highlighted:hover,
.cdk-drag-preview .pwo-input button.highlighted:hover {
  background-color: var(--ceres-primary-color-3);
}
#sie-boilerplate .pwo-input .mat-icon-button,
.mat-mdc-dialog-container .pwo-input .mat-icon-button,
.cdk-overlay-container .pwo-input .mat-icon-button,
.cdk-drag-preview .pwo-input .mat-icon-button {
  line-height: 26px;
}
#sie-boilerplate .pwo-form,
.mat-mdc-dialog-container .pwo-form,
.cdk-overlay-container .pwo-form,
.cdk-drag-preview .pwo-form {
  height: 30px;
  max-height: 30px;
  display: flex;
  align-items: center;
  background-color: var(--ceres-white);
  margin-top: 15px;
  /*&.picker {
    border: 1px solid var(--ceres-primary-color-7);
  }*/
}
#sie-boilerplate .pwo-form.mat-mdc-form-field-invalid input,
.mat-mdc-dialog-container .pwo-form.mat-mdc-form-field-invalid input,
.cdk-overlay-container .pwo-form.mat-mdc-form-field-invalid input,
.cdk-drag-preview .pwo-form.mat-mdc-form-field-invalid input {
  border: 1px solid var(--ceres-secondary-color-14) !important;
}
#sie-boilerplate .pwo-form input,
#sie-boilerplate .pwo-form textarea,
.mat-mdc-dialog-container .pwo-form input,
.mat-mdc-dialog-container .pwo-form textarea,
.cdk-overlay-container .pwo-form input,
.cdk-overlay-container .pwo-form textarea,
.cdk-drag-preview .pwo-form input,
.cdk-drag-preview .pwo-form textarea {
  position: absolute;
  top: 0px;
  font-weight: 400 !important;
  font-size: 16px !important;
  font-family: "Siemens Sans", sans-serif !important;
  height: 30px !important;
  color: var(--ceres-primary-color-2);
  padding: 10px;
}
#sie-boilerplate .pwo-form textarea:read-only,
#sie-boilerplate .pwo-form textarea:disabled,
#sie-boilerplate .pwo-form input:read-only,
#sie-boilerplate .pwo-form input:disabled,
.mat-mdc-dialog-container .pwo-form textarea:read-only,
.mat-mdc-dialog-container .pwo-form textarea:disabled,
.mat-mdc-dialog-container .pwo-form input:read-only,
.mat-mdc-dialog-container .pwo-form input:disabled,
.cdk-overlay-container .pwo-form textarea:read-only,
.cdk-overlay-container .pwo-form textarea:disabled,
.cdk-overlay-container .pwo-form input:read-only,
.cdk-overlay-container .pwo-form input:disabled,
.cdk-drag-preview .pwo-form textarea:read-only,
.cdk-drag-preview .pwo-form textarea:disabled,
.cdk-drag-preview .pwo-form input:read-only,
.cdk-drag-preview .pwo-form input:disabled {
  background-color: var(--ceres-primary-color-8);
  cursor: not-allowed;
  color: var(--ceres-primary-color-2);
}
#sie-boilerplate .pwo-form .mat-mdc-form-field-subscript-wrapper,
.mat-mdc-dialog-container .pwo-form .mat-mdc-form-field-subscript-wrapper,
.cdk-overlay-container .pwo-form .mat-mdc-form-field-subscript-wrapper,
.cdk-drag-preview .pwo-form .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
#sie-boilerplate .pwo-form .mat-mdc-form-field-label,
.mat-mdc-dialog-container .pwo-form .mat-mdc-form-field-label,
.cdk-overlay-container .pwo-form .mat-mdc-form-field-label,
.cdk-drag-preview .pwo-form .mat-mdc-form-field-label {
  color: var(--ceres-primary-color-2);
  font-size: 16px;
}
#sie-boilerplate .pwo-form .mat-mdc-form-field-label-wrapper,
.mat-mdc-dialog-container .pwo-form .mat-mdc-form-field-label-wrapper,
.cdk-overlay-container .pwo-form .mat-mdc-form-field-label-wrapper,
.cdk-drag-preview .pwo-form .mat-mdc-form-field-label-wrapper {
  padding: 9px 6px 6px 6px;
}
#sie-boilerplate .pwo-form .mat-mdc-form-field-underline,
.mat-mdc-dialog-container .pwo-form .mat-mdc-form-field-underline,
.cdk-overlay-container .pwo-form .mat-mdc-form-field-underline,
.cdk-drag-preview .pwo-form .mat-mdc-form-field-underline {
  height: 0 !important;
  display: none;
}
#sie-boilerplate .pwo-form .mat-mdc-form-field-wrapper,
.mat-mdc-dialog-container .pwo-form .mat-mdc-form-field-wrapper,
.cdk-overlay-container .pwo-form .mat-mdc-form-field-wrapper,
.cdk-drag-preview .pwo-form .mat-mdc-form-field-wrapper {
  padding: 0 !important;
  width: 100%;
}
#sie-boilerplate .pwo-form .mat-mdc-form-field-flex,
.mat-mdc-dialog-container .pwo-form .mat-mdc-form-field-flex,
.cdk-overlay-container .pwo-form .mat-mdc-form-field-flex,
.cdk-drag-preview .pwo-form .mat-mdc-form-field-flex {
  align-items: center;
}
#sie-boilerplate .pwo-form .mat-mdc-form-field-label-wrapper,
.mat-mdc-dialog-container .pwo-form .mat-mdc-form-field-label-wrapper,
.cdk-overlay-container .pwo-form .mat-mdc-form-field-label-wrapper,
.cdk-drag-preview .pwo-form .mat-mdc-form-field-label-wrapper {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  padding: 6px;
}
#sie-boilerplate .pwo-form .mat-mdc-form-field-infix,
.mat-mdc-dialog-container .pwo-form .mat-mdc-form-field-infix,
.cdk-overlay-container .pwo-form .mat-mdc-form-field-infix,
.cdk-drag-preview .pwo-form .mat-mdc-form-field-infix {
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: "Siemens Sans", sans-serif !important;
  padding: 0 !important;
  height: 30px;
  border-top: 0 !important;
}
#sie-boilerplate .pwo-form .mat-mdc-form-field-infix label,
.mat-mdc-dialog-container .pwo-form .mat-mdc-form-field-infix label,
.cdk-overlay-container .pwo-form .mat-mdc-form-field-infix label,
.cdk-drag-preview .pwo-form .mat-mdc-form-field-infix label {
  margin-left: 3px;
  position: initial;
}
#sie-boilerplate .pwo-form.mat-mdc-form-field-should-float label,
.mat-mdc-dialog-container .pwo-form.mat-mdc-form-field-should-float label,
.cdk-overlay-container .pwo-form.mat-mdc-form-field-should-float label,
.cdk-drag-preview .pwo-form.mat-mdc-form-field-should-float label {
  margin-left: -4px;
  margin-bottom: 3px;
}
#sie-boilerplate .pwo-form .pwo-btn,
.mat-mdc-dialog-container .pwo-form .pwo-btn,
.cdk-overlay-container .pwo-form .pwo-btn,
.cdk-drag-preview .pwo-form .pwo-btn {
  position: absolute;
  right: 0;
  height: 30px;
  color: var(--ceres-secondary-color-16);
}
#sie-boilerplate .pwo-form .pwo-btn.highlighted,
.mat-mdc-dialog-container .pwo-form .pwo-btn.highlighted,
.cdk-overlay-container .pwo-form .pwo-btn.highlighted,
.cdk-drag-preview .pwo-form .pwo-btn.highlighted {
  border: none !important;
  color: var(--ceres-white);
  background-color: var(--ceres-primary-color-1);
}
#sie-boilerplate .pwo-form .pwo-btn.highlighted:hover,
.mat-mdc-dialog-container .pwo-form .pwo-btn.highlighted:hover,
.cdk-overlay-container .pwo-form .pwo-btn.highlighted:hover,
.cdk-drag-preview .pwo-form .pwo-btn.highlighted:hover {
  background-color: var(--ceres-primary-color-1);
}
#sie-boilerplate .pwo-form .mat-icon-button,
.mat-mdc-dialog-container .pwo-form .mat-icon-button,
.cdk-overlay-container .pwo-form .mat-icon-button,
.cdk-drag-preview .pwo-form .mat-icon-button {
  line-height: 26px;
}
#sie-boilerplate .pwo-form-textarea,
.mat-mdc-dialog-container .pwo-form-textarea,
.cdk-overlay-container .pwo-form-textarea,
.cdk-drag-preview .pwo-form-textarea {
  display: flex;
  align-items: center;
  border: 1.5px solid var(--ceres-primary-color-8);
  background-color: var(--ceres-white);
  min-height: 40px;
}
#sie-boilerplate .pwo-form-textarea.large textarea,
.mat-mdc-dialog-container .pwo-form-textarea.large textarea,
.cdk-overlay-container .pwo-form-textarea.large textarea,
.cdk-drag-preview .pwo-form-textarea.large textarea {
  min-height: 150px;
  max-height: 250px;
  overflow: scroll;
}
#sie-boilerplate .pwo-form-textarea textarea,
.mat-mdc-dialog-container .pwo-form-textarea textarea,
.cdk-overlay-container .pwo-form-textarea textarea,
.cdk-drag-preview .pwo-form-textarea textarea {
  font-weight: 400 !important;
  font-size: 16px !important;
  color: var(--ceres-primary-color-2);
  font-family: "Siemens Sans", sans-serif !important;
  padding: 10px !important;
  min-height: 40x;
}
#sie-boilerplate .pwo-form-textarea textarea:read-only,
.mat-mdc-dialog-container .pwo-form-textarea textarea:read-only,
.cdk-overlay-container .pwo-form-textarea textarea:read-only,
.cdk-drag-preview .pwo-form-textarea textarea:read-only {
  background-color: var(--ceres-primary-color-8);
  cursor: not-allowed;
}
#sie-boilerplate .pwo-form-textarea .mat-mdc-form-field-subscript-wrapper,
.mat-mdc-dialog-container .pwo-form-textarea .mat-mdc-form-field-subscript-wrapper,
.cdk-overlay-container .pwo-form-textarea .mat-mdc-form-field-subscript-wrapper,
.cdk-drag-preview .pwo-form-textarea .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
#sie-boilerplate .pwo-form-textarea .mat-mdc-form-field-underline,
.mat-mdc-dialog-container .pwo-form-textarea .mat-mdc-form-field-underline,
.cdk-overlay-container .pwo-form-textarea .mat-mdc-form-field-underline,
.cdk-drag-preview .pwo-form-textarea .mat-mdc-form-field-underline {
  height: 0 !important;
  display: none;
}
#sie-boilerplate .pwo-form-textarea .mat-mdc-form-field-wrapper,
.mat-mdc-dialog-container .pwo-form-textarea .mat-mdc-form-field-wrapper,
.cdk-overlay-container .pwo-form-textarea .mat-mdc-form-field-wrapper,
.cdk-drag-preview .pwo-form-textarea .mat-mdc-form-field-wrapper {
  padding: 0 !important;
  width: 100%;
}
#sie-boilerplate .pwo-form-textarea .mat-mdc-form-field-wrapper,
#sie-boilerplate .pwo-form-textarea .mat-mdc-form-field-flex,
#sie-boilerplate .pwo-form-textarea .mat-mdc-form-field-label-wrapper,
.mat-mdc-dialog-container .pwo-form-textarea .mat-mdc-form-field-wrapper,
.mat-mdc-dialog-container .pwo-form-textarea .mat-mdc-form-field-flex,
.mat-mdc-dialog-container .pwo-form-textarea .mat-mdc-form-field-label-wrapper,
.cdk-overlay-container .pwo-form-textarea .mat-mdc-form-field-wrapper,
.cdk-overlay-container .pwo-form-textarea .mat-mdc-form-field-flex,
.cdk-overlay-container .pwo-form-textarea .mat-mdc-form-field-label-wrapper,
.cdk-drag-preview .pwo-form-textarea .mat-mdc-form-field-wrapper,
.cdk-drag-preview .pwo-form-textarea .mat-mdc-form-field-flex,
.cdk-drag-preview .pwo-form-textarea .mat-mdc-form-field-label-wrapper {
  height: auto !important;
}
#sie-boilerplate .pwo-form-textarea .mat-mdc-form-field-label-wrapper,
.mat-mdc-dialog-container .pwo-form-textarea .mat-mdc-form-field-label-wrapper,
.cdk-overlay-container .pwo-form-textarea .mat-mdc-form-field-label-wrapper,
.cdk-drag-preview .pwo-form-textarea .mat-mdc-form-field-label-wrapper {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  padding: 6px;
}
#sie-boilerplate .pwo-form-textarea .mat-mdc-form-field-infix,
.mat-mdc-dialog-container .pwo-form-textarea .mat-mdc-form-field-infix,
.cdk-overlay-container .pwo-form-textarea .mat-mdc-form-field-infix,
.cdk-drag-preview .pwo-form-textarea .mat-mdc-form-field-infix {
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: "Siemens Sans", sans-serif !important;
  padding: 0 !important;
  border-top: 0 !important;
  height: auto;
}
#sie-boilerplate .pwo-form-textarea .mat-mdc-form-field-infix label,
.mat-mdc-dialog-container .pwo-form-textarea .mat-mdc-form-field-infix label,
.cdk-overlay-container .pwo-form-textarea .mat-mdc-form-field-infix label,
.cdk-drag-preview .pwo-form-textarea .mat-mdc-form-field-infix label {
  margin-left: 3px;
  position: initial;
  font-size: 16px;
  color: var(--ceres-primary-color-2);
}
#sie-boilerplate .pwo-form-textarea.mat-mdc-form-field-should-float label,
.mat-mdc-dialog-container .pwo-form-textarea.mat-mdc-form-field-should-float label,
.cdk-overlay-container .pwo-form-textarea.mat-mdc-form-field-should-float label,
.cdk-drag-preview .pwo-form-textarea.mat-mdc-form-field-should-float label {
  margin-left: -4px;
  margin-bottom: 3px;
}
#sie-boilerplate .pwo-form--select .mat-mdc-form-field-label,
.mat-mdc-dialog-container .pwo-form--select .mat-mdc-form-field-label,
.cdk-overlay-container .pwo-form--select .mat-mdc-form-field-label,
.cdk-drag-preview .pwo-form--select .mat-mdc-form-field-label {
  color: var(--ceres-primary-color-2);
  font-size: 16px;
}
#sie-boilerplate .pwo-form--select .mat-mdc-form-field-label-wrapper,
.mat-mdc-dialog-container .pwo-form--select .mat-mdc-form-field-label-wrapper,
.cdk-overlay-container .pwo-form--select .mat-mdc-form-field-label-wrapper,
.cdk-drag-preview .pwo-form--select .mat-mdc-form-field-label-wrapper {
  padding: 9px 6px 6px 6px;
}
#sie-boilerplate mat-tab-group.projects .mat-mdc-tab-header-pagination.mat-mdc-tab-header-pagination-disabled,
.mat-mdc-dialog-container mat-tab-group.projects .mat-mdc-tab-header-pagination.mat-mdc-tab-header-pagination-disabled,
.cdk-overlay-container mat-tab-group.projects .mat-mdc-tab-header-pagination.mat-mdc-tab-header-pagination-disabled,
.cdk-drag-preview mat-tab-group.projects .mat-mdc-tab-header-pagination.mat-mdc-tab-header-pagination-disabled {
  display: none !important;
}
#sie-boilerplate mat-tab-group.projects .mat-mdc-tab-body-wrapper,
.mat-mdc-dialog-container mat-tab-group.projects .mat-mdc-tab-body-wrapper,
.cdk-overlay-container mat-tab-group.projects .mat-mdc-tab-body-wrapper,
.cdk-drag-preview mat-tab-group.projects .mat-mdc-tab-body-wrapper {
  padding: 20px 0px 20px 20px !important;
}
#sie-boilerplate mat-tab-group.projects .mat-mdc-tab-label,
.mat-mdc-dialog-container mat-tab-group.projects .mat-mdc-tab-label,
.cdk-overlay-container mat-tab-group.projects .mat-mdc-tab-label,
.cdk-drag-preview mat-tab-group.projects .mat-mdc-tab-label {
  min-width: 150px !important;
  max-width: 170px !important;
}
#sie-boilerplate mat-tab-group.projects .mdc-tab__content .mdc-tab__text-label,
.mat-mdc-dialog-container mat-tab-group.projects .mdc-tab__content .mdc-tab__text-label,
.cdk-overlay-container mat-tab-group.projects .mdc-tab__content .mdc-tab__text-label,
.cdk-drag-preview mat-tab-group.projects .mdc-tab__content .mdc-tab__text-label {
  font-family: "Siemens Sans", sans-serif;
  font-size: 18px !important;
  color: var(--ceres-primary-color-2);
  padding: 0 !important;
}
#sie-boilerplate mat-tab-group.projects .mat-ink-bar,
.mat-mdc-dialog-container mat-tab-group.projects .mat-ink-bar,
.cdk-overlay-container mat-tab-group.projects .mat-ink-bar,
.cdk-drag-preview mat-tab-group.projects .mat-ink-bar {
  background-color: var(--ceres-primary-color-1) !important;
  height: 3px;
}
#sie-boilerplate mat-tab-group.projects .mat-header,
.mat-mdc-dialog-container mat-tab-group.projects .mat-header,
.cdk-overlay-container mat-tab-group.projects .mat-header,
.cdk-drag-preview mat-tab-group.projects .mat-header {
  margin-top: 20px;
}
#sie-boilerplate mat-tab-group[vertical],
.mat-mdc-dialog-container mat-tab-group[vertical],
.cdk-overlay-container mat-tab-group[vertical],
.cdk-drag-preview mat-tab-group[vertical] {
  flex-direction: row;
}
#sie-boilerplate mat-tab-group[vertical] mat-tab-header,
.mat-mdc-dialog-container mat-tab-group[vertical] mat-tab-header,
.cdk-overlay-container mat-tab-group[vertical] mat-tab-header,
.cdk-drag-preview mat-tab-group[vertical] mat-tab-header {
  flex-direction: column;
  border-bottom: 0px solid transparent;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 40px;
}
#sie-boilerplate mat-tab-group[vertical] mat-tab-header .mat-mdc-tab-label-container,
.mat-mdc-dialog-container mat-tab-group[vertical] mat-tab-header .mat-mdc-tab-label-container,
.cdk-overlay-container mat-tab-group[vertical] mat-tab-header .mat-mdc-tab-label-container,
.cdk-drag-preview mat-tab-group[vertical] mat-tab-header .mat-mdc-tab-label-container {
  flex-direction: column;
}
#sie-boilerplate mat-tab-group[vertical] mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-labels,
.mat-mdc-dialog-container mat-tab-group[vertical] mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-labels,
.cdk-overlay-container mat-tab-group[vertical] mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-labels,
.cdk-drag-preview mat-tab-group[vertical] mat-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-labels {
  flex-direction: column;
}
#sie-boilerplate mat-tab-group[vertical] mat-tab-header .mat-mdc-tab-label-container mat-ink-bar,
.mat-mdc-dialog-container mat-tab-group[vertical] mat-tab-header .mat-mdc-tab-label-container mat-ink-bar,
.cdk-overlay-container mat-tab-group[vertical] mat-tab-header .mat-mdc-tab-label-container mat-ink-bar,
.cdk-drag-preview mat-tab-group[vertical] mat-tab-header .mat-mdc-tab-label-container mat-ink-bar {
  left: initial !important;
  bottom: initial;
  right: 0;
  width: 2px !important;
  height: initial;
}
#sie-boilerplate mat-tab-group[vertical] .mat-mdc-tab-body-wrapper,
.mat-mdc-dialog-container mat-tab-group[vertical] .mat-mdc-tab-body-wrapper,
.cdk-overlay-container mat-tab-group[vertical] .mat-mdc-tab-body-wrapper,
.cdk-drag-preview mat-tab-group[vertical] .mat-mdc-tab-body-wrapper {
  flex-direction: column;
  width: 100%;
}
#sie-boilerplate .pwo-select,
.mat-mdc-dialog-container .pwo-select,
.cdk-overlay-container .pwo-select,
.cdk-drag-preview .pwo-select {
  font-size: 15px;
  margin-top: 0px;
  background-color: var(--ceres-white);
  border: 1px solid var(--ceres-primary-color-7);
  height: 35px;
  max-height: 35px;
  padding: 7px 6px 6px 6px;
}
#sie-boilerplate .pwo-select span,
.mat-mdc-dialog-container .pwo-select span,
.cdk-overlay-container .pwo-select span,
.cdk-drag-preview .pwo-select span {
  color: var(--ceres-primary-color-2);
  font-size: 16px;
}
#sie-boilerplate .pwo-select .mat-mdc-select-arrow,
.mat-mdc-dialog-container .pwo-select .mat-mdc-select-arrow,
.cdk-overlay-container .pwo-select .mat-mdc-select-arrow,
.cdk-drag-preview .pwo-select .mat-mdc-select-arrow {
  color: var(--ceres-primary-color-2);
}
#sie-boilerplate .pwo-select--auto,
.mat-mdc-dialog-container .pwo-select--auto,
.cdk-overlay-container .pwo-select--auto,
.cdk-drag-preview .pwo-select--auto {
  max-width: 175px;
  display: inline-block;
}
#sie-boilerplate .pwo-select--auto,
.mat-mdc-dialog-container .pwo-select--auto,
.cdk-overlay-container .pwo-select--auto,
.cdk-drag-preview .pwo-select--auto {
  max-width: 300px;
}
#sie-boilerplate .pwo-select.edit-project,
.mat-mdc-dialog-container .pwo-select.edit-project,
.cdk-overlay-container .pwo-select.edit-project,
.cdk-drag-preview .pwo-select.edit-project {
  text-align: left !important;
}
#sie-boilerplate .pwo-select.edit-project.calculation,
.mat-mdc-dialog-container .pwo-select.edit-project.calculation,
.cdk-overlay-container .pwo-select.edit-project.calculation,
.cdk-drag-preview .pwo-select.edit-project.calculation {
  background-color: var(--ceres-primary-color-7);
}
#sie-boilerplate .pwo-select.edit-project.calculation span,
.mat-mdc-dialog-container .pwo-select.edit-project.calculation span,
.cdk-overlay-container .pwo-select.edit-project.calculation span,
.cdk-drag-preview .pwo-select.edit-project.calculation span {
  font-size: 20px !important;
  color: var(--ceres-primary-color-1) !important;
}
#sie-boilerplate .pwo-select.edit-project.calculation .mat-mdc-select-arrow,
.mat-mdc-dialog-container .pwo-select.edit-project.calculation .mat-mdc-select-arrow,
.cdk-overlay-container .pwo-select.edit-project.calculation .mat-mdc-select-arrow,
.cdk-drag-preview .pwo-select.edit-project.calculation .mat-mdc-select-arrow {
  color: var(--ceres-primary-color-1) !important;
  border-left: 10px solid transparent !important;
  border-right: 10px solid transparent !important;
  border-top: 10px solid !important;
}
#sie-boilerplate .pwo-select.edit-project.activity,
.mat-mdc-dialog-container .pwo-select.edit-project.activity,
.cdk-overlay-container .pwo-select.edit-project.activity,
.cdk-drag-preview .pwo-select.edit-project.activity {
  height: 25px;
  padding: 3px 5px;
  position: relative;
  bottom: 4px;
}
#sie-boilerplate .pwo-select.edit-project.activity span,
.mat-mdc-dialog-container .pwo-select.edit-project.activity span,
.cdk-overlay-container .pwo-select.edit-project.activity span,
.cdk-drag-preview .pwo-select.edit-project.activity span {
  font-size: 12px;
}
#sie-boilerplate .pwo-select.notValid,
.mat-mdc-dialog-container .pwo-select.notValid,
.cdk-overlay-container .pwo-select.notValid,
.cdk-drag-preview .pwo-select.notValid {
  border-color: var(--ceres-secondary-color-14);
}
#sie-boilerplate .pwo-select.notValid span,
.mat-mdc-dialog-container .pwo-select.notValid span,
.cdk-overlay-container .pwo-select.notValid span,
.cdk-drag-preview .pwo-select.notValid span {
  color: var(--ceres-black);
}
#sie-boilerplate .pwo-select.mat-mdc-select-invalid,
.mat-mdc-dialog-container .pwo-select.mat-mdc-select-invalid,
.cdk-overlay-container .pwo-select.mat-mdc-select-invalid,
.cdk-drag-preview .pwo-select.mat-mdc-select-invalid {
  border-color: var(--ceres-secondary-color-14);
}
#sie-boilerplate .pwo-select.mat-mdc-select-invalid .mat-mdc-form-field-label,
.mat-mdc-dialog-container .pwo-select.mat-mdc-select-invalid .mat-mdc-form-field-label,
.cdk-overlay-container .pwo-select.mat-mdc-select-invalid .mat-mdc-form-field-label,
.cdk-drag-preview .pwo-select.mat-mdc-select-invalid .mat-mdc-form-field-label {
  color: var(--ceres-secondary-color-14) !important;
}
#sie-boilerplate .pwo-select.mat-mdc-select-required .mat-mdc-select-placeholder:after,
.mat-mdc-dialog-container .pwo-select.mat-mdc-select-required .mat-mdc-select-placeholder:after,
.cdk-overlay-container .pwo-select.mat-mdc-select-required .mat-mdc-select-placeholder:after,
.cdk-drag-preview .pwo-select.mat-mdc-select-required .mat-mdc-select-placeholder:after {
  content: " *";
}
#sie-boilerplate ::ng-deep .mat-mdc-checkbox .mat-mdc-checkbox-frame,
.mat-mdc-dialog-container ::ng-deep .mat-mdc-checkbox .mat-mdc-checkbox-frame,
.cdk-overlay-container ::ng-deep .mat-mdc-checkbox .mat-mdc-checkbox-frame,
.cdk-drag-preview ::ng-deep .mat-mdc-checkbox .mat-mdc-checkbox-frame {
  border-color: violet;
  background-color: var(--ceres-secondary-color-14) !important;
}
#sie-boilerplate button.pwo,
.mat-mdc-dialog-container button.pwo,
.cdk-overlay-container button.pwo,
.cdk-drag-preview button.pwo {
  margin-bottom: 7px !important;
  margin-top: 7px !important;
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 35px;
  max-height: 35px;
  justify-content: space-between;
  align-items: center;
}
#sie-boilerplate button.pwo span,
.mat-mdc-dialog-container button.pwo span,
.cdk-overlay-container button.pwo span,
.cdk-drag-preview button.pwo span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#sie-boilerplate button.pwo--mb,
.mat-mdc-dialog-container button.pwo--mb,
.cdk-overlay-container button.pwo--mb,
.cdk-drag-preview button.pwo--mb {
  margin-bottom: 10px;
}
#sie-boilerplate button.pwo > .container,
.mat-mdc-dialog-container button.pwo > .container,
.cdk-overlay-container button.pwo > .container,
.cdk-drag-preview button.pwo > .container {
  display: flex;
  align-items: center;
  padding: 0 !important;
}
#sie-boilerplate button.pwo.dd-with-icon,
.mat-mdc-dialog-container button.pwo.dd-with-icon,
.cdk-overlay-container button.pwo.dd-with-icon,
.cdk-drag-preview button.pwo.dd-with-icon {
  background-color: var(--ceres-primary-color-1);
  color: var(--ceres-white);
}
#sie-boilerplate button.pwo.dd-with-icon:hover,
.mat-mdc-dialog-container button.pwo.dd-with-icon:hover,
.cdk-overlay-container button.pwo.dd-with-icon:hover,
.cdk-drag-preview button.pwo.dd-with-icon:hover {
  background-color: var(--ceres-primary-color-1);
}
#sie-boilerplate button.pwo.dd-with-icon .icon,
.mat-mdc-dialog-container button.pwo.dd-with-icon .icon,
.cdk-overlay-container button.pwo.dd-with-icon .icon,
.cdk-drag-preview button.pwo.dd-with-icon .icon {
  margin-right: 7px;
}
#sie-boilerplate button.pwo.dd-with-icon .icon,
#sie-boilerplate button.pwo.dd-with-icon span,
.mat-mdc-dialog-container button.pwo.dd-with-icon .icon,
.mat-mdc-dialog-container button.pwo.dd-with-icon span,
.cdk-overlay-container button.pwo.dd-with-icon .icon,
.cdk-overlay-container button.pwo.dd-with-icon span,
.cdk-drag-preview button.pwo.dd-with-icon .icon,
.cdk-drag-preview button.pwo.dd-with-icon span {
  font-size: 13px;
}
#sie-boilerplate button.pwo.smaller,
.mat-mdc-dialog-container button.pwo.smaller,
.cdk-overlay-container button.pwo.smaller,
.cdk-drag-preview button.pwo.smaller {
  max-height: 30px;
}
#sie-boilerplate button.pwo.smaller > .container,
.mat-mdc-dialog-container button.pwo.smaller > .container,
.cdk-overlay-container button.pwo.smaller > .container,
.cdk-drag-preview button.pwo.smaller > .container {
  padding: 0;
}
#sie-boilerplate button.pwo--with-icon,
.mat-mdc-dialog-container button.pwo--with-icon,
.cdk-overlay-container button.pwo--with-icon,
.cdk-drag-preview button.pwo--with-icon {
  background-color: var(--ceres-white);
  color: var(--ceres-primary-color-1);
  border: 2px solid var(--ceres-primary-color-1);
}
#sie-boilerplate button.pwo--with-icon .container,
.mat-mdc-dialog-container button.pwo--with-icon .container,
.cdk-overlay-container button.pwo--with-icon .container,
.cdk-drag-preview button.pwo--with-icon .container {
  padding: 0 !important;
}
#sie-boilerplate button.pwo--with-icon .icon,
.mat-mdc-dialog-container button.pwo--with-icon .icon,
.cdk-overlay-container button.pwo--with-icon .icon,
.cdk-drag-preview button.pwo--with-icon .icon {
  margin-right: 7px;
}
#sie-boilerplate button.pwo--with-icon span,
.mat-mdc-dialog-container button.pwo--with-icon span,
.cdk-overlay-container button.pwo--with-icon span,
.cdk-drag-preview button.pwo--with-icon span {
  font-size: 13px;
}
#sie-boilerplate button.pwo--with-icon:hover,
.mat-mdc-dialog-container button.pwo--with-icon:hover,
.cdk-overlay-container button.pwo--with-icon:hover,
.cdk-drag-preview button.pwo--with-icon:hover {
  background-color: var(--ceres-primary-color-1);
  color: var(--ceres-white);
}
#sie-boilerplate button.pwo--with-icon:disabled,
.mat-mdc-dialog-container button.pwo--with-icon:disabled,
.cdk-overlay-container button.pwo--with-icon:disabled,
.cdk-drag-preview button.pwo--with-icon:disabled {
  color: gray;
  border: 2px solid gray;
}
#sie-boilerplate button.pwo--with-icon:disabled:hover,
.mat-mdc-dialog-container button.pwo--with-icon:disabled:hover,
.cdk-overlay-container button.pwo--with-icon:disabled:hover,
.cdk-drag-preview button.pwo--with-icon:disabled:hover {
  background-color: gray;
  color: var(--ceres-white);
}
#sie-boilerplate button.pwo--150,
.mat-mdc-dialog-container button.pwo--150,
.cdk-overlay-container button.pwo--150,
.cdk-drag-preview button.pwo--150 {
  display: inline-block;
  width: 150px;
}
#sie-boilerplate button.pwo--auto,
.mat-mdc-dialog-container button.pwo--auto,
.cdk-overlay-container button.pwo--auto,
.cdk-drag-preview button.pwo--auto {
  min-width: 150px;
  display: inline-block;
  width: fit-content;
}
#sie-boilerplate button.pwo.margins,
.mat-mdc-dialog-container button.pwo.margins,
.cdk-overlay-container button.pwo.margins,
.cdk-drag-preview button.pwo.margins {
  margin: 0 10px;
}
#sie-boilerplate button.pwo.smaller-text span,
.mat-mdc-dialog-container button.pwo.smaller-text span,
.cdk-overlay-container button.pwo.smaller-text span,
.cdk-drag-preview button.pwo.smaller-text span {
  font-size: 13px !important;
}
#sie-boilerplate button.pwo--last,
.mat-mdc-dialog-container button.pwo--last,
.cdk-overlay-container button.pwo--last,
.cdk-drag-preview button.pwo--last {
  margin-right: 0 !important;
}
#sie-boilerplate .grid-col.button-container,
.mat-mdc-dialog-container .grid-col.button-container,
.cdk-overlay-container .grid-col.button-container,
.cdk-drag-preview .grid-col.button-container {
  display: flex;
  gap: 7px;
}
#sie-boilerplate .grid-col.button-container :first-child,
.mat-mdc-dialog-container .grid-col.button-container :first-child,
.cdk-overlay-container .grid-col.button-container :first-child,
.cdk-drag-preview .grid-col.button-container :first-child {
  margin-left: 0 !important;
}
#sie-boilerplate .grid-col.button-container :last-child,
.mat-mdc-dialog-container .grid-col.button-container :last-child,
.cdk-overlay-container .grid-col.button-container :last-child,
.cdk-drag-preview .grid-col.button-container :last-child {
  margin-right: 0 !important;
}
#sie-boilerplate .grid-col.button-container--right-normal,
.mat-mdc-dialog-container .grid-col.button-container--right-normal,
.cdk-overlay-container .grid-col.button-container--right-normal,
.cdk-drag-preview .grid-col.button-container--right-normal {
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}
#sie-boilerplate .grid-col.button-container--right .burger,
.mat-mdc-dialog-container .grid-col.button-container--right .burger,
.cdk-overlay-container .grid-col.button-container--right .burger,
.cdk-drag-preview .grid-col.button-container--right .burger {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
  visibility: hidden;
}
#sie-boilerplate .grid-col.button-container--right .burger:before,
.mat-mdc-dialog-container .grid-col.button-container--right .burger:before,
.cdk-overlay-container .grid-col.button-container--right .burger:before,
.cdk-drag-preview .grid-col.button-container--right .burger:before {
  margin: 0 !important;
}
@media only screen and (max-width: 1225px) {
  #sie-boilerplate .grid-col.button-container--right,
  .mat-mdc-dialog-container .grid-col.button-container--right,
  .cdk-overlay-container .grid-col.button-container--right,
  .cdk-drag-preview .grid-col.button-container--right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0;
    max-height: 44px;
    z-index: 101;
  }
  #sie-boilerplate .grid-col.button-container--right.optional,
  .mat-mdc-dialog-container .grid-col.button-container--right.optional,
  .cdk-overlay-container .grid-col.button-container--right.optional,
  .cdk-drag-preview .grid-col.button-container--right.optional {
    display: none;
  }
  #sie-boilerplate .grid-col.button-container--right button,
  #sie-boilerplate .grid-col.button-container--right mat-select,
  #sie-boilerplate .grid-col.button-container--right ceres-button,
  .mat-mdc-dialog-container .grid-col.button-container--right button,
  .mat-mdc-dialog-container .grid-col.button-container--right mat-select,
  .mat-mdc-dialog-container .grid-col.button-container--right ceres-button,
  .cdk-overlay-container .grid-col.button-container--right button,
  .cdk-overlay-container .grid-col.button-container--right mat-select,
  .cdk-overlay-container .grid-col.button-container--right ceres-button,
  .cdk-drag-preview .grid-col.button-container--right button,
  .cdk-drag-preview .grid-col.button-container--right mat-select,
  .cdk-drag-preview .grid-col.button-container--right ceres-button {
    min-width: 250px !important;
    max-width: 250px !important;
    margin: 3px 0 3px 0 !important;
    visibility: hidden;
    transition: visibility 0.15s ease-in-out;
  }
  #sie-boilerplate .grid-col.button-container--right button.triggered,
  #sie-boilerplate .grid-col.button-container--right mat-select.triggered,
  #sie-boilerplate .grid-col.button-container--right ceres-button.triggered,
  .mat-mdc-dialog-container .grid-col.button-container--right button.triggered,
  .mat-mdc-dialog-container .grid-col.button-container--right mat-select.triggered,
  .mat-mdc-dialog-container .grid-col.button-container--right ceres-button.triggered,
  .cdk-overlay-container .grid-col.button-container--right button.triggered,
  .cdk-overlay-container .grid-col.button-container--right mat-select.triggered,
  .cdk-overlay-container .grid-col.button-container--right ceres-button.triggered,
  .cdk-drag-preview .grid-col.button-container--right button.triggered,
  .cdk-drag-preview .grid-col.button-container--right mat-select.triggered,
  .cdk-drag-preview .grid-col.button-container--right ceres-button.triggered {
    visibility: visible;
  }
  #sie-boilerplate .grid-col.button-container--right button.triggered > *,
  #sie-boilerplate .grid-col.button-container--right mat-select.triggered > *,
  #sie-boilerplate .grid-col.button-container--right ceres-button.triggered > *,
  .mat-mdc-dialog-container .grid-col.button-container--right button.triggered > *,
  .mat-mdc-dialog-container .grid-col.button-container--right mat-select.triggered > *,
  .mat-mdc-dialog-container .grid-col.button-container--right ceres-button.triggered > *,
  .cdk-overlay-container .grid-col.button-container--right button.triggered > *,
  .cdk-overlay-container .grid-col.button-container--right mat-select.triggered > *,
  .cdk-overlay-container .grid-col.button-container--right ceres-button.triggered > *,
  .cdk-drag-preview .grid-col.button-container--right button.triggered > *,
  .cdk-drag-preview .grid-col.button-container--right mat-select.triggered > *,
  .cdk-drag-preview .grid-col.button-container--right ceres-button.triggered > * {
    visibility: visible;
    margin: 0 !important;
  }
  #sie-boilerplate .grid-col.button-container--right button.optional2,
  #sie-boilerplate .grid-col.button-container--right mat-select.optional2,
  #sie-boilerplate .grid-col.button-container--right ceres-button.optional2,
  .mat-mdc-dialog-container .grid-col.button-container--right button.optional2,
  .mat-mdc-dialog-container .grid-col.button-container--right mat-select.optional2,
  .mat-mdc-dialog-container .grid-col.button-container--right ceres-button.optional2,
  .cdk-overlay-container .grid-col.button-container--right button.optional2,
  .cdk-overlay-container .grid-col.button-container--right mat-select.optional2,
  .cdk-overlay-container .grid-col.button-container--right ceres-button.optional2,
  .cdk-drag-preview .grid-col.button-container--right button.optional2,
  .cdk-drag-preview .grid-col.button-container--right mat-select.optional2,
  .cdk-drag-preview .grid-col.button-container--right ceres-button.optional2 {
    display: none;
  }
  #sie-boilerplate .grid-col.button-container--right button.optional,
  .mat-mdc-dialog-container .grid-col.button-container--right button.optional,
  .cdk-overlay-container .grid-col.button-container--right button.optional,
  .cdk-drag-preview .grid-col.button-container--right button.optional {
    display: flex;
  }
  #sie-boilerplate .grid-col.button-container--right mat-select.optional,
  .mat-mdc-dialog-container .grid-col.button-container--right mat-select.optional,
  .cdk-overlay-container .grid-col.button-container--right mat-select.optional,
  .cdk-drag-preview .grid-col.button-container--right mat-select.optional {
    display: inline-block;
  }
  #sie-boilerplate .grid-col.button-container--right .burger,
  .mat-mdc-dialog-container .grid-col.button-container--right .burger,
  .cdk-overlay-container .grid-col.button-container--right .burger,
  .cdk-drag-preview .grid-col.button-container--right .burger {
    visibility: visible;
    min-width: auto !important;
  }
}
@media only screen and (min-width: 1226px) {
  #sie-boilerplate .grid-col.button-container--right,
  .mat-mdc-dialog-container .grid-col.button-container--right,
  .cdk-overlay-container .grid-col.button-container--right,
  .cdk-drag-preview .grid-col.button-container--right {
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
  }
  #sie-boilerplate .grid-col.button-container--right button.optional,
  .mat-mdc-dialog-container .grid-col.button-container--right button.optional,
  .cdk-overlay-container .grid-col.button-container--right button.optional,
  .cdk-drag-preview .grid-col.button-container--right button.optional {
    display: none;
  }
  #sie-boilerplate .grid-col.button-container--right mat-select.optional,
  .mat-mdc-dialog-container .grid-col.button-container--right mat-select.optional,
  .cdk-overlay-container .grid-col.button-container--right mat-select.optional,
  .cdk-drag-preview .grid-col.button-container--right mat-select.optional {
    display: none;
  }
}
#sie-boilerplate .grid-col.button-container button :last-of-type,
.mat-mdc-dialog-container .grid-col.button-container button :last-of-type,
.cdk-overlay-container .grid-col.button-container button :last-of-type,
.cdk-drag-preview .grid-col.button-container button :last-of-type {
  margin-right: 0 !important;
}
#sie-boilerplate .flex-center,
.mat-mdc-dialog-container .flex-center,
.cdk-overlay-container .flex-center,
.cdk-drag-preview .flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
#sie-boilerplate .spinner-container,
.mat-mdc-dialog-container .spinner-container,
.cdk-overlay-container .spinner-container,
.cdk-drag-preview .spinner-container {
  height: calc(100vh - 118px);
}
#sie-boilerplate .spinner-container.overlay,
.mat-mdc-dialog-container .spinner-container.overlay,
.cdk-overlay-container .spinner-container.overlay,
.cdk-drag-preview .spinner-container.overlay {
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--ceres-primary-color-8);
  z-index: 999;
  height: auto;
}
#sie-boilerplate ::-webkit-scrollbar,
.mat-mdc-dialog-container ::-webkit-scrollbar,
.cdk-overlay-container ::-webkit-scrollbar,
.cdk-drag-preview ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
#sie-boilerplate ::-webkit-scrollbar-thumb,
.mat-mdc-dialog-container ::-webkit-scrollbar-thumb,
.cdk-overlay-container ::-webkit-scrollbar-thumb,
.cdk-drag-preview ::-webkit-scrollbar-thumb {
  background: var(--ceres-secondary-color-1);
}
#sie-boilerplate ::-webkit-scrollbar-thumb:hover,
.mat-mdc-dialog-container ::-webkit-scrollbar-thumb:hover,
.cdk-overlay-container ::-webkit-scrollbar-thumb:hover,
.cdk-drag-preview ::-webkit-scrollbar-thumb:hover {
  background: var(--ceres-secondary-color-1);
}
#sie-boilerplate mat-chip,
.mat-mdc-dialog-container mat-chip,
.cdk-overlay-container mat-chip,
.cdk-drag-preview mat-chip {
  background-color: var(--ceres-form-field-background);
  border-radius: 0 !important;
  padding: 4px;
  height: fit-content;
}
#sie-boilerplate mat-chip mat-icon,
.mat-mdc-dialog-container mat-chip mat-icon,
.cdk-overlay-container mat-chip mat-icon,
.cdk-drag-preview mat-chip mat-icon {
  color: var(--ceres-primary-color-1);
  opacity: 1;
}
#sie-boilerplate mat-chip:hover, #sie-boilerplate mat-chip:active,
.mat-mdc-dialog-container mat-chip:hover,
.mat-mdc-dialog-container mat-chip:active,
.cdk-overlay-container mat-chip:hover,
.cdk-overlay-container mat-chip:active,
.cdk-drag-preview mat-chip:hover,
.cdk-drag-preview mat-chip:active {
  filter: brightness(0.9);
  color: var(--ceres-primary-color-1) !important;
  opacity: 1;
  cursor: pointer;
}
#sie-boilerplate mat-chip:hover mat-icon, #sie-boilerplate mat-chip:active mat-icon,
.mat-mdc-dialog-container mat-chip:hover mat-icon,
.mat-mdc-dialog-container mat-chip:active mat-icon,
.cdk-overlay-container mat-chip:hover mat-icon,
.cdk-overlay-container mat-chip:active mat-icon,
.cdk-drag-preview mat-chip:hover mat-icon,
.cdk-drag-preview mat-chip:active mat-icon {
  color: var(--ceres-primary-color-1) !important;
  opacity: 1;
}
#sie-boilerplate .form-container .options-form,
.mat-mdc-dialog-container .form-container .options-form,
.cdk-overlay-container .form-container .options-form,
.cdk-drag-preview .form-container .options-form {
  padding: 14px 14px 0px 14px;
  background-color: var(--ceres-primary-color-7);
  margin-bottom: 30px;
}
#sie-boilerplate .form-container .options-form:last-of-type,
.mat-mdc-dialog-container .form-container .options-form:last-of-type,
.cdk-overlay-container .form-container .options-form:last-of-type,
.cdk-drag-preview .form-container .options-form:last-of-type {
  margin-bottom: 0 !important;
}
#sie-boilerplate .form-container .options-form--header,
.mat-mdc-dialog-container .form-container .options-form--header,
.cdk-overlay-container .form-container .options-form--header,
.cdk-drag-preview .form-container .options-form--header {
  margin-bottom: 0;
  border-bottom: 1px solid var(--ceres-black);
  padding: 7px;
  background-color: var(--ceres-primary-color-3);
}
#sie-boilerplate .form-container .options-form--header p,
#sie-boilerplate .form-container .options-form--header b,
.mat-mdc-dialog-container .form-container .options-form--header p,
.mat-mdc-dialog-container .form-container .options-form--header b,
.cdk-overlay-container .form-container .options-form--header p,
.cdk-overlay-container .form-container .options-form--header b,
.cdk-drag-preview .form-container .options-form--header p,
.cdk-drag-preview .form-container .options-form--header b {
  color: var(--ceres-static-white) !important;
}
#sie-boilerplate .mat-mdc-checkbox-checked .mat-mdc-checkbox-background,
#sie-boilerplate .mat-mdc-checkbox-indeterminate .mat-mdc-checkbox-background,
.mat-mdc-dialog-container .mat-mdc-checkbox-checked .mat-mdc-checkbox-background,
.mat-mdc-dialog-container .mat-mdc-checkbox-indeterminate .mat-mdc-checkbox-background,
.cdk-overlay-container .mat-mdc-checkbox-checked .mat-mdc-checkbox-background,
.cdk-overlay-container .mat-mdc-checkbox-indeterminate .mat-mdc-checkbox-background,
.cdk-drag-preview .mat-mdc-checkbox-checked .mat-mdc-checkbox-background,
.cdk-drag-preview .mat-mdc-checkbox-indeterminate .mat-mdc-checkbox-background {
  background-color: var(--ceres-primary-color-1) !important;
}
#sie-boilerplate .mat-ink-bar,
.mat-mdc-dialog-container .mat-ink-bar,
.cdk-overlay-container .mat-ink-bar,
.cdk-drag-preview .mat-ink-bar {
  background-color: var(--ceres-primary-color-3) !important;
}
#sie-boilerplate .mat-pseudo-checkbox-checked,
.mat-mdc-dialog-container .mat-pseudo-checkbox-checked,
.cdk-overlay-container .mat-pseudo-checkbox-checked,
.cdk-drag-preview .mat-pseudo-checkbox-checked {
  background-color: var(--ceres-primary-color-1) !important;
}
#sie-boilerplate .mat-mdc-checkbox.mat-mdc-checkbox-semichecked .mat-mdc-checkbox-background,
.mat-mdc-dialog-container .mat-mdc-checkbox.mat-mdc-checkbox-semichecked .mat-mdc-checkbox-background,
.cdk-overlay-container .mat-mdc-checkbox.mat-mdc-checkbox-semichecked .mat-mdc-checkbox-background,
.cdk-drag-preview .mat-mdc-checkbox.mat-mdc-checkbox-semichecked .mat-mdc-checkbox-background {
  z-index: 1;
  background-color: var(--ceres-primary-color-3);
  margin: 4px;
}
#sie-boilerplate .mat-mdc-checkbox.mat-mdc-checkbox-semichecked .mat-mdc-checkbox-frame,
.mat-mdc-dialog-container .mat-mdc-checkbox.mat-mdc-checkbox-semichecked .mat-mdc-checkbox-frame,
.cdk-overlay-container .mat-mdc-checkbox.mat-mdc-checkbox-semichecked .mat-mdc-checkbox-frame,
.cdk-drag-preview .mat-mdc-checkbox.mat-mdc-checkbox-semichecked .mat-mdc-checkbox-frame {
  z-index: 2;
  border-color: var(--ceres-black-opaque-54);
}
#sie-boilerplate .mat-mdc-checkbox-touch-target,
.mat-mdc-dialog-container .mat-mdc-checkbox-touch-target,
.cdk-overlay-container .mat-mdc-checkbox-touch-target,
.cdk-drag-preview .mat-mdc-checkbox-touch-target {
  height: 18px;
  width: 18px;
}
#sie-boilerplate .pwo-checkbox,
.mat-mdc-dialog-container .pwo-checkbox,
.cdk-overlay-container .pwo-checkbox,
.cdk-drag-preview .pwo-checkbox {
  height: 44px;
  display: flex !important;
  align-items: center;
}
#sie-boilerplate .mat-mdc-footer-row,
.mat-mdc-dialog-container .mat-mdc-footer-row,
.cdk-overlay-container .mat-mdc-footer-row,
.cdk-drag-preview .mat-mdc-footer-row {
  border-top: 1.5px solid var(--ceres-primary-color-6);
  background-color: var(--ceres-primary-color-8);
}
#sie-boilerplate .pwo-no-underline .mat-mdc-form-field-wrapper,
.mat-mdc-dialog-container .pwo-no-underline .mat-mdc-form-field-wrapper,
.cdk-overlay-container .pwo-no-underline .mat-mdc-form-field-wrapper,
.cdk-drag-preview .pwo-no-underline .mat-mdc-form-field-wrapper {
  padding-bottom: 0 !important;
}
#sie-boilerplate .pwo-no-underline .mat-mdc-form-field-underline,
.mat-mdc-dialog-container .pwo-no-underline .mat-mdc-form-field-underline,
.cdk-overlay-container .pwo-no-underline .mat-mdc-form-field-underline,
.cdk-drag-preview .pwo-no-underline .mat-mdc-form-field-underline {
  display: none;
}
#sie-boilerplate .pwo-no-underline .mat-mdc-form-field-label,
.mat-mdc-dialog-container .pwo-no-underline .mat-mdc-form-field-label,
.cdk-overlay-container .pwo-no-underline .mat-mdc-form-field-label,
.cdk-drag-preview .pwo-no-underline .mat-mdc-form-field-label {
  color: var(--ceres-primary-color-2);
  font-size: 16px;
}
#sie-boilerplate .pwo-no-underline .mat-mdc-form-field-infix,
.mat-mdc-dialog-container .pwo-no-underline .mat-mdc-form-field-infix,
.cdk-overlay-container .pwo-no-underline .mat-mdc-form-field-infix,
.cdk-drag-preview .pwo-no-underline .mat-mdc-form-field-infix {
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: "Siemens Sans", sans-serif !important;
}
#sie-boilerplate .pwo-no-underline .mat-mdc-form-field-infix label,
.mat-mdc-dialog-container .pwo-no-underline .mat-mdc-form-field-infix label,
.cdk-overlay-container .pwo-no-underline .mat-mdc-form-field-infix label,
.cdk-drag-preview .pwo-no-underline .mat-mdc-form-field-infix label {
  margin-left: 3px;
}
#sie-boilerplate .space-checkbox,
.mat-mdc-dialog-container .space-checkbox,
.cdk-overlay-container .space-checkbox,
.cdk-drag-preview .space-checkbox {
  display: flex;
  height: 44px;
  align-items: center;
}
#sie-boilerplate .space-checkbox mat-checkbox,
.mat-mdc-dialog-container .space-checkbox mat-checkbox,
.cdk-overlay-container .space-checkbox mat-checkbox,
.cdk-drag-preview .space-checkbox mat-checkbox {
  width: 100%;
}
#sie-boilerplate .space-checkbox .mat-mdc-checkbox-layout,
.mat-mdc-dialog-container .space-checkbox .mat-mdc-checkbox-layout,
.cdk-overlay-container .space-checkbox .mat-mdc-checkbox-layout,
.cdk-drag-preview .space-checkbox .mat-mdc-checkbox-layout {
  justify-content: flex-start;
  width: 100%;
}
#sie-boilerplate .space-checkbox .mat-mdc-checkbox-inner-container,
.mat-mdc-dialog-container .space-checkbox .mat-mdc-checkbox-inner-container,
.cdk-overlay-container .space-checkbox .mat-mdc-checkbox-inner-container,
.cdk-drag-preview .space-checkbox .mat-mdc-checkbox-inner-container {
  margin: 0 10px 0 0 !important;
}
#sie-boilerplate .cursored,
.mat-mdc-dialog-container .cursored,
.cdk-overlay-container .cursored,
.cdk-drag-preview .cursored {
  cursor: pointer;
}
#sie-boilerplate .button-menu-container,
.mat-mdc-dialog-container .button-menu-container,
.cdk-overlay-container .button-menu-container,
.cdk-drag-preview .button-menu-container {
  background: transparent !important;
  box-shadow: none;
}
#sie-boilerplate .button-menu-container button,
.mat-mdc-dialog-container .button-menu-container button,
.cdk-overlay-container .button-menu-container button,
.cdk-drag-preview .button-menu-container button {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}
#sie-boilerplate .space,
.mat-mdc-dialog-container .space,
.cdk-overlay-container .space,
.cdk-drag-preview .space {
  min-height: 1px;
}
#sie-boilerplate .month-row,
.mat-mdc-dialog-container .month-row,
.cdk-overlay-container .month-row,
.cdk-drag-preview .month-row {
  margin-bottom: 30px !important;
  justify-content: center;
  display: flex;
}
#sie-boilerplate .month-row .month-title p,
.mat-mdc-dialog-container .month-row .month-title p,
.cdk-overlay-container .month-row .month-title p,
.cdk-drag-preview .month-row .month-title p {
  font-weight: bold !important;
}
#sie-boilerplate .paginator,
.mat-mdc-dialog-container .paginator,
.cdk-overlay-container .paginator,
.cdk-drag-preview .paginator {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
#sie-boilerplate .paginator button,
.mat-mdc-dialog-container .paginator button,
.cdk-overlay-container .paginator button,
.cdk-drag-preview .paginator button {
  padding: 0;
  width: 24px;
  height: 24px;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
#sie-boilerplate .paginator mat-select,
.mat-mdc-dialog-container .paginator mat-select,
.cdk-overlay-container .paginator mat-select,
.cdk-drag-preview .paginator mat-select {
  height: fit-content;
  width: 150px;
}
#sie-boilerplate .header,
.mat-mdc-dialog-container .header,
.cdk-overlay-container .header,
.cdk-drag-preview .header {
  min-height: 44px;
  display: flex;
  align-items: center;
}
#sie-boilerplate .mat-calendar-body-selected,
.mat-mdc-dialog-container .mat-calendar-body-selected,
.cdk-overlay-container .mat-calendar-body-selected,
.cdk-drag-preview .mat-calendar-body-selected {
  background-color: var(--ceres-primary-color-1);
}
#sie-boilerplate .mat-datepicker-toggle-active,
.mat-mdc-dialog-container .mat-datepicker-toggle-active,
.cdk-overlay-container .mat-datepicker-toggle-active,
.cdk-drag-preview .mat-datepicker-toggle-active {
  color: var(--ceres-primary-color-1);
}
#sie-boilerplate ceres-multi-select,
.mat-mdc-dialog-container ceres-multi-select,
.cdk-overlay-container ceres-multi-select,
.cdk-drag-preview ceres-multi-select {
  cursor: pointer;
  position: absolute;
  top: 0px;
  z-index: 100;
  right: 15px;
  background-color: var(--ceres-primary-color-1);
  height: 57px;
  width: 50px;
  color: var(--ceres-white);
}
#sie-boilerplate ceres-multi-select.column-select-filter,
.mat-mdc-dialog-container ceres-multi-select.column-select-filter,
.cdk-overlay-container ceres-multi-select.column-select-filter,
.cdk-drag-preview ceres-multi-select.column-select-filter {
  margin-right: -2px;
}
#sie-boilerplate ceres-multi-select.no-right,
.mat-mdc-dialog-container ceres-multi-select.no-right,
.cdk-overlay-container ceres-multi-select.no-right,
.cdk-drag-preview ceres-multi-select.no-right {
  right: 0;
  margin-right: 0 !important;
}
#sie-boilerplate ceres-multi-select > .trigger,
.mat-mdc-dialog-container ceres-multi-select > .trigger,
.cdk-overlay-container ceres-multi-select > .trigger,
.cdk-drag-preview ceres-multi-select > .trigger {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  align-items: center;
}
#sie-boilerplate .top-row,
.mat-mdc-dialog-container .top-row,
.cdk-overlay-container .top-row,
.cdk-drag-preview .top-row {
  margin-top: 30px;
}
#sie-boilerplate .main-container-spinner,
.mat-mdc-dialog-container .main-container-spinner,
.cdk-overlay-container .main-container-spinner,
.cdk-drag-preview .main-container-spinner {
  margin-top: 25px;
  height: calc(100vh - 196px);
  display: flex;
  align-items: center;
}
#sie-boilerplate .main-container-spinner-50,
.mat-mdc-dialog-container .main-container-spinner-50,
.cdk-overlay-container .main-container-spinner-50,
.cdk-drag-preview .main-container-spinner-50 {
  margin-top: 50px;
}
#sie-boilerplate .main-spinner,
.mat-mdc-dialog-container .main-spinner,
.cdk-overlay-container .main-spinner,
.cdk-drag-preview .main-spinner {
  margin: 0 auto;
}
#sie-boilerplate .date-warning,
.mat-mdc-dialog-container .date-warning,
.cdk-overlay-container .date-warning,
.cdk-drag-preview .date-warning {
  font-size: 14px;
  color: orange;
}
#sie-boilerplate .mat-step-header .mat-step-icon-selected,
#sie-boilerplate .mat-step-header .mat-step-icon-state-done,
#sie-boilerplate .mat-step-header .mat-step-icon-state-edit,
.mat-mdc-dialog-container .mat-step-header .mat-step-icon-selected,
.mat-mdc-dialog-container .mat-step-header .mat-step-icon-state-done,
.mat-mdc-dialog-container .mat-step-header .mat-step-icon-state-edit,
.cdk-overlay-container .mat-step-header .mat-step-icon-selected,
.cdk-overlay-container .mat-step-header .mat-step-icon-state-done,
.cdk-overlay-container .mat-step-header .mat-step-icon-state-edit,
.cdk-drag-preview .mat-step-header .mat-step-icon-selected,
.cdk-drag-preview .mat-step-header .mat-step-icon-state-done,
.cdk-drag-preview .mat-step-header .mat-step-icon-state-edit {
  background-color: var(--ceres-primary-color-1) !important;
  color: var(--ceres-white);
}
#sie-boilerplate .pwo-tooltip,
.mat-mdc-dialog-container .pwo-tooltip,
.cdk-overlay-container .pwo-tooltip,
.cdk-drag-preview .pwo-tooltip {
  font-size: 14px;
  color: var(--ceres-white);
}
#sie-boilerplate .dialog-header,
.mat-mdc-dialog-container .dialog-header,
.cdk-overlay-container .dialog-header,
.cdk-drag-preview .dialog-header {
  display: flex;
  align-items: center;
  padding: 7px;
  margin: -14px -22px 12px;
}
#sie-boilerplate .dialog-header p,
#sie-boilerplate .dialog-header b,
#sie-boilerplate .dialog-header h4,
#sie-boilerplate .dialog-header .h4,
.mat-mdc-dialog-container .dialog-header p,
.mat-mdc-dialog-container .dialog-header b,
.mat-mdc-dialog-container .dialog-header h4,
.mat-mdc-dialog-container .dialog-header .h4,
.cdk-overlay-container .dialog-header p,
.cdk-overlay-container .dialog-header b,
.cdk-overlay-container .dialog-header h4,
.cdk-overlay-container .dialog-header .h4,
.cdk-drag-preview .dialog-header p,
.cdk-drag-preview .dialog-header b,
.cdk-drag-preview .dialog-header h4,
.cdk-drag-preview .dialog-header .h4 {
  color: var(--ceres-black) !important;
}
#sie-boilerplate .dialog-header .left,
#sie-boilerplate .dialog-header .right,
.mat-mdc-dialog-container .dialog-header .left,
.mat-mdc-dialog-container .dialog-header .right,
.cdk-overlay-container .dialog-header .left,
.cdk-overlay-container .dialog-header .right,
.cdk-drag-preview .dialog-header .left,
.cdk-drag-preview .dialog-header .right {
  display: flex;
}
#sie-boilerplate .dialog-header .left,
.mat-mdc-dialog-container .dialog-header .left,
.cdk-overlay-container .dialog-header .left,
.cdk-drag-preview .dialog-header .left {
  justify-content: flex-start;
}
#sie-boilerplate .dialog-header .right,
.mat-mdc-dialog-container .dialog-header .right,
.cdk-overlay-container .dialog-header .right,
.cdk-drag-preview .dialog-header .right {
  justify-content: flex-end;
}
#sie-boilerplate .dialog-header .right button,
.mat-mdc-dialog-container .dialog-header .right button,
.cdk-overlay-container .dialog-header .right button,
.cdk-drag-preview .dialog-header .right button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  cursor: pointer;
  color: var(--ceres-black);
}
#sie-boilerplate .dialog-header .right button i,
.mat-mdc-dialog-container .dialog-header .right button i,
.cdk-overlay-container .dialog-header .right button i,
.cdk-drag-preview .dialog-header .right button i {
  font-size: 20px;
}
#sie-boilerplate .mat-custom.mat-mdc-form-field-appearance-fill .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow, #sie-boilerplate .mat-custom.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-label, #sie-boilerplate .mat-custom.mat-mdc-form-field-appearance-fill .mat-mdc-select-value-text, #sie-boilerplate .mat-custom.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex,
.mat-mdc-dialog-container .mat-custom.mat-mdc-form-field-appearance-fill .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow,
.mat-mdc-dialog-container .mat-custom.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-label,
.mat-mdc-dialog-container .mat-custom.mat-mdc-form-field-appearance-fill .mat-mdc-select-value-text,
.mat-mdc-dialog-container .mat-custom.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex,
.cdk-overlay-container .mat-custom.mat-mdc-form-field-appearance-fill .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow,
.cdk-overlay-container .mat-custom.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-label,
.cdk-overlay-container .mat-custom.mat-mdc-form-field-appearance-fill .mat-mdc-select-value-text,
.cdk-overlay-container .mat-custom.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex,
.cdk-drag-preview .mat-custom.mat-mdc-form-field-appearance-fill .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow,
.cdk-drag-preview .mat-custom.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-label,
.cdk-drag-preview .mat-custom.mat-mdc-form-field-appearance-fill .mat-mdc-select-value-text,
.cdk-drag-preview .mat-custom.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex {
  color: var(--ceres-input);
}
#sie-boilerplate .mat-custom.mat-mdc-form-field-appearance-fill.mat-mdc-form-field-disabled .mat-mdc-form-field-flex,
.mat-mdc-dialog-container .mat-custom.mat-mdc-form-field-appearance-fill.mat-mdc-form-field-disabled .mat-mdc-form-field-flex,
.cdk-overlay-container .mat-custom.mat-mdc-form-field-appearance-fill.mat-mdc-form-field-disabled .mat-mdc-form-field-flex,
.cdk-drag-preview .mat-custom.mat-mdc-form-field-appearance-fill.mat-mdc-form-field-disabled .mat-mdc-form-field-flex {
  background-color: rgba(246, 246, 246, var(--ceres-disabled-form-field-opacity)) !important;
}
#sie-boilerplate .mat-custom.mat-mdc-form-field-appearance-fill.mat-mdc-form-field-disabled .mat-mdc-form-field-flex input, #sie-boilerplate .mat-custom.mat-mdc-form-field-appearance-fill.mat-mdc-form-field-disabled .mat-mdc-form-field-label,
.mat-mdc-dialog-container .mat-custom.mat-mdc-form-field-appearance-fill.mat-mdc-form-field-disabled .mat-mdc-form-field-flex input,
.mat-mdc-dialog-container .mat-custom.mat-mdc-form-field-appearance-fill.mat-mdc-form-field-disabled .mat-mdc-form-field-label,
.cdk-overlay-container .mat-custom.mat-mdc-form-field-appearance-fill.mat-mdc-form-field-disabled .mat-mdc-form-field-flex input,
.cdk-overlay-container .mat-custom.mat-mdc-form-field-appearance-fill.mat-mdc-form-field-disabled .mat-mdc-form-field-label,
.cdk-drag-preview .mat-custom.mat-mdc-form-field-appearance-fill.mat-mdc-form-field-disabled .mat-mdc-form-field-flex input,
.cdk-drag-preview .mat-custom.mat-mdc-form-field-appearance-fill.mat-mdc-form-field-disabled .mat-mdc-form-field-label {
  color: var(--ceres-input);
}
#sie-boilerplate .mat-custom.mat-mdc-form-field-appearance-fill.mat-custom-form-clickable .mat-mdc-form-field-flex,
.mat-mdc-dialog-container .mat-custom.mat-mdc-form-field-appearance-fill.mat-custom-form-clickable .mat-mdc-form-field-flex,
.cdk-overlay-container .mat-custom.mat-mdc-form-field-appearance-fill.mat-custom-form-clickable .mat-mdc-form-field-flex,
.cdk-drag-preview .mat-custom.mat-mdc-form-field-appearance-fill.mat-custom-form-clickable .mat-mdc-form-field-flex {
  background-color: rgba(246, 246, 246, var(--ceres-disabled-form-field-opacity)) !important;
}
#sie-boilerplate .mat-custom.mat-mdc-form-field-appearance-fill.mat-custom-form-clickable .mat-mdc-form-field-underline,
.mat-mdc-dialog-container .mat-custom.mat-mdc-form-field-appearance-fill.mat-custom-form-clickable .mat-mdc-form-field-underline,
.cdk-overlay-container .mat-custom.mat-mdc-form-field-appearance-fill.mat-custom-form-clickable .mat-mdc-form-field-underline,
.cdk-drag-preview .mat-custom.mat-mdc-form-field-appearance-fill.mat-custom-form-clickable .mat-mdc-form-field-underline {
  height: 0 !important;
  display: none;
}
#sie-boilerplate .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex,
.mat-mdc-dialog-container .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex,
.cdk-overlay-container .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex,
.cdk-drag-preview .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex {
  background-color: var(--ceres-input-background);
}
#sie-boilerplate .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex mat-icon,
.mat-mdc-dialog-container .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex mat-icon,
.cdk-overlay-container .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex mat-icon,
.cdk-drag-preview .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex mat-icon {
  color: var(--ceres-primary-color-1);
}
#sie-boilerplate .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(input[disabled]), #sie-boilerplate .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(textarea[disabled]),
.mat-mdc-dialog-container .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(input[disabled]),
.mat-mdc-dialog-container .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(textarea[disabled]),
.cdk-overlay-container .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(input[disabled]),
.cdk-overlay-container .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(textarea[disabled]),
.cdk-drag-preview .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(input[disabled]),
.cdk-drag-preview .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(textarea[disabled]) {
  background-color: var(--ceres-primary-color-8);
  cursor: not-allowed;
}
#sie-boilerplate .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(input[disabled]) input, #sie-boilerplate .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(textarea[disabled]) input,
.mat-mdc-dialog-container .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(input[disabled]) input,
.mat-mdc-dialog-container .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(textarea[disabled]) input,
.cdk-overlay-container .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(input[disabled]) input,
.cdk-overlay-container .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(textarea[disabled]) input,
.cdk-drag-preview .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(input[disabled]) input,
.cdk-drag-preview .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(textarea[disabled]) input {
  cursor: not-allowed !important;
}
#sie-boilerplate .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(input[readonly]), #sie-boilerplate .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(textarea[readonly]),
.mat-mdc-dialog-container .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(input[readonly]),
.mat-mdc-dialog-container .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(textarea[readonly]),
.cdk-overlay-container .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(input[readonly]),
.cdk-overlay-container .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(textarea[readonly]),
.cdk-drag-preview .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(input[readonly]),
.cdk-drag-preview .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(textarea[readonly]) {
  cursor: not-allowed;
}
#sie-boilerplate .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(input[readonly]) input, #sie-boilerplate .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(textarea[readonly]) input,
.mat-mdc-dialog-container .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(input[readonly]) input,
.mat-mdc-dialog-container .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(textarea[readonly]) input,
.cdk-overlay-container .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(input[readonly]) input,
.cdk-overlay-container .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(textarea[readonly]) input,
.cdk-drag-preview .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(input[readonly]) input,
.cdk-drag-preview .mat-custom.mat-mdc-form-field-appearance-fill:not(.mat-custom-form-clickable) .mat-mdc-form-field-flex:has(textarea[readonly]) input {
  cursor: not-allowed !important;
}
#sie-boilerplate .mat-custom.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow, #sie-boilerplate .mat-custom.mat-mdc-form-field-invalid .mat-placeholder-required, #sie-boilerplate .mat-custom.mat-mdc-form-field-invalid .mat-mdc-form-field-label,
.mat-mdc-dialog-container .mat-custom.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow,
.mat-mdc-dialog-container .mat-custom.mat-mdc-form-field-invalid .mat-placeholder-required,
.mat-mdc-dialog-container .mat-custom.mat-mdc-form-field-invalid .mat-mdc-form-field-label,
.cdk-overlay-container .mat-custom.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow,
.cdk-overlay-container .mat-custom.mat-mdc-form-field-invalid .mat-placeholder-required,
.cdk-overlay-container .mat-custom.mat-mdc-form-field-invalid .mat-mdc-form-field-label,
.cdk-drag-preview .mat-custom.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow,
.cdk-drag-preview .mat-custom.mat-mdc-form-field-invalid .mat-placeholder-required,
.cdk-drag-preview .mat-custom.mat-mdc-form-field-invalid .mat-mdc-form-field-label {
  color: var(--ceres-secondary-color-14) !important;
}
#sie-boilerplate .mat-custom.mat-mdc-form-field-invalid .mat-mdc-form-field-underline .mat-mdc-form-field-ripple,
.mat-mdc-dialog-container .mat-custom.mat-mdc-form-field-invalid .mat-mdc-form-field-underline .mat-mdc-form-field-ripple,
.cdk-overlay-container .mat-custom.mat-mdc-form-field-invalid .mat-mdc-form-field-underline .mat-mdc-form-field-ripple,
.cdk-drag-preview .mat-custom.mat-mdc-form-field-invalid .mat-mdc-form-field-underline .mat-mdc-form-field-ripple {
  background: var(--ceres-secondary-color-14) !important;
}
#sie-boilerplate mat-table.mat-custom mat-footer-cell button mat-icon,
#sie-boilerplate mat-table.mat-custom mat-cell button mat-icon,
.mat-mdc-dialog-container mat-table.mat-custom mat-footer-cell button mat-icon,
.mat-mdc-dialog-container mat-table.mat-custom mat-cell button mat-icon,
.cdk-overlay-container mat-table.mat-custom mat-footer-cell button mat-icon,
.cdk-overlay-container mat-table.mat-custom mat-cell button mat-icon,
.cdk-drag-preview mat-table.mat-custom mat-footer-cell button mat-icon,
.cdk-drag-preview mat-table.mat-custom mat-cell button mat-icon {
  color: var(--ceres-input);
}
#sie-boilerplate mat-checkbox.mat-mdc-checkbox-disabled,
.mat-mdc-dialog-container mat-checkbox.mat-mdc-checkbox-disabled,
.cdk-overlay-container mat-checkbox.mat-mdc-checkbox-disabled,
.cdk-drag-preview mat-checkbox.mat-mdc-checkbox-disabled {
  cursor: not-allowed;
}
#sie-boilerplate .mat-fix-padding,
.mat-mdc-dialog-container .mat-fix-padding,
.cdk-overlay-container .mat-fix-padding,
.cdk-drag-preview .mat-fix-padding {
  margin-bottom: -1.25em;
}
#sie-boilerplate .multiline-tooltip,
.mat-mdc-dialog-container .multiline-tooltip,
.cdk-overlay-container .multiline-tooltip,
.cdk-drag-preview .multiline-tooltip {
  white-space: pre-line !important;
}
#sie-boilerplate mat-calendar-header,
.mat-mdc-dialog-container mat-calendar-header,
.cdk-overlay-container mat-calendar-header,
.cdk-drag-preview mat-calendar-header {
  color: var(--ceres-black);
}
#sie-boilerplate .mat-custom-spinner:disabled,
.mat-mdc-dialog-container .mat-custom-spinner:disabled,
.cdk-overlay-container .mat-custom-spinner:disabled,
.cdk-drag-preview .mat-custom-spinner:disabled {
  cursor: not-allowed !important;
}
#sie-boilerplate .mat-custom-spinner .mat-progress-spinner circle,
#sie-boilerplate .mat-custom-spinner .mat-spinner circle,
.mat-mdc-dialog-container .mat-custom-spinner .mat-progress-spinner circle,
.mat-mdc-dialog-container .mat-custom-spinner .mat-spinner circle,
.cdk-overlay-container .mat-custom-spinner .mat-progress-spinner circle,
.cdk-overlay-container .mat-custom-spinner .mat-spinner circle,
.cdk-drag-preview .mat-custom-spinner .mat-progress-spinner circle,
.cdk-drag-preview .mat-custom-spinner .mat-spinner circle {
  stroke: var(--ceres-white) !important;
}
#sie-boilerplate .mat-mdc-form-field,
.mat-mdc-dialog-container .mat-mdc-form-field,
.cdk-overlay-container .mat-mdc-form-field,
.cdk-drag-preview .mat-mdc-form-field {
  --mat-form-field-container-height: 32px;
  --mat-form-field-filled-with-label-container-padding-top: 12px;
  --mat-form-field-filled-with-label-container-padding-bottom: 12px;
  --mdc-filled-text-field-label-text-size: 16px;
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: "Siemens Sans", sans-serif;
  letter-spacing: normal;
}
#sie-boilerplate .mat-mdc-form-field .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined),
.mat-mdc-dialog-container .mat-mdc-form-field .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined),
.cdk-overlay-container .mat-mdc-form-field .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined),
.cdk-drag-preview .mat-mdc-form-field .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) {
  border-radius: 4px 4px 0 0;
  padding: 0.75em 0.75em 0 0.75em;
}
#sie-boilerplate .mat-mdc-form-field.mat-mdc-form-field-type-mat-select,
.mat-mdc-dialog-container .mat-mdc-form-field.mat-mdc-form-field-type-mat-select,
.cdk-overlay-container .mat-mdc-form-field.mat-mdc-form-field-type-mat-select,
.cdk-drag-preview .mat-mdc-form-field.mat-mdc-form-field-type-mat-select {
  --mat-select-trigger-text-size: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 8px !important;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px !important;
}
#sie-boilerplate .mdc-text-field--filled:not(.mdc-text-field--disabled),
.mat-mdc-dialog-container .mdc-text-field--filled:not(.mdc-text-field--disabled),
.cdk-overlay-container .mdc-text-field--filled:not(.mdc-text-field--disabled),
.cdk-drag-preview .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: var(--ceres-form-field-background) !important;
}
#sie-boilerplate .mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-chip-grid .mdc-text-field--filled:not(.mdc-text-field--disabled),
#sie-boilerplate .mat-mdc-dialog-container .mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-chip-grid .mdc-text-field--filled:not(.mdc-text-field--disabled),
#sie-boilerplate .cdk-overlay-container .mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-chip-grid .mdc-text-field--filled:not(.mdc-text-field--disabled),
#sie-boilerplate .cdk-drag-preview .mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-chip-grid .mdc-text-field--filled:not(.mdc-text-field--disabled),
.mat-mdc-dialog-container .mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-chip-grid .mdc-text-field--filled:not(.mdc-text-field--disabled),
.mat-mdc-dialog-container .mat-mdc-dialog-container .mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-chip-grid .mdc-text-field--filled:not(.mdc-text-field--disabled),
.mat-mdc-dialog-container .cdk-overlay-container .mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-chip-grid .mdc-text-field--filled:not(.mdc-text-field--disabled),
.mat-mdc-dialog-container .cdk-drag-preview .mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-chip-grid .mdc-text-field--filled:not(.mdc-text-field--disabled),
.cdk-overlay-container .mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-chip-grid .mdc-text-field--filled:not(.mdc-text-field--disabled),
.cdk-overlay-container .mat-mdc-dialog-container .mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-chip-grid .mdc-text-field--filled:not(.mdc-text-field--disabled),
.cdk-overlay-container .cdk-overlay-container .mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-chip-grid .mdc-text-field--filled:not(.mdc-text-field--disabled),
.cdk-overlay-container .cdk-drag-preview .mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-chip-grid .mdc-text-field--filled:not(.mdc-text-field--disabled),
.cdk-drag-preview .mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-chip-grid .mdc-text-field--filled:not(.mdc-text-field--disabled),
.cdk-drag-preview .mat-mdc-dialog-container .mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-chip-grid .mdc-text-field--filled:not(.mdc-text-field--disabled),
.cdk-drag-preview .cdk-overlay-container .mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-chip-grid .mdc-text-field--filled:not(.mdc-text-field--disabled),
.cdk-drag-preview .cdk-drag-preview .mat-form-field-appearance-fill.mat-mdc-form-field-type-mat-chip-grid .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}
#sie-boilerplate .mdc-text-field--outlined:not(.mat-form-field-type-mat-rate-range-input) .mat-mdc-form-field-infix,
#sie-boilerplate .mdc-text-field--no-label:not(.mat-form-field-type-mat-rate-range-input) .mat-mdc-form-field-infix,
.mat-mdc-dialog-container .mdc-text-field--outlined:not(.mat-form-field-type-mat-rate-range-input) .mat-mdc-form-field-infix,
.mat-mdc-dialog-container .mdc-text-field--no-label:not(.mat-form-field-type-mat-rate-range-input) .mat-mdc-form-field-infix,
.cdk-overlay-container .mdc-text-field--outlined:not(.mat-form-field-type-mat-rate-range-input) .mat-mdc-form-field-infix,
.cdk-overlay-container .mdc-text-field--no-label:not(.mat-form-field-type-mat-rate-range-input) .mat-mdc-form-field-infix,
.cdk-drag-preview .mdc-text-field--outlined:not(.mat-form-field-type-mat-rate-range-input) .mat-mdc-form-field-infix,
.cdk-drag-preview .mdc-text-field--no-label:not(.mat-form-field-type-mat-rate-range-input) .mat-mdc-form-field-infix {
  --mat-form-field-container-vertical-padding: 6px;
}
#sie-boilerplate .mat-mdc-form-field-focus-overlay,
.mat-mdc-dialog-container .mat-mdc-form-field-focus-overlay,
.cdk-overlay-container .mat-mdc-form-field-focus-overlay,
.cdk-drag-preview .mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}
#sie-boilerplate .mat-mdc-chip-grid.mdc-text-field--filled:not(.mdc-text-field--disabled),
#sie-boilerplate .mat-mdc-chip-set.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mat-mdc-dialog-container .mat-mdc-chip-grid.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mat-mdc-dialog-container .mat-mdc-chip-set.mdc-text-field--filled:not(.mdc-text-field--disabled),
.cdk-overlay-container .mat-mdc-chip-grid.mdc-text-field--filled:not(.mdc-text-field--disabled),
.cdk-overlay-container .mat-mdc-chip-set.mdc-text-field--filled:not(.mdc-text-field--disabled),
.cdk-drag-preview .mat-mdc-chip-grid.mdc-text-field--filled:not(.mdc-text-field--disabled),
.cdk-drag-preview .mat-mdc-chip-set.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}
#sie-boilerplate .mdc-text-field--disabled,
#sie-boilerplate .mdc-text-field--filled.mdc-text-field--disabled,
#sie-boilerplate .mdc-text-field--outlined.mdc-text-field--disabled,
#sie-boilerplate .mat-mdc-form-field-disabled,
.mat-mdc-dialog-container .mdc-text-field--disabled,
.mat-mdc-dialog-container .mdc-text-field--filled.mdc-text-field--disabled,
.mat-mdc-dialog-container .mdc-text-field--outlined.mdc-text-field--disabled,
.mat-mdc-dialog-container .mat-mdc-form-field-disabled,
.cdk-overlay-container .mdc-text-field--disabled,
.cdk-overlay-container .mdc-text-field--filled.mdc-text-field--disabled,
.cdk-overlay-container .mdc-text-field--outlined.mdc-text-field--disabled,
.cdk-overlay-container .mat-mdc-form-field-disabled,
.cdk-drag-preview .mdc-text-field--disabled,
.cdk-drag-preview .mdc-text-field--filled.mdc-text-field--disabled,
.cdk-drag-preview .mdc-text-field--outlined.mdc-text-field--disabled,
.cdk-drag-preview .mat-mdc-form-field-disabled {
  background-color: rgba(246, 246, 246, var(--ceres-disabled-form-field-opacity)) !important;
  color: var(--ceres-black) !important;
}
#sie-boilerplate .mdc-text-field--disabled .mdc-text-field__input:not(.link),
.mat-mdc-dialog-container .mdc-text-field--disabled .mdc-text-field__input:not(.link),
.cdk-overlay-container .mdc-text-field--disabled .mdc-text-field__input:not(.link),
.cdk-drag-preview .mdc-text-field--disabled .mdc-text-field__input:not(.link) {
  color: var(--ceres-black) !important;
}
#sie-boilerplate .mdc-text-field--disabled .mdc-floating-label,
#sie-boilerplate .mat-mdc-form-field-disabled .mat-mdc-form-field-label,
.mat-mdc-dialog-container .mdc-text-field--disabled .mdc-floating-label,
.mat-mdc-dialog-container .mat-mdc-form-field-disabled .mat-mdc-form-field-label,
.cdk-overlay-container .mdc-text-field--disabled .mdc-floating-label,
.cdk-overlay-container .mat-mdc-form-field-disabled .mat-mdc-form-field-label,
.cdk-drag-preview .mdc-text-field--disabled .mdc-floating-label,
.cdk-drag-preview .mat-mdc-form-field-disabled .mat-mdc-form-field-label {
  color: var(--ceres-black) !important;
}
#sie-boilerplate .mdc-text-field--filled.mdc-text-field--disabled,
.mat-mdc-dialog-container .mdc-text-field--filled.mdc-text-field--disabled,
.cdk-overlay-container .mdc-text-field--filled.mdc-text-field--disabled,
.cdk-drag-preview .mdc-text-field--filled.mdc-text-field--disabled {
  background-color: rgba(246, 246, 246, var(--ceres-disabled-form-field-opacity));
}
#sie-boilerplate .mat-mdc-radio-button .mdc-radio,
#sie-boilerplate .mdc-checkbox,
.mat-mdc-dialog-container .mat-mdc-radio-button .mdc-radio,
.mat-mdc-dialog-container .mdc-checkbox,
.cdk-overlay-container .mat-mdc-radio-button .mdc-radio,
.cdk-overlay-container .mdc-checkbox,
.cdk-drag-preview .mat-mdc-radio-button .mdc-radio,
.cdk-drag-preview .mdc-checkbox {
  padding: 0;
  margin: 0;
}
#sie-boilerplate .mdc-checkbox .mdc-checkbox__background,
.mat-mdc-dialog-container .mdc-checkbox .mdc-checkbox__background,
.cdk-overlay-container .mdc-checkbox .mdc-checkbox__background,
.cdk-drag-preview .mdc-checkbox .mdc-checkbox__background {
  top: unset;
  left: unset;
}
#sie-boilerplate .mat-mdc-option .mat-icon,
#sie-boilerplate .mat-mdc-option .mat-pseudo-checkbox-full,
#sie-boilerplate .mdc-checkbox,
.mat-mdc-dialog-container .mat-mdc-option .mat-icon,
.mat-mdc-dialog-container .mat-mdc-option .mat-pseudo-checkbox-full,
.mat-mdc-dialog-container .mdc-checkbox,
.cdk-overlay-container .mat-mdc-option .mat-icon,
.cdk-overlay-container .mat-mdc-option .mat-pseudo-checkbox-full,
.cdk-overlay-container .mdc-checkbox,
.cdk-drag-preview .mat-mdc-option .mat-icon,
.cdk-drag-preview .mat-mdc-option .mat-pseudo-checkbox-full,
.cdk-drag-preview .mdc-checkbox {
  margin-right: 8px;
}
#sie-boilerplate .mat-mdc-option,
.mat-mdc-dialog-container .mat-mdc-option,
.cdk-overlay-container .mat-mdc-option,
.cdk-drag-preview .mat-mdc-option {
  --mat-option-label-text-size: 14px;
}
#sie-boilerplate .mat-mdc-table,
.mat-mdc-dialog-container .mat-mdc-table,
.cdk-overlay-container .mat-mdc-table,
.cdk-drag-preview .mat-mdc-table {
  background-color: transparent;
}
#sie-boilerplate .mat-mdc-dialog-surface.mdc-dialog__surface,
.mat-mdc-dialog-container .mat-mdc-dialog-surface.mdc-dialog__surface,
.cdk-overlay-container .mat-mdc-dialog-surface.mdc-dialog__surface,
.cdk-drag-preview .mat-mdc-dialog-surface.mdc-dialog__surface {
  display: block;
  padding: 24px;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: auto;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}
#sie-boilerplate .content-container-title,
.mat-mdc-dialog-container .content-container-title,
.cdk-overlay-container .content-container-title,
.cdk-drag-preview .content-container-title {
  color: var(--ceres-black);
}
#sie-boilerplate .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after,
.mat-mdc-dialog-container .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after,
.cdk-overlay-container .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after,
.cdk-drag-preview .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
  color: var(--ceres-white) !important;
}
#sie-boilerplate .cdk-virtual-scroll-content-wrapper,
.mat-mdc-dialog-container .cdk-virtual-scroll-content-wrapper,
.cdk-overlay-container .cdk-virtual-scroll-content-wrapper,
.cdk-drag-preview .cdk-virtual-scroll-content-wrapper {
  display: flex;
  flex-direction: column;
}
#sie-boilerplate .mat-mdc-cell,
#sie-boilerplate .mat-mdc-header-cell,
.mat-mdc-dialog-container .mat-mdc-cell,
.mat-mdc-dialog-container .mat-mdc-header-cell,
.cdk-overlay-container .mat-mdc-cell,
.cdk-overlay-container .mat-mdc-header-cell,
.cdk-drag-preview .mat-mdc-cell,
.cdk-drag-preview .mat-mdc-header-cell {
  border-bottom-style: none !important;
}
#sie-boilerplate .mat-mdc-form-field-icon-prefix > .mat-icon,
#sie-boilerplate .mat-mdc-form-field-icon-suffix > .mat-icon,
.mat-mdc-dialog-container .mat-mdc-form-field-icon-prefix > .mat-icon,
.mat-mdc-dialog-container .mat-mdc-form-field-icon-suffix > .mat-icon,
.cdk-overlay-container .mat-mdc-form-field-icon-prefix > .mat-icon,
.cdk-overlay-container .mat-mdc-form-field-icon-suffix > .mat-icon,
.cdk-drag-preview .mat-mdc-form-field-icon-prefix > .mat-icon,
.cdk-drag-preview .mat-mdc-form-field-icon-suffix > .mat-icon {
  padding: 0 !important;
  color: var(--ceres-primary-color-1);
}
#sie-boilerplate .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label,
#sie-boilerplate .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label--float-above,
.mat-mdc-dialog-container .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label,
.mat-mdc-dialog-container .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label--float-above,
.cdk-overlay-container .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label,
.cdk-overlay-container .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label--float-above,
.cdk-drag-preview .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label,
.cdk-drag-preview .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
  color: var(--ceres-black);
}
#sie-boilerplate .mdc-evolution-chip__cell,
.mat-mdc-dialog-container .mdc-evolution-chip__cell,
.cdk-overlay-container .mdc-evolution-chip__cell,
.cdk-drag-preview .mdc-evolution-chip__cell {
  margin: 0 4px;
}
#sie-boilerplate .mat-mdc-menu-item-text,
.mat-mdc-dialog-container .mat-mdc-menu-item-text,
.cdk-overlay-container .mat-mdc-menu-item-text,
.cdk-drag-preview .mat-mdc-menu-item-text {
  --mat-menu-item-label-text-size: 14px;
}
#sie-boilerplate .mat-mdc-cell:not(.services) .mat-mdc-icon-button.mat-mdc-button-base,
.mat-mdc-dialog-container .mat-mdc-cell:not(.services) .mat-mdc-icon-button.mat-mdc-button-base,
.cdk-overlay-container .mat-mdc-cell:not(.services) .mat-mdc-icon-button.mat-mdc-button-base,
.cdk-drag-preview .mat-mdc-cell:not(.services) .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 24px;
}
#sie-boilerplate .mat-mdc-standard-chip .mdc-evolution-chip__text-label i,
.mat-mdc-dialog-container .mat-mdc-standard-chip .mdc-evolution-chip__text-label i,
.cdk-overlay-container .mat-mdc-standard-chip .mdc-evolution-chip__text-label i,
.cdk-drag-preview .mat-mdc-standard-chip .mdc-evolution-chip__text-label i {
  color: var(--ceres-primary-color-1);
}
#sie-boilerplate .mat-mdc-cell .mat-mdc-input-element,
.mat-mdc-dialog-container .mat-mdc-cell .mat-mdc-input-element,
.cdk-overlay-container .mat-mdc-cell .mat-mdc-input-element,
.cdk-drag-preview .mat-mdc-cell .mat-mdc-input-element {
  color: var(--ceres-black);
  background-color: transparent;
  border: none;
}
#sie-boilerplate .mat-mdc-form-field-icon-prefix,
.mat-mdc-dialog-container .mat-mdc-form-field-icon-prefix,
.cdk-overlay-container .mat-mdc-form-field-icon-prefix,
.cdk-drag-preview .mat-mdc-form-field-icon-prefix {
  color: var(--mdc-filled-text-field-input-text-color);
}
#sie-boilerplate mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base,
.mat-mdc-dialog-container mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base,
.cdk-overlay-container mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base,
.cdk-drag-preview mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 24px;
  transform: translate(-25%, 7.5%);
  padding: 0 !important;
}
#sie-boilerplate mat-datepicker-toggle .mat-datepicker-toggle-default-icon, #sie-boilerplate mat-datepicker-toggle .mat-mdc-button-touch-target,
.mat-mdc-dialog-container mat-datepicker-toggle .mat-datepicker-toggle-default-icon,
.mat-mdc-dialog-container mat-datepicker-toggle .mat-mdc-button-touch-target,
.cdk-overlay-container mat-datepicker-toggle .mat-datepicker-toggle-default-icon,
.cdk-overlay-container mat-datepicker-toggle .mat-mdc-button-touch-target,
.cdk-drag-preview mat-datepicker-toggle .mat-datepicker-toggle-default-icon,
.cdk-drag-preview mat-datepicker-toggle .mat-mdc-button-touch-target {
  width: 24px;
  height: 24px;
}
#sie-boilerplate mat-datepicker-toggle .mat-mdc-focus-indicator, #sie-boilerplate mat-datepicker-toggle .mat-mdc-focus-indicator,
.mat-mdc-dialog-container mat-datepicker-toggle .mat-mdc-focus-indicator,
.mat-mdc-dialog-container mat-datepicker-toggle .mat-mdc-focus-indicator,
.cdk-overlay-container mat-datepicker-toggle .mat-mdc-focus-indicator,
.cdk-overlay-container mat-datepicker-toggle .mat-mdc-focus-indicator,
.cdk-drag-preview mat-datepicker-toggle .mat-mdc-focus-indicator,
.cdk-drag-preview mat-datepicker-toggle .mat-mdc-focus-indicator {
  width: 24px;
  height: 24px;
}
#sie-boilerplate .mat-mdc-icon-button .mat-mdc-paginator-icon,
.mat-mdc-dialog-container .mat-mdc-icon-button .mat-mdc-paginator-icon,
.cdk-overlay-container .mat-mdc-icon-button .mat-mdc-paginator-icon,
.cdk-drag-preview .mat-mdc-icon-button .mat-mdc-paginator-icon {
  fill: var(--ceres-black);
}
#sie-boilerplate .month-selector,
.mat-mdc-dialog-container .month-selector,
.cdk-overlay-container .month-selector,
.cdk-drag-preview .month-selector {
  min-width: 200px;
}
#sie-boilerplate .mat-mdc-standard-chip .mdc-evolution-chip__text-label,
.mat-mdc-dialog-container .mat-mdc-standard-chip .mdc-evolution-chip__text-label,
.cdk-overlay-container .mat-mdc-standard-chip .mdc-evolution-chip__text-label,
.cdk-drag-preview .mat-mdc-standard-chip .mdc-evolution-chip__text-label {
  color: var(--ceres-primary-color-1);
  opacity: 1;
  border-radius: 0 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}
#sie-boilerplate .mat-mdc-chip-trailing-icon,
.mat-mdc-dialog-container .mat-mdc-chip-trailing-icon,
.cdk-overlay-container .mat-mdc-chip-trailing-icon,
.cdk-drag-preview .mat-mdc-chip-trailing-icon {
  padding: 0;
}
#sie-boilerplate .mdc-evolution-chip__text-label,
.mat-mdc-dialog-container .mdc-evolution-chip__text-label,
.cdk-overlay-container .mdc-evolution-chip__text-label,
.cdk-drag-preview .mdc-evolution-chip__text-label {
  white-space: pre-wrap;
  height: fit-content;
}
#sie-boilerplate .mdc-tooltip__surface,
.mat-mdc-dialog-container .mdc-tooltip__surface,
.cdk-overlay-container .mdc-tooltip__surface,
.cdk-drag-preview .mdc-tooltip__surface {
  background-color: var(--ceres-primary-color-1) !important;
  color: var(--ceres-white) !important;
}
#sie-boilerplate .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label, #sie-boilerplate .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label--float-above,
.mat-mdc-dialog-container .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label,
.mat-mdc-dialog-container .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label--float-above,
.cdk-overlay-container .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label,
.cdk-overlay-container .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label--float-above,
.cdk-drag-preview .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label,
.cdk-drag-preview .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
  color: var(--ceres-label-color);
}
#sie-boilerplate *,
#sie-boilerplate *:before,
#sie-boilerplate *:after,
.mat-mdc-dialog-container *,
.mat-mdc-dialog-container *:before,
.mat-mdc-dialog-container *:after,
.cdk-overlay-container *,
.cdk-overlay-container *:before,
.cdk-overlay-container *:after,
.cdk-drag-preview *,
.cdk-drag-preview *:before,
.cdk-drag-preview *:after {
  box-sizing: border-box;
}
#sie-boilerplate *:focus,
.mat-mdc-dialog-container *:focus,
.cdk-overlay-container *:focus,
.cdk-drag-preview *:focus {
  outline: none;
}
#sie-boilerplate h1, #sie-boilerplate .h1,
#sie-boilerplate h2,
#sie-boilerplate .h2,
#sie-boilerplate h3,
#sie-boilerplate .h3,
#sie-boilerplate h4,
#sie-boilerplate .h4,
#sie-boilerplate h5,
#sie-boilerplate .h5,
#sie-boilerplate h6,
#sie-boilerplate .h6,
#sie-boilerplate p,
#sie-boilerplate a,
#sie-boilerplate ul,
#sie-boilerplate li,
.mat-mdc-dialog-container h1,
.mat-mdc-dialog-container .h1,
.mat-mdc-dialog-container h2,
.mat-mdc-dialog-container .h2,
.mat-mdc-dialog-container h3,
.mat-mdc-dialog-container .h3,
.mat-mdc-dialog-container h4,
.mat-mdc-dialog-container .h4,
.mat-mdc-dialog-container h5,
.mat-mdc-dialog-container .h5,
.mat-mdc-dialog-container h6,
.mat-mdc-dialog-container .h6,
.mat-mdc-dialog-container p,
.mat-mdc-dialog-container a,
.mat-mdc-dialog-container ul,
.mat-mdc-dialog-container li,
.cdk-overlay-container h1,
.cdk-overlay-container .h1,
.cdk-overlay-container h2,
.cdk-overlay-container .h2,
.cdk-overlay-container h3,
.cdk-overlay-container .h3,
.cdk-overlay-container h4,
.cdk-overlay-container .h4,
.cdk-overlay-container h5,
.cdk-overlay-container .h5,
.cdk-overlay-container h6,
.cdk-overlay-container .h6,
.cdk-overlay-container p,
.cdk-overlay-container a,
.cdk-overlay-container ul,
.cdk-overlay-container li,
.cdk-drag-preview h1,
.cdk-drag-preview .h1,
.cdk-drag-preview h2,
.cdk-drag-preview .h2,
.cdk-drag-preview h3,
.cdk-drag-preview .h3,
.cdk-drag-preview h4,
.cdk-drag-preview .h4,
.cdk-drag-preview h5,
.cdk-drag-preview .h5,
.cdk-drag-preview h6,
.cdk-drag-preview .h6,
.cdk-drag-preview p,
.cdk-drag-preview a,
.cdk-drag-preview ul,
.cdk-drag-preview li {
  margin: 0;
  padding: 0;
}
#sie-boilerplate img,
.mat-mdc-dialog-container img,
.cdk-overlay-container img,
.cdk-drag-preview img {
  width: 100%;
}
#sie-boilerplate ol,
.mat-mdc-dialog-container ol,
.cdk-overlay-container ol,
.cdk-drag-preview ol {
  margin: 0;
  padding: 0;
}
#sie-boilerplate ol li,
.mat-mdc-dialog-container ol li,
.cdk-overlay-container ol li,
.cdk-drag-preview ol li {
  font-family: "Siemens Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-primary-color-3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate ol li,
  .mat-mdc-dialog-container ol li,
  .cdk-overlay-container ol li,
  .cdk-drag-preview ol li {
    font-family: "Siemens Sans", sans-serif;
    font-size: 17.99px;
    line-height: 25.7px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-primary-color-3);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate ul,
.mat-mdc-dialog-container ul,
.cdk-overlay-container ul,
.cdk-drag-preview ul {
  margin: 0;
  padding: 0;
}
#sie-boilerplate ul li,
.mat-mdc-dialog-container ul li,
.cdk-overlay-container ul li,
.cdk-drag-preview ul li {
  font-family: "Siemens Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-primary-color-3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate ul li,
  .mat-mdc-dialog-container ul li,
  .cdk-overlay-container ul li,
  .cdk-drag-preview ul li {
    font-family: "Siemens Sans", sans-serif;
    font-size: 17.99px;
    line-height: 25.7px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-primary-color-3);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate a,
.mat-mdc-dialog-container a,
.cdk-overlay-container a,
.cdk-drag-preview a {
  cursor: pointer;
  text-decoration: none;
}
#sie-boilerplate button,
.mat-mdc-dialog-container button,
.cdk-overlay-container button,
.cdk-drag-preview button {
  border: none;
  outline: none;
}
#sie-boilerplate ceres-project-relations-container.child-container:first-child:last-child.relation-lines, #sie-boilerplate ceres-project-relations-container.child-container:first-child:nth-last-child(1).relation-lines,
.mat-mdc-dialog-container ceres-project-relations-container.child-container:first-child:last-child.relation-lines,
.mat-mdc-dialog-container ceres-project-relations-container.child-container:first-child:nth-last-child(1).relation-lines,
.cdk-overlay-container ceres-project-relations-container.child-container:first-child:last-child.relation-lines,
.cdk-overlay-container ceres-project-relations-container.child-container:first-child:nth-last-child(1).relation-lines,
.cdk-drag-preview ceres-project-relations-container.child-container:first-child:last-child.relation-lines,
.cdk-drag-preview ceres-project-relations-container.child-container:first-child:nth-last-child(1).relation-lines {
  border-top: 0 !important;
}
#sie-boilerplate ceres-project-relations-container.child-container:first-child:last-child.relation-lines:after, #sie-boilerplate ceres-project-relations-container.child-container:first-child:nth-last-child(1).relation-lines:after,
.mat-mdc-dialog-container ceres-project-relations-container.child-container:first-child:last-child.relation-lines:after,
.mat-mdc-dialog-container ceres-project-relations-container.child-container:first-child:nth-last-child(1).relation-lines:after,
.cdk-overlay-container ceres-project-relations-container.child-container:first-child:last-child.relation-lines:after,
.cdk-overlay-container ceres-project-relations-container.child-container:first-child:nth-last-child(1).relation-lines:after,
.cdk-drag-preview ceres-project-relations-container.child-container:first-child:last-child.relation-lines:after,
.cdk-drag-preview ceres-project-relations-container.child-container:first-child:nth-last-child(1).relation-lines:after {
  height: 0 !important;
}
#sie-boilerplate ceres-project-relations-container.child-container:first-child:not(:nth-last-child(1)).relation-lines,
.mat-mdc-dialog-container ceres-project-relations-container.child-container:first-child:not(:nth-last-child(1)).relation-lines,
.cdk-overlay-container ceres-project-relations-container.child-container:first-child:not(:nth-last-child(1)).relation-lines,
.cdk-drag-preview ceres-project-relations-container.child-container:first-child:not(:nth-last-child(1)).relation-lines {
  border-top: 2px solid var(--ceres-primary-color-5);
  position: relative;
}
#sie-boilerplate ceres-project-relations-container.child-container:first-child:not(:nth-last-child(1)).relation-lines:after,
.mat-mdc-dialog-container ceres-project-relations-container.child-container:first-child:not(:nth-last-child(1)).relation-lines:after,
.cdk-overlay-container ceres-project-relations-container.child-container:first-child:not(:nth-last-child(1)).relation-lines:after,
.cdk-drag-preview ceres-project-relations-container.child-container:first-child:not(:nth-last-child(1)).relation-lines:after {
  padding: 0;
  margin: 0;
  display: block; /* probably not really needed? */
  content: "";
  width: 49.75%;
  height: 2px;
  background-color: var(--ceres-primary-color-7);
  position: absolute;
  left: 0;
  top: -2px;
}
#sie-boilerplate ceres-project-relations-container.child-container:not(:first-child):not(:last-child).relation-lines,
.mat-mdc-dialog-container ceres-project-relations-container.child-container:not(:first-child):not(:last-child).relation-lines,
.cdk-overlay-container ceres-project-relations-container.child-container:not(:first-child):not(:last-child).relation-lines,
.cdk-drag-preview ceres-project-relations-container.child-container:not(:first-child):not(:last-child).relation-lines {
  border-top: 2px solid var(--ceres-primary-color-5);
  position: relative;
}
#sie-boilerplate ceres-project-relations-container.child-container:not(:first-child):last-child.relation-lines,
.mat-mdc-dialog-container ceres-project-relations-container.child-container:not(:first-child):last-child.relation-lines,
.cdk-overlay-container ceres-project-relations-container.child-container:not(:first-child):last-child.relation-lines,
.cdk-drag-preview ceres-project-relations-container.child-container:not(:first-child):last-child.relation-lines {
  border-top: 2px solid var(--ceres-primary-color-5);
  position: relative;
}
#sie-boilerplate ceres-project-relations-container.child-container:not(:first-child):last-child.relation-lines:after,
.mat-mdc-dialog-container ceres-project-relations-container.child-container:not(:first-child):last-child.relation-lines:after,
.cdk-overlay-container ceres-project-relations-container.child-container:not(:first-child):last-child.relation-lines:after,
.cdk-drag-preview ceres-project-relations-container.child-container:not(:first-child):last-child.relation-lines:after {
  padding: 0;
  margin: 0;
  display: block; /* probably not really needed? */
  content: "";
  width: 49.75%;
  height: 2px;
  background-color: var(--ceres-primary-color-7);
  position: absolute;
  right: 0;
  top: -2px;
}
#sie-boilerplate .tile-container,
.mat-mdc-dialog-container .tile-container,
.cdk-overlay-container .tile-container,
.cdk-drag-preview .tile-container {
  cursor: pointer;
  width: 100%;
  height: 235px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#sie-boilerplate .tile-container .tile-content,
.mat-mdc-dialog-container .tile-container .tile-content,
.cdk-overlay-container .tile-container .tile-content,
.cdk-drag-preview .tile-container .tile-content {
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 3px 12px 19px -4px var(--ceres-black-opaque-35);
  -moz-box-shadow: 3px 12px 19px -4px var(--ceres-black-opaque-35);
  box-shadow: 3px 12px 19px -4px var(--ceres-black-opaque-35);
  background: var(--ceres-primary-color-3);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ceres-static-white);
  overflow: hidden;
}
#sie-boilerplate .tile-container .tile-content .tile-icon,
.mat-mdc-dialog-container .tile-container .tile-content .tile-icon,
.cdk-overlay-container .tile-container .tile-content .tile-icon,
.cdk-drag-preview .tile-container .tile-content .tile-icon {
  text-align: center;
  width: 100%;
  display: inline-block;
  padding: 0;
}
#sie-boilerplate .tile-container .tile-content .tile-icon .card-title,
.mat-mdc-dialog-container .tile-container .tile-content .tile-icon .card-title,
.cdk-overlay-container .tile-container .tile-content .tile-icon .card-title,
.cdk-drag-preview .tile-container .tile-content .tile-icon .card-title {
  color: var(--ceres-static-white);
}
#sie-boilerplate .tile-container .tile-content .tile-icon i,
.mat-mdc-dialog-container .tile-container .tile-content .tile-icon i,
.cdk-overlay-container .tile-container .tile-content .tile-icon i,
.cdk-drag-preview .tile-container .tile-content .tile-icon i {
  font-size: 48px;
}
#sie-boilerplate .tile-container .tile-content .tile-icon .caption,
.mat-mdc-dialog-container .tile-container .tile-content .tile-icon .caption,
.cdk-overlay-container .tile-container .tile-content .tile-icon .caption,
.cdk-drag-preview .tile-container .tile-content .tile-icon .caption {
  display: none;
  font-size: 14px !important;
  padding: 0 20px !important;
  color: var(--ceres-static-white);
}
#sie-boilerplate .tile-container .tile-content:hover .tile-icon,
.mat-mdc-dialog-container .tile-container .tile-content:hover .tile-icon,
.cdk-overlay-container .tile-container .tile-content:hover .tile-icon,
.cdk-drag-preview .tile-container .tile-content:hover .tile-icon {
  width: 100%;
}
#sie-boilerplate .tile-container .tile-content:hover .icon.a-icon,
.mat-mdc-dialog-container .tile-container .tile-content:hover .icon.a-icon,
.cdk-overlay-container .tile-container .tile-content:hover .icon.a-icon,
.cdk-drag-preview .tile-container .tile-content:hover .icon.a-icon {
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  animation-fill-mode: both;
}
#sie-boilerplate .tile-container .tile-content:hover .icon.a-icon::before,
.mat-mdc-dialog-container .tile-container .tile-content:hover .icon.a-icon::before,
.cdk-overlay-container .tile-container .tile-content:hover .icon.a-icon::before,
.cdk-drag-preview .tile-container .tile-content:hover .icon.a-icon::before {
  animation-name: resize;
  animation-duration: 0.6s;
  animation-fill-mode: both;
}
#sie-boilerplate .tile-container .tile-content:hover .icon.material-icons,
.mat-mdc-dialog-container .tile-container .tile-content:hover .icon.material-icons,
.cdk-overlay-container .tile-container .tile-content:hover .icon.material-icons,
.cdk-drag-preview .tile-container .tile-content:hover .icon.material-icons {
  animation-name: slideOutLeft, resize;
  animation-duration: 0.6s, 0.6s;
  animation-fill-mode: both, both;
}
@keyframes slideOutLeft {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
@keyframes resize {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.7);
  }
}
#sie-boilerplate .tile-container .tile-content:hover h4, #sie-boilerplate .tile-container .tile-content:hover .h4,
.mat-mdc-dialog-container .tile-container .tile-content:hover h4,
.mat-mdc-dialog-container .tile-container .tile-content:hover .h4,
.cdk-overlay-container .tile-container .tile-content:hover h4,
.cdk-overlay-container .tile-container .tile-content:hover .h4,
.cdk-drag-preview .tile-container .tile-content:hover h4,
.cdk-drag-preview .tile-container .tile-content:hover .h4 {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-30%);
    transform: translateY(-30%);
  }
}
@keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-30%);
    transform: translateY(-30%);
  }
}
#sie-boilerplate .tile-container .tile-content:hover .caption,
.mat-mdc-dialog-container .tile-container .tile-content:hover .caption,
.cdk-overlay-container .tile-container .tile-content:hover .caption,
.cdk-drag-preview .tile-container .tile-content:hover .caption {
  display: block !important;
  width: 100%;
  float: left;
  text-align: left;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes Wait-And-Start {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(200%);
  }
}
#sie-boilerplate .tile-container mat-card-actions,
.mat-mdc-dialog-container .tile-container mat-card-actions,
.cdk-overlay-container .tile-container mat-card-actions,
.cdk-drag-preview .tile-container mat-card-actions {
  padding-top: 0 !important;
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  font-size: small;
}
#sie-boilerplate .spacer-page,
.mat-mdc-dialog-container .spacer-page,
.cdk-overlay-container .spacer-page,
.cdk-drag-preview .spacer-page {
  padding-bottom: 92px;
}
#sie-boilerplate .lay-wrapper,
.mat-mdc-dialog-container .lay-wrapper,
.cdk-overlay-container .lay-wrapper,
.cdk-drag-preview .lay-wrapper {
  margin: 0 auto;
  width: 100%;
  position: relative;
  transition: transform 0.5s ease-in-out;
}
#sie-boilerplate .lay-wrapper:before, #sie-boilerplate .lay-wrapper:after,
.mat-mdc-dialog-container .lay-wrapper:before,
.mat-mdc-dialog-container .lay-wrapper:after,
.cdk-overlay-container .lay-wrapper:before,
.cdk-overlay-container .lay-wrapper:after,
.cdk-drag-preview .lay-wrapper:before,
.cdk-drag-preview .lay-wrapper:after {
  content: " ";
  display: table;
}
#sie-boilerplate .lay-wrapper:after,
.mat-mdc-dialog-container .lay-wrapper:after,
.cdk-overlay-container .lay-wrapper:after,
.cdk-drag-preview .lay-wrapper:after {
  clear: both;
}
#sie-boilerplate .lay-wrapper:last-of-type,
.mat-mdc-dialog-container .lay-wrapper:last-of-type,
.cdk-overlay-container .lay-wrapper:last-of-type,
.cdk-drag-preview .lay-wrapper:last-of-type {
  margin-bottom: 0;
}
#sie-boilerplate .lay-wrapper--header,
.mat-mdc-dialog-container .lay-wrapper--header,
.cdk-overlay-container .lay-wrapper--header,
.cdk-drag-preview .lay-wrapper--header {
  top: 0;
  height: 70px;
  background: var(--ceres-white);
  border-bottom: 2px solid var(--ceres-primary-color-8);
  position: fixed;
  z-index: 12 !important;
}
#sie-boilerplate .lay-wrapper--footer,
.mat-mdc-dialog-container .lay-wrapper--footer,
.cdk-overlay-container .lay-wrapper--footer,
.cdk-drag-preview .lay-wrapper--footer {
  left: 0;
  right: 0;
  z-index: 60;
  bottom: 0;
  position: fixed;
  height: 60px;
  background-color: var(--ceres-footer-color);
  width: 100%;
}
@media (max-width: 575.98px) {
  #sie-boilerplate .lay-wrapper--footer .nav,
  .mat-mdc-dialog-container .lay-wrapper--footer .nav,
  .cdk-overlay-container .lay-wrapper--footer .nav,
  .cdk-drag-preview .lay-wrapper--footer .nav {
    padding: 0 !important;
  }
}
#sie-boilerplate .lay-wrapper--footer .nav,
.mat-mdc-dialog-container .lay-wrapper--footer .nav,
.cdk-overlay-container .lay-wrapper--footer .nav,
.cdk-drag-preview .lay-wrapper--footer .nav {
  padding: 20px;
}
#sie-boilerplate .lay-wrapper--footer .gms-badge,
.mat-mdc-dialog-container .lay-wrapper--footer .gms-badge,
.cdk-overlay-container .lay-wrapper--footer .gms-badge,
.cdk-drag-preview .lay-wrapper--footer .gms-badge {
  color: #000028;
  margin-left: 48px;
  min-width: 150px;
  padding: 0 84px 0 96px;
  display: flex;
  height: 100%;
  align-items: center;
  align-content: center;
  background: linear-gradient(15deg, #00ffb9 0%, #00e6dc 100%);
  clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
}
#sie-boilerplate .lay-wrapper--footer .gms-badge strong,
.mat-mdc-dialog-container .lay-wrapper--footer .gms-badge strong,
.cdk-overlay-container .lay-wrapper--footer .gms-badge strong,
.cdk-drag-preview .lay-wrapper--footer .gms-badge strong {
  font: caption;
  font-weight: 600;
}
#sie-boilerplate .lay-wrapper--footer .gms-badge:hover,
.mat-mdc-dialog-container .lay-wrapper--footer .gms-badge:hover,
.cdk-overlay-container .lay-wrapper--footer .gms-badge:hover,
.cdk-drag-preview .lay-wrapper--footer .gms-badge:hover {
  text-decoration-line: underline;
  text-underline-offset: 5px;
}
#sie-boilerplate .lay-wrapper--default,
.mat-mdc-dialog-container .lay-wrapper--default,
.cdk-overlay-container .lay-wrapper--default,
.cdk-drag-preview .lay-wrapper--default {
  width: 92.5%;
  margin: 0 auto;
  position: relative;
  z-index: 0;
  flex: 1 0 auto;
  padding: 0px;
  min-height: calc(100vh - 160px);
}
#sie-boilerplate .lay-wrapper--full-width,
.mat-mdc-dialog-container .lay-wrapper--full-width,
.cdk-overlay-container .lay-wrapper--full-width,
.cdk-drag-preview .lay-wrapper--full-width {
  width: 100%;
  position: relative;
}
#sie-boilerplate .lay-wrapper--full-width:before, #sie-boilerplate .lay-wrapper--full-width:after,
.mat-mdc-dialog-container .lay-wrapper--full-width:before,
.mat-mdc-dialog-container .lay-wrapper--full-width:after,
.cdk-overlay-container .lay-wrapper--full-width:before,
.cdk-overlay-container .lay-wrapper--full-width:after,
.cdk-drag-preview .lay-wrapper--full-width:before,
.cdk-drag-preview .lay-wrapper--full-width:after {
  content: " ";
  display: table;
}
#sie-boilerplate .lay-wrapper--full-width:after,
.mat-mdc-dialog-container .lay-wrapper--full-width:after,
.cdk-overlay-container .lay-wrapper--full-width:after,
.cdk-drag-preview .lay-wrapper--full-width:after {
  clear: both;
}
#sie-boilerplate .lay-wrapper--top-distance,
.mat-mdc-dialog-container .lay-wrapper--top-distance,
.cdk-overlay-container .lay-wrapper--top-distance,
.cdk-drag-preview .lay-wrapper--top-distance {
  padding-top: 125px;
}
#sie-boilerplate .lay-wrapper--top-distance-small,
.mat-mdc-dialog-container .lay-wrapper--top-distance-small,
.cdk-overlay-container .lay-wrapper--top-distance-small,
.cdk-drag-preview .lay-wrapper--top-distance-small {
  top: 90px;
  bottom: 90px;
}
#sie-boilerplate .lay-wrapper--bottom-distance,
.mat-mdc-dialog-container .lay-wrapper--bottom-distance,
.cdk-overlay-container .lay-wrapper--bottom-distance,
.cdk-drag-preview .lay-wrapper--bottom-distance {
  padding-bottom: 100px;
}
#sie-boilerplate .lay-wrapper--bottom-distance-small,
.mat-mdc-dialog-container .lay-wrapper--bottom-distance-small,
.cdk-overlay-container .lay-wrapper--bottom-distance-small,
.cdk-drag-preview .lay-wrapper--bottom-distance-small {
  padding-bottom: 65px;
}
#sie-boilerplate footer .nav ul,
.mat-mdc-dialog-container footer .nav ul,
.cdk-overlay-container footer .nav ul,
.cdk-drag-preview footer .nav ul {
  list-style: none;
}
#sie-boilerplate footer .nav ul li,
.mat-mdc-dialog-container footer .nav ul li,
.cdk-overlay-container footer .nav ul li,
.cdk-drag-preview footer .nav ul li {
  float: left;
}
#sie-boilerplate .footer-text,
.mat-mdc-dialog-container .footer-text,
.cdk-overlay-container .footer-text,
.cdk-drag-preview .footer-text {
  font-family: "Siemens Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-primary-color-5);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: default;
  margin: 0 9px;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .footer-text,
  .mat-mdc-dialog-container .footer-text,
  .cdk-overlay-container .footer-text,
  .cdk-drag-preview .footer-text {
    font-family: "Siemens Sans", sans-serif;
    font-size: 17.99px;
    line-height: 25.7px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-primary-color-5);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
@media only screen and (max-width: 768px) {
  #sie-boilerplate .nav ul li,
  #sie-boilerplate info,
  .mat-mdc-dialog-container .nav ul li,
  .mat-mdc-dialog-container info,
  .cdk-overlay-container .nav ul li,
  .cdk-overlay-container info,
  .cdk-drag-preview .nav ul li,
  .cdk-drag-preview info {
    margin: 0 4px !important;
  }
  #sie-boilerplate .footer-text,
  .mat-mdc-dialog-container .footer-text,
  .cdk-overlay-container .footer-text,
  .cdk-drag-preview .footer-text {
    font-size: 10px !important;
  }
}
@media only screen and (max-width: 576px) {
  #sie-boilerplate footer,
  .mat-mdc-dialog-container footer,
  .cdk-overlay-container footer,
  .cdk-drag-preview footer {
    padding: 8px !important;
  }
  #sie-boilerplate .nav ul li,
  #sie-boilerplate info,
  .mat-mdc-dialog-container .nav ul li,
  .mat-mdc-dialog-container info,
  .cdk-overlay-container .nav ul li,
  .cdk-overlay-container info,
  .cdk-drag-preview .nav ul li,
  .cdk-drag-preview info {
    margin: 0 2px !important;
  }
  #sie-boilerplate .footer-text,
  .mat-mdc-dialog-container .footer-text,
  .cdk-overlay-container .footer-text,
  .cdk-drag-preview .footer-text {
    font-size: 8px !important;
  }
}
#sie-boilerplate .mod-navigation,
.mat-mdc-dialog-container .mod-navigation,
.cdk-overlay-container .mod-navigation,
.cdk-drag-preview .mod-navigation {
  position: relative;
  /* ICONS */
}
#sie-boilerplate .mod-navigation--icons,
.mat-mdc-dialog-container .mod-navigation--icons,
.cdk-overlay-container .mod-navigation--icons,
.cdk-drag-preview .mod-navigation--icons {
  width: auto;
  height: 60px;
  right: -11px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
}
#sie-boilerplate .mod-navigation--main-desktop,
.mat-mdc-dialog-container .mod-navigation--main-desktop,
.cdk-overlay-container .mod-navigation--main-desktop,
.cdk-drag-preview .mod-navigation--main-desktop {
  margin-left: 15%;
  width: 70%;
  display: none;
}
@media print, only screen and (min-width: 1024px) {
  #sie-boilerplate .mod-navigation--main-desktop,
  .mat-mdc-dialog-container .mod-navigation--main-desktop,
  .cdk-overlay-container .mod-navigation--main-desktop,
  .cdk-drag-preview .mod-navigation--main-desktop {
    display: block;
  }
}
#sie-boilerplate .mod-navigation--main-desktop > ul,
.mat-mdc-dialog-container .mod-navigation--main-desktop > ul,
.cdk-overlay-container .mod-navigation--main-desktop > ul,
.cdk-drag-preview .mod-navigation--main-desktop > ul {
  list-style: none;
  position: relative;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
#sie-boilerplate .mod-navigation--main-desktop > ul > li,
.mat-mdc-dialog-container .mod-navigation--main-desktop > ul > li,
.cdk-overlay-container .mod-navigation--main-desktop > ul > li,
.cdk-drag-preview .mod-navigation--main-desktop > ul > li {
  position: relative;
  margin: 0 15px;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--main-desktop > ul > li,
  .mat-mdc-dialog-container .mod-navigation--main-desktop > ul > li,
  .cdk-overlay-container .mod-navigation--main-desktop > ul > li,
  .cdk-drag-preview .mod-navigation--main-desktop > ul > li {
    transform: translateY(-4px);
  }
}
#sie-boilerplate .mod-navigation--main-desktop > ul > li:hover > a,
.mat-mdc-dialog-container .mod-navigation--main-desktop > ul > li:hover > a,
.cdk-overlay-container .mod-navigation--main-desktop > ul > li:hover > a,
.cdk-drag-preview .mod-navigation--main-desktop > ul > li:hover > a {
  color: var(--ceres-primary-color-3);
}
#sie-boilerplate .mod-navigation--main-desktop > ul > .m-active-line,
.mat-mdc-dialog-container .mod-navigation--main-desktop > ul > .m-active-line,
.cdk-overlay-container .mod-navigation--main-desktop > ul > .m-active-line,
.cdk-drag-preview .mod-navigation--main-desktop > ul > .m-active-line {
  bottom: 2px;
  height: 4px;
  background: var(--ceres-secondary-color-6);
  position: absolute;
  transition: left 0.4s ease-in-out, width 0.4s ease-in-out;
  display: none;
}
@media print, only screen and (min-width: 1024px) {
  #sie-boilerplate .mod-navigation--main-desktop > ul > .m-active-line,
  .mat-mdc-dialog-container .mod-navigation--main-desktop > ul > .m-active-line,
  .cdk-overlay-container .mod-navigation--main-desktop > ul > .m-active-line,
  .cdk-drag-preview .mod-navigation--main-desktop > ul > .m-active-line {
    display: block;
  }
}
#sie-boilerplate h1, #sie-boilerplate .h1,
#sie-boilerplate .a-text-headline-page,
.mat-mdc-dialog-container h1,
.mat-mdc-dialog-container .h1,
.mat-mdc-dialog-container .a-text-headline-page,
.cdk-overlay-container h1,
.cdk-overlay-container .h1,
.cdk-overlay-container .a-text-headline-page,
.cdk-drag-preview h1,
.cdk-drag-preview .h1,
.cdk-drag-preview .a-text-headline-page {
  font-family: "Siemens Sans", sans-serif;
  font-size: 46px;
  line-height: 57px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-primary-color-3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate h1, #sie-boilerplate .h1,
  #sie-boilerplate .a-text-headline-page,
  .mat-mdc-dialog-container h1,
  .mat-mdc-dialog-container .h1,
  .mat-mdc-dialog-container .a-text-headline-page,
  .cdk-overlay-container h1,
  .cdk-overlay-container .h1,
  .cdk-overlay-container .a-text-headline-page,
  .cdk-drag-preview h1,
  .cdk-drag-preview .h1,
  .cdk-drag-preview .a-text-headline-page {
    font-family: "Siemens Sans", sans-serif;
    font-size: 59.11px;
    line-height: 73.245px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-primary-color-3);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate h2, #sie-boilerplate .h2,
#sie-boilerplate .a-text-headline-chapter,
.mat-mdc-dialog-container h2,
.mat-mdc-dialog-container .h2,
.mat-mdc-dialog-container .a-text-headline-chapter,
.cdk-overlay-container h2,
.cdk-overlay-container .h2,
.cdk-overlay-container .a-text-headline-chapter,
.cdk-drag-preview h2,
.cdk-drag-preview .h2,
.cdk-drag-preview .a-text-headline-chapter {
  font-family: "Siemens Sans Bold", sans-serif;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-primary-color-3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate h2, #sie-boilerplate .h2,
  #sie-boilerplate .a-text-headline-chapter,
  .mat-mdc-dialog-container h2,
  .mat-mdc-dialog-container .h2,
  .mat-mdc-dialog-container .a-text-headline-chapter,
  .cdk-overlay-container h2,
  .cdk-overlay-container .h2,
  .cdk-overlay-container .a-text-headline-chapter,
  .cdk-drag-preview h2,
  .cdk-drag-preview .h2,
  .cdk-drag-preview .a-text-headline-chapter {
    font-family: "Siemens Sans Bold", sans-serif;
    font-size: 38.55px;
    line-height: 46.26px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-primary-color-3);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate h3, #sie-boilerplate .h3,
#sie-boilerplate .a-text-headline-component,
.mat-mdc-dialog-container h3,
.mat-mdc-dialog-container .h3,
.mat-mdc-dialog-container .a-text-headline-component,
.cdk-overlay-container h3,
.cdk-overlay-container .h3,
.cdk-overlay-container .a-text-headline-component,
.cdk-drag-preview h3,
.cdk-drag-preview .h3,
.cdk-drag-preview .a-text-headline-component {
  font-family: "Siemens Sans", sans-serif;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-primary-color-3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate h3, #sie-boilerplate .h3,
  #sie-boilerplate .a-text-headline-component,
  .mat-mdc-dialog-container h3,
  .mat-mdc-dialog-container .h3,
  .mat-mdc-dialog-container .a-text-headline-component,
  .cdk-overlay-container h3,
  .cdk-overlay-container .h3,
  .cdk-overlay-container .a-text-headline-component,
  .cdk-drag-preview h3,
  .cdk-drag-preview .h3,
  .cdk-drag-preview .a-text-headline-component {
    font-family: "Siemens Sans", sans-serif;
    font-size: 30.84px;
    line-height: 38.55px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-primary-color-3);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate h4, #sie-boilerplate .h4,
#sie-boilerplate .a-text-headline-txt,
.mat-mdc-dialog-container h4,
.mat-mdc-dialog-container .h4,
.mat-mdc-dialog-container .a-text-headline-txt,
.cdk-overlay-container h4,
.cdk-overlay-container .h4,
.cdk-overlay-container .a-text-headline-txt,
.cdk-drag-preview h4,
.cdk-drag-preview .h4,
.cdk-drag-preview .a-text-headline-txt {
  font-family: "Siemens Sans Bold", sans-serif;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-primary-color-3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate h4, #sie-boilerplate .h4,
  #sie-boilerplate .a-text-headline-txt,
  .mat-mdc-dialog-container h4,
  .mat-mdc-dialog-container .h4,
  .mat-mdc-dialog-container .a-text-headline-txt,
  .cdk-overlay-container h4,
  .cdk-overlay-container .h4,
  .cdk-overlay-container .a-text-headline-txt,
  .cdk-drag-preview h4,
  .cdk-drag-preview .h4,
  .cdk-drag-preview .a-text-headline-txt {
    font-family: "Siemens Sans Bold", sans-serif;
    font-size: 23.13px;
    line-height: 30.84px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-primary-color-3);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate p,
#sie-boilerplate .a-text-copy-txt,
.mat-mdc-dialog-container p,
.mat-mdc-dialog-container .a-text-copy-txt,
.cdk-overlay-container p,
.cdk-overlay-container .a-text-copy-txt,
.cdk-drag-preview p,
.cdk-drag-preview .a-text-copy-txt {
  font-family: "Siemens Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-primary-color-3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate p,
  #sie-boilerplate .a-text-copy-txt,
  .mat-mdc-dialog-container p,
  .mat-mdc-dialog-container .a-text-copy-txt,
  .cdk-overlay-container p,
  .cdk-overlay-container .a-text-copy-txt,
  .cdk-drag-preview p,
  .cdk-drag-preview .a-text-copy-txt {
    font-family: "Siemens Sans", sans-serif;
    font-size: 17.99px;
    line-height: 25.7px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-primary-color-3);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .a-text-copy-txt-bold,
.mat-mdc-dialog-container .a-text-copy-txt-bold,
.cdk-overlay-container .a-text-copy-txt-bold,
.cdk-drag-preview .a-text-copy-txt-bold {
  font-family: "Siemens Sans Bold", sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-primary-color-3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .a-text-copy-txt-bold,
  .mat-mdc-dialog-container .a-text-copy-txt-bold,
  .cdk-overlay-container .a-text-copy-txt-bold,
  .cdk-drag-preview .a-text-copy-txt-bold {
    font-family: "Siemens Sans Bold", sans-serif;
    font-size: 17.99px;
    line-height: 25.7px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-primary-color-3);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .a-text-copy-txt-chapter-title,
.mat-mdc-dialog-container .a-text-copy-txt-chapter-title,
.cdk-overlay-container .a-text-copy-txt-chapter-title,
.cdk-drag-preview .a-text-copy-txt-chapter-title {
  font-family: "Siemens Sans", sans-serif;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-secondary-color-16);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .a-text-copy-txt-chapter-title,
  .mat-mdc-dialog-container .a-text-copy-txt-chapter-title,
  .cdk-overlay-container .a-text-copy-txt-chapter-title,
  .cdk-drag-preview .a-text-copy-txt-chapter-title {
    font-family: "Siemens Sans", sans-serif;
    font-size: 23.13px;
    line-height: 30.84px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-secondary-color-16);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .a-text-copy-txt-subtitle,
.mat-mdc-dialog-container .a-text-copy-txt-subtitle,
.cdk-overlay-container .a-text-copy-txt-subtitle,
.cdk-drag-preview .a-text-copy-txt-subtitle {
  font-family: "Siemens Sans Bold", sans-serif;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-primary-color-3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .a-text-copy-txt-subtitle,
  .mat-mdc-dialog-container .a-text-copy-txt-subtitle,
  .cdk-overlay-container .a-text-copy-txt-subtitle,
  .cdk-drag-preview .a-text-copy-txt-subtitle {
    font-family: "Siemens Sans Bold", sans-serif;
    font-size: 23.13px;
    line-height: 30.84px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-primary-color-3);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .a-text-copy-txt-info,
.mat-mdc-dialog-container .a-text-copy-txt-info,
.cdk-overlay-container .a-text-copy-txt-info,
.cdk-drag-preview .a-text-copy-txt-info {
  font-family: "Siemens Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-secondary-color-16);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .a-text-copy-txt-info,
  .mat-mdc-dialog-container .a-text-copy-txt-info,
  .cdk-overlay-container .a-text-copy-txt-info,
  .cdk-drag-preview .a-text-copy-txt-info {
    font-family: "Siemens Sans", sans-serif;
    font-size: 17.99px;
    line-height: 25.7px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-secondary-color-16);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .a-text-link-content,
.mat-mdc-dialog-container .a-text-link-content,
.cdk-overlay-container .a-text-link-content,
.cdk-drag-preview .a-text-link-content {
  font-family: "Siemens Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-secondary-color-6);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  position: relative;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .a-text-link-content,
  .mat-mdc-dialog-container .a-text-link-content,
  .cdk-overlay-container .a-text-link-content,
  .cdk-drag-preview .a-text-link-content {
    font-family: "Siemens Sans", sans-serif;
    font-size: 17.99px;
    line-height: 25.7px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-secondary-color-6);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .a-text-link-content:hover,
#sie-boilerplate .a-text-link-content .active,
.mat-mdc-dialog-container .a-text-link-content:hover,
.mat-mdc-dialog-container .a-text-link-content .active,
.cdk-overlay-container .a-text-link-content:hover,
.cdk-overlay-container .a-text-link-content .active,
.cdk-drag-preview .a-text-link-content:hover,
.cdk-drag-preview .a-text-link-content .active {
  color: var(--ceres-secondary-color-5);
  border-bottom: 1px solid var(--ceres-secondary-color-5);
}
#sie-boilerplate .a-text-link-copy-txt,
.mat-mdc-dialog-container .a-text-link-copy-txt,
.cdk-overlay-container .a-text-link-copy-txt,
.cdk-drag-preview .a-text-link-copy-txt {
  font-family: "Siemens Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-secondary-color-6);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  position: relative;
  border-bottom: 1px solid var(--ceres-secondary-color-6);
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .a-text-link-copy-txt,
  .mat-mdc-dialog-container .a-text-link-copy-txt,
  .cdk-overlay-container .a-text-link-copy-txt,
  .cdk-drag-preview .a-text-link-copy-txt {
    font-family: "Siemens Sans", sans-serif;
    font-size: 17.99px;
    line-height: 25.7px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-secondary-color-6);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .a-text-link-copy-txt:hover,
#sie-boilerplate .a-text-link-copy-txt .active,
.mat-mdc-dialog-container .a-text-link-copy-txt:hover,
.mat-mdc-dialog-container .a-text-link-copy-txt .active,
.cdk-overlay-container .a-text-link-copy-txt:hover,
.cdk-overlay-container .a-text-link-copy-txt .active,
.cdk-drag-preview .a-text-link-copy-txt:hover,
.cdk-drag-preview .a-text-link-copy-txt .active {
  color: var(--ceres-secondary-color-5);
  border-bottom: 1px solid var(--ceres-secondary-color-5);
}
#sie-boilerplate .a-text-link-nav-generic,
.mat-mdc-dialog-container .a-text-link-nav-generic,
.cdk-overlay-container .a-text-link-nav-generic,
.cdk-drag-preview .a-text-link-nav-generic {
  font-family: "Siemens Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-secondary-color-16);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  position: relative;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .a-text-link-nav-generic,
  .mat-mdc-dialog-container .a-text-link-nav-generic,
  .cdk-overlay-container .a-text-link-nav-generic,
  .cdk-drag-preview .a-text-link-nav-generic {
    font-family: "Siemens Sans", sans-serif;
    font-size: 17.99px;
    line-height: 25.7px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-secondary-color-16);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .a-text-link-nav-generic:hover,
#sie-boilerplate .a-text-link-nav-generic .active,
.mat-mdc-dialog-container .a-text-link-nav-generic:hover,
.mat-mdc-dialog-container .a-text-link-nav-generic .active,
.cdk-overlay-container .a-text-link-nav-generic:hover,
.cdk-overlay-container .a-text-link-nav-generic .active,
.cdk-drag-preview .a-text-link-nav-generic:hover,
.cdk-drag-preview .a-text-link-nav-generic .active {
  color: var(--ceres-secondary-color-5);
  border-bottom: 1px solid var(--ceres-secondary-color-5);
}
#sie-boilerplate .a-text-link-nav-primary,
.mat-mdc-dialog-container .a-text-link-nav-primary,
.cdk-overlay-container .a-text-link-nav-primary,
.cdk-drag-preview .a-text-link-nav-primary {
  font-family: "Siemens Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-secondary-color-16);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  position: relative;
}
@media print, only screen and (min-width: 1024px) {
  #sie-boilerplate .a-text-link-nav-primary,
  .mat-mdc-dialog-container .a-text-link-nav-primary,
  .cdk-overlay-container .a-text-link-nav-primary,
  .cdk-drag-preview .a-text-link-nav-primary {
    font-size: 18px;
    line-height: 24px;
  }
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .a-text-link-nav-primary,
  .mat-mdc-dialog-container .a-text-link-nav-primary,
  .cdk-overlay-container .a-text-link-nav-primary,
  .cdk-drag-preview .a-text-link-nav-primary {
    font-family: "Siemens Sans", sans-serif;
    font-size: 17.99px;
    line-height: 25.7px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-secondary-color-16);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .a-text-link-arrow,
.mat-mdc-dialog-container .a-text-link-arrow,
.cdk-overlay-container .a-text-link-arrow,
.cdk-drag-preview .a-text-link-arrow {
  font-family: "Siemens Sans", sans-serif;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-secondary-color-6);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  position: relative;
  display: block;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .a-text-link-arrow,
  .mat-mdc-dialog-container .a-text-link-arrow,
  .cdk-overlay-container .a-text-link-arrow,
  .cdk-drag-preview .a-text-link-arrow {
    font-size: 18px;
    line-height: 21px;
  }
}
#sie-boilerplate .a-text-link-arrow.a-icon:before,
.mat-mdc-dialog-container .a-text-link-arrow.a-icon:before,
.cdk-overlay-container .a-text-link-arrow.a-icon:before,
.cdk-drag-preview .a-text-link-arrow.a-icon:before {
  font-size: 18px;
  color: var(--ceres-secondary-color-6);
  transform: translate(-6px, 3px);
  display: inline-block;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .a-text-link-arrow.a-icon:before,
  .mat-mdc-dialog-container .a-text-link-arrow.a-icon:before,
  .cdk-overlay-container .a-text-link-arrow.a-icon:before,
  .cdk-drag-preview .a-text-link-arrow.a-icon:before {
    transform: translate(-6px, 2px);
  }
}
#sie-boilerplate .a-text-link-arrow:hover,
#sie-boilerplate .a-text-link-arrow .active,
.mat-mdc-dialog-container .a-text-link-arrow:hover,
.mat-mdc-dialog-container .a-text-link-arrow .active,
.cdk-overlay-container .a-text-link-arrow:hover,
.cdk-overlay-container .a-text-link-arrow .active,
.cdk-drag-preview .a-text-link-arrow:hover,
.cdk-drag-preview .a-text-link-arrow .active {
  color: var(--ceres-secondary-color-5);
}
#sie-boilerplate .a-text-link-arrow:hover.a-icon:before,
#sie-boilerplate .a-text-link-arrow .active.a-icon:before,
.mat-mdc-dialog-container .a-text-link-arrow:hover.a-icon:before,
.mat-mdc-dialog-container .a-text-link-arrow .active.a-icon:before,
.cdk-overlay-container .a-text-link-arrow:hover.a-icon:before,
.cdk-overlay-container .a-text-link-arrow .active.a-icon:before,
.cdk-drag-preview .a-text-link-arrow:hover.a-icon:before,
.cdk-drag-preview .a-text-link-arrow .active.a-icon:before {
  color: var(--ceres-secondary-color-5);
}
#sie-boilerplate .a-icon--apps:before,
.mat-mdc-dialog-container .a-icon--apps:before,
.cdk-overlay-container .a-icon--apps:before,
.cdk-drag-preview .a-icon--apps:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e600";
}
#sie-boilerplate .a-icon--arrow-down-extrasmall:before,
.mat-mdc-dialog-container .a-icon--arrow-down-extrasmall:before,
.cdk-overlay-container .a-icon--arrow-down-extrasmall:before,
.cdk-drag-preview .a-icon--arrow-down-extrasmall:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e601";
}
#sie-boilerplate .a-icon--arrow-left-extrasmall:before,
.mat-mdc-dialog-container .a-icon--arrow-left-extrasmall:before,
.cdk-overlay-container .a-icon--arrow-left-extrasmall:before,
.cdk-drag-preview .a-icon--arrow-left-extrasmall:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e602";
}
#sie-boilerplate .a-icon--arrow-right-extrasmall:before,
.mat-mdc-dialog-container .a-icon--arrow-right-extrasmall:before,
.cdk-overlay-container .a-icon--arrow-right-extrasmall:before,
.cdk-drag-preview .a-icon--arrow-right-extrasmall:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e603";
}
#sie-boilerplate .a-icon--arrow-up-extrasmall:before,
.mat-mdc-dialog-container .a-icon--arrow-up-extrasmall:before,
.cdk-overlay-container .a-icon--arrow-up-extrasmall:before,
.cdk-drag-preview .a-icon--arrow-up-extrasmall:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e604";
}
#sie-boilerplate .a-icon--arrow-down-large:before,
.mat-mdc-dialog-container .a-icon--arrow-down-large:before,
.cdk-overlay-container .a-icon--arrow-down-large:before,
.cdk-drag-preview .a-icon--arrow-down-large:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e605";
}
#sie-boilerplate .a-icon--arrow-left-large:before,
.mat-mdc-dialog-container .a-icon--arrow-left-large:before,
.cdk-overlay-container .a-icon--arrow-left-large:before,
.cdk-drag-preview .a-icon--arrow-left-large:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e606";
}
#sie-boilerplate .a-icon--arrow-right-large:before,
.mat-mdc-dialog-container .a-icon--arrow-right-large:before,
.cdk-overlay-container .a-icon--arrow-right-large:before,
.cdk-drag-preview .a-icon--arrow-right-large:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e607";
}
#sie-boilerplate .a-icon--arrow-up-large:before,
.mat-mdc-dialog-container .a-icon--arrow-up-large:before,
.cdk-overlay-container .a-icon--arrow-up-large:before,
.cdk-drag-preview .a-icon--arrow-up-large:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e608";
}
#sie-boilerplate .a-icon--arrow-down-small:before,
.mat-mdc-dialog-container .a-icon--arrow-down-small:before,
.cdk-overlay-container .a-icon--arrow-down-small:before,
.cdk-drag-preview .a-icon--arrow-down-small:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e609";
}
#sie-boilerplate .a-icon--arrow-left-small:before,
.mat-mdc-dialog-container .a-icon--arrow-left-small:before,
.cdk-overlay-container .a-icon--arrow-left-small:before,
.cdk-drag-preview .a-icon--arrow-left-small:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e60a";
}
#sie-boilerplate .a-icon--arrow-right-small:before,
.mat-mdc-dialog-container .a-icon--arrow-right-small:before,
.cdk-overlay-container .a-icon--arrow-right-small:before,
.cdk-drag-preview .a-icon--arrow-right-small:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e60b";
}
#sie-boilerplate .a-icon--arrow-up-small:before,
.mat-mdc-dialog-container .a-icon--arrow-up-small:before,
.cdk-overlay-container .a-icon--arrow-up-small:before,
.cdk-drag-preview .a-icon--arrow-up-small:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e60c";
}
#sie-boilerplate .a-icon--arrow-to-top:before,
.mat-mdc-dialog-container .a-icon--arrow-to-top:before,
.cdk-overlay-container .a-icon--arrow-to-top:before,
.cdk-drag-preview .a-icon--arrow-to-top:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e60d";
}
#sie-boilerplate .a-icon--at:before,
.mat-mdc-dialog-container .a-icon--at:before,
.cdk-overlay-container .a-icon--at:before,
.cdk-drag-preview .a-icon--at:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e60e";
}
#sie-boilerplate .a-icon--bookmark:before,
.mat-mdc-dialog-container .a-icon--bookmark:before,
.cdk-overlay-container .a-icon--bookmark:before,
.cdk-drag-preview .a-icon--bookmark:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e60f";
}
#sie-boilerplate .a-icon--calculate:before,
.mat-mdc-dialog-container .a-icon--calculate:before,
.cdk-overlay-container .a-icon--calculate:before,
.cdk-drag-preview .a-icon--calculate:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e610";
}
#sie-boilerplate .a-icon--calendar:before,
.mat-mdc-dialog-container .a-icon--calendar:before,
.cdk-overlay-container .a-icon--calendar:before,
.cdk-drag-preview .a-icon--calendar:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e611";
}
#sie-boilerplate .a-icon--chat:before,
.mat-mdc-dialog-container .a-icon--chat:before,
.cdk-overlay-container .a-icon--chat:before,
.cdk-drag-preview .a-icon--chat:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e612";
}
#sie-boilerplate .a-icon--check-mark:before,
.mat-mdc-dialog-container .a-icon--check-mark:before,
.cdk-overlay-container .a-icon--check-mark:before,
.cdk-drag-preview .a-icon--check-mark:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e613";
}
#sie-boilerplate .a-icon--circle:before,
.mat-mdc-dialog-container .a-icon--circle:before,
.cdk-overlay-container .a-icon--circle:before,
.cdk-drag-preview .a-icon--circle:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e614";
}
#sie-boilerplate .a-icon--clock:before,
.mat-mdc-dialog-container .a-icon--clock:before,
.cdk-overlay-container .a-icon--clock:before,
.cdk-drag-preview .a-icon--clock:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e907";
}
#sie-boilerplate .a-icon--close:before,
.mat-mdc-dialog-container .a-icon--close:before,
.cdk-overlay-container .a-icon--close:before,
.cdk-drag-preview .a-icon--close:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e615";
}
#sie-boilerplate .a-icon--control:before,
.mat-mdc-dialog-container .a-icon--control:before,
.cdk-overlay-container .a-icon--control:before,
.cdk-drag-preview .a-icon--control:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e616";
}
#sie-boilerplate .a-icon--delete:before,
.mat-mdc-dialog-container .a-icon--delete:before,
.cdk-overlay-container .a-icon--delete:before,
.cdk-drag-preview .a-icon--delete:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e617";
}
#sie-boilerplate .a-icon--double-arrow-backward:before,
.mat-mdc-dialog-container .a-icon--double-arrow-backward:before,
.cdk-overlay-container .a-icon--double-arrow-backward:before,
.cdk-drag-preview .a-icon--double-arrow-backward:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e618";
}
#sie-boilerplate .a-icon--double-arrow-down:before,
.mat-mdc-dialog-container .a-icon--double-arrow-down:before,
.cdk-overlay-container .a-icon--double-arrow-down:before,
.cdk-drag-preview .a-icon--double-arrow-down:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e619";
}
#sie-boilerplate .a-icon--double-arrow-forward:before,
.mat-mdc-dialog-container .a-icon--double-arrow-forward:before,
.cdk-overlay-container .a-icon--double-arrow-forward:before,
.cdk-drag-preview .a-icon--double-arrow-forward:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e61a";
}
#sie-boilerplate .a-icon--double-arrow-up:before,
.mat-mdc-dialog-container .a-icon--double-arrow-up:before,
.cdk-overlay-container .a-icon--double-arrow-up:before,
.cdk-drag-preview .a-icon--double-arrow-up:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e61b";
}
#sie-boilerplate .a-icon--download:before,
.mat-mdc-dialog-container .a-icon--download:before,
.cdk-overlay-container .a-icon--download:before,
.cdk-drag-preview .a-icon--download:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e61c";
}
#sie-boilerplate .a-icon--drag:before,
.mat-mdc-dialog-container .a-icon--drag:before,
.cdk-overlay-container .a-icon--drag:before,
.cdk-drag-preview .a-icon--drag:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e61d";
}
#sie-boilerplate .a-icon--edit:before,
.mat-mdc-dialog-container .a-icon--edit:before,
.cdk-overlay-container .a-icon--edit:before,
.cdk-drag-preview .a-icon--edit:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e61e";
}
#sie-boilerplate .a-icon--email:before,
.mat-mdc-dialog-container .a-icon--email:before,
.cdk-overlay-container .a-icon--email:before,
.cdk-drag-preview .a-icon--email:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e61f";
}
#sie-boilerplate .a-icon--ext:before,
.mat-mdc-dialog-container .a-icon--ext:before,
.cdk-overlay-container .a-icon--ext:before,
.cdk-drag-preview .a-icon--ext:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e620";
}
#sie-boilerplate .a-icon--facebook:before,
.mat-mdc-dialog-container .a-icon--facebook:before,
.cdk-overlay-container .a-icon--facebook:before,
.cdk-drag-preview .a-icon--facebook:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e621";
}
#sie-boilerplate .a-icon--facebook-negative:before,
.mat-mdc-dialog-container .a-icon--facebook-negative:before,
.cdk-overlay-container .a-icon--facebook-negative:before,
.cdk-drag-preview .a-icon--facebook-negative:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e622";
}
#sie-boilerplate .a-icon--fax:before,
.mat-mdc-dialog-container .a-icon--fax:before,
.cdk-overlay-container .a-icon--fax:before,
.cdk-drag-preview .a-icon--fax:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e623";
}
#sie-boilerplate .a-icon--feature:before,
.mat-mdc-dialog-container .a-icon--feature:before,
.cdk-overlay-container .a-icon--feature:before,
.cdk-drag-preview .a-icon--feature:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e933";
}
#sie-boilerplate .a-icon--file:before,
.mat-mdc-dialog-container .a-icon--file:before,
.cdk-overlay-container .a-icon--file:before,
.cdk-drag-preview .a-icon--file:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e624";
}
#sie-boilerplate .a-icon--avi:before,
.mat-mdc-dialog-container .a-icon--avi:before,
.cdk-overlay-container .a-icon--avi:before,
.cdk-drag-preview .a-icon--avi:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e625";
}
#sie-boilerplate .a-icon--doc:before,
.mat-mdc-dialog-container .a-icon--doc:before,
.cdk-overlay-container .a-icon--doc:before,
.cdk-drag-preview .a-icon--doc:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e626";
}
#sie-boilerplate .a-icon--eps:before,
.mat-mdc-dialog-container .a-icon--eps:before,
.cdk-overlay-container .a-icon--eps:before,
.cdk-drag-preview .a-icon--eps:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e627";
}
#sie-boilerplate .a-icon--jpg:before,
.mat-mdc-dialog-container .a-icon--jpg:before,
.cdk-overlay-container .a-icon--jpg:before,
.cdk-drag-preview .a-icon--jpg:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e628";
}
#sie-boilerplate .a-icon--mov:before,
.mat-mdc-dialog-container .a-icon--mov:before,
.cdk-overlay-container .a-icon--mov:before,
.cdk-drag-preview .a-icon--mov:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e629";
}
#sie-boilerplate .a-icon--mp3:before,
.mat-mdc-dialog-container .a-icon--mp3:before,
.cdk-overlay-container .a-icon--mp3:before,
.cdk-drag-preview .a-icon--mp3:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e62a";
}
#sie-boilerplate .a-icon--mp4:before,
.mat-mdc-dialog-container .a-icon--mp4:before,
.cdk-overlay-container .a-icon--mp4:before,
.cdk-drag-preview .a-icon--mp4:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e62b";
}
#sie-boilerplate .a-icon--png:before,
.mat-mdc-dialog-container .a-icon--png:before,
.cdk-overlay-container .a-icon--png:before,
.cdk-drag-preview .a-icon--png:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e62d";
}
#sie-boilerplate .a-icon--ppt:before,
.mat-mdc-dialog-container .a-icon--ppt:before,
.cdk-overlay-container .a-icon--ppt:before,
.cdk-drag-preview .a-icon--ppt:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e62e";
}
#sie-boilerplate .a-icon--files:before,
.mat-mdc-dialog-container .a-icon--files:before,
.cdk-overlay-container .a-icon--files:before,
.cdk-drag-preview .a-icon--files:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e62f";
}
#sie-boilerplate .a-icon--tif:before,
.mat-mdc-dialog-container .a-icon--tif:before,
.cdk-overlay-container .a-icon--tif:before,
.cdk-drag-preview .a-icon--tif:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e630";
}
#sie-boilerplate .a-icon--vcf:before,
.mat-mdc-dialog-container .a-icon--vcf:before,
.cdk-overlay-container .a-icon--vcf:before,
.cdk-drag-preview .a-icon--vcf:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e631";
}
#sie-boilerplate .a-icon--xls:before,
.mat-mdc-dialog-container .a-icon--xls:before,
.cdk-overlay-container .a-icon--xls:before,
.cdk-drag-preview .a-icon--xls:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e632";
}
#sie-boilerplate .a-icon--zip:before,
.mat-mdc-dialog-container .a-icon--zip:before,
.cdk-overlay-container .a-icon--zip:before,
.cdk-drag-preview .a-icon--zip:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e633";
}
#sie-boilerplate .a-icon--forum:before,
.mat-mdc-dialog-container .a-icon--forum:before,
.cdk-overlay-container .a-icon--forum:before,
.cdk-drag-preview .a-icon--forum:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e634";
}
#sie-boilerplate .a-icon--globe:before,
.mat-mdc-dialog-container .a-icon--globe:before,
.cdk-overlay-container .a-icon--globe:before,
.cdk-drag-preview .a-icon--globe:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e636";
}
#sie-boilerplate .a-icon--glossary:before,
.mat-mdc-dialog-container .a-icon--glossary:before,
.cdk-overlay-container .a-icon--glossary:before,
.cdk-drag-preview .a-icon--glossary:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e637";
}
#sie-boilerplate .a-icon--google:before,
.mat-mdc-dialog-container .a-icon--google:before,
.cdk-overlay-container .a-icon--google:before,
.cdk-drag-preview .a-icon--google:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e638";
}
#sie-boilerplate .a-icon--google-negative:before,
.mat-mdc-dialog-container .a-icon--google-negative:before,
.cdk-overlay-container .a-icon--google-negative:before,
.cdk-drag-preview .a-icon--google-negative:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e639";
}
#sie-boilerplate .a-icon--home:before,
.mat-mdc-dialog-container .a-icon--home:before,
.cdk-overlay-container .a-icon--home:before,
.cdk-drag-preview .a-icon--home:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e63a";
}
#sie-boilerplate .a-icon--information:before,
.mat-mdc-dialog-container .a-icon--information:before,
.cdk-overlay-container .a-icon--information:before,
.cdk-drag-preview .a-icon--information:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e63b";
}
#sie-boilerplate .a-icon--information-package:before,
.mat-mdc-dialog-container .a-icon--information-package:before,
.cdk-overlay-container .a-icon--information-package:before,
.cdk-drag-preview .a-icon--information-package:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e63c";
}
#sie-boilerplate .a-icon--instagram:before,
.mat-mdc-dialog-container .a-icon--instagram:before,
.cdk-overlay-container .a-icon--instagram:before,
.cdk-drag-preview .a-icon--instagram:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e63d";
}
#sie-boilerplate .a-icon--instagram-negative:before,
.mat-mdc-dialog-container .a-icon--instagram-negative:before,
.cdk-overlay-container .a-icon--instagram-negative:before,
.cdk-drag-preview .a-icon--instagram-negative:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e63e";
}
#sie-boilerplate .a-icon--legal-notice:before,
.mat-mdc-dialog-container .a-icon--legal-notice:before,
.cdk-overlay-container .a-icon--legal-notice:before,
.cdk-drag-preview .a-icon--legal-notice:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e63f";
}
#sie-boilerplate .a-icon--level-up:before,
.mat-mdc-dialog-container .a-icon--level-up:before,
.cdk-overlay-container .a-icon--level-up:before,
.cdk-drag-preview .a-icon--level-up:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e640";
}
#sie-boilerplate .a-icon--investor-relations:before,
.mat-mdc-dialog-container .a-icon--investor-relations:before,
.cdk-overlay-container .a-icon--investor-relations:before,
.cdk-drag-preview .a-icon--investor-relations:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e90a";
}
#sie-boilerplate .a-icon--linkedin:before,
.mat-mdc-dialog-container .a-icon--linkedin:before,
.cdk-overlay-container .a-icon--linkedin:before,
.cdk-drag-preview .a-icon--linkedin:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e641";
}
#sie-boilerplate .a-icon--linkedin-negative:before,
.mat-mdc-dialog-container .a-icon--linkedin-negative:before,
.cdk-overlay-container .a-icon--linkedin-negative:before,
.cdk-drag-preview .a-icon--linkedin-negative:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e642";
}
#sie-boilerplate .a-icon--list-view:before,
.mat-mdc-dialog-container .a-icon--list-view:before,
.cdk-overlay-container .a-icon--list-view:before,
.cdk-drag-preview .a-icon--list-view:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e643";
}
#sie-boilerplate .a-icon--location-pin:before,
.mat-mdc-dialog-container .a-icon--location-pin:before,
.cdk-overlay-container .a-icon--location-pin:before,
.cdk-drag-preview .a-icon--location-pin:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e644";
}
#sie-boilerplate .a-icon--log-in:before,
.mat-mdc-dialog-container .a-icon--log-in:before,
.cdk-overlay-container .a-icon--log-in:before,
.cdk-drag-preview .a-icon--log-in:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e645";
}
#sie-boilerplate .a-icon--log-out:before,
.mat-mdc-dialog-container .a-icon--log-out:before,
.cdk-overlay-container .a-icon--log-out:before,
.cdk-drag-preview .a-icon--log-out:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e646";
}
#sie-boilerplate .a-icon--menu:before,
.mat-mdc-dialog-container .a-icon--menu:before,
.cdk-overlay-container .a-icon--menu:before,
.cdk-drag-preview .a-icon--menu:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e647";
}
#sie-boilerplate .a-icon--minus:before,
.mat-mdc-dialog-container .a-icon--minus:before,
.cdk-overlay-container .a-icon--minus:before,
.cdk-drag-preview .a-icon--minus:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e648";
}
#sie-boilerplate .a-icon--more:before,
.mat-mdc-dialog-container .a-icon--more:before,
.cdk-overlay-container .a-icon--more:before,
.cdk-drag-preview .a-icon--more:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e649";
}
#sie-boilerplate .a-icon--navigation-gyroscope:before,
.mat-mdc-dialog-container .a-icon--navigation-gyroscope:before,
.cdk-overlay-container .a-icon--navigation-gyroscope:before,
.cdk-drag-preview .a-icon--navigation-gyroscope:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e64a";
}
#sie-boilerplate .a-icon--navigation-mouse:before,
.mat-mdc-dialog-container .a-icon--navigation-mouse:before,
.cdk-overlay-container .a-icon--navigation-mouse:before,
.cdk-drag-preview .a-icon--navigation-mouse:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e64b";
}
#sie-boilerplate .a-icon--newton_product-catalogue:before,
.mat-mdc-dialog-container .a-icon--newton_product-catalogue:before,
.cdk-overlay-container .a-icon--newton_product-catalogue:before,
.cdk-drag-preview .a-icon--newton_product-catalogue:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e64c";
}
#sie-boilerplate .a-icon--outline:before,
.mat-mdc-dialog-container .a-icon--outline:before,
.cdk-overlay-container .a-icon--outline:before,
.cdk-drag-preview .a-icon--outline:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e64d";
}
#sie-boilerplate .a-icon--phone:before,
.mat-mdc-dialog-container .a-icon--phone:before,
.cdk-overlay-container .a-icon--phone:before,
.cdk-drag-preview .a-icon--phone:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e64e";
  transform: translate(0, 0.5px) !important;
}
#sie-boilerplate .a-icon--play:before,
.mat-mdc-dialog-container .a-icon--play:before,
.cdk-overlay-container .a-icon--play:before,
.cdk-drag-preview .a-icon--play:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e64f";
}
#sie-boilerplate .a-icon--plus:before,
.mat-mdc-dialog-container .a-icon--plus:before,
.cdk-overlay-container .a-icon--plus:before,
.cdk-drag-preview .a-icon--plus:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e650";
}
#sie-boilerplate .a-icon--print:before,
.mat-mdc-dialog-container .a-icon--print:before,
.cdk-overlay-container .a-icon--print:before,
.cdk-drag-preview .a-icon--print:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e651";
}
#sie-boilerplate .a-icon--rating:before,
.mat-mdc-dialog-container .a-icon--rating:before,
.cdk-overlay-container .a-icon--rating:before,
.cdk-drag-preview .a-icon--rating:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e652";
}
#sie-boilerplate .a-icon--region:before,
.mat-mdc-dialog-container .a-icon--region:before,
.cdk-overlay-container .a-icon--region:before,
.cdk-drag-preview .a-icon--region:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e653";
}
#sie-boilerplate .a-icon--replay:before,
.mat-mdc-dialog-container .a-icon--replay:before,
.cdk-overlay-container .a-icon--replay:before,
.cdk-drag-preview .a-icon--replay:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e91b";
}
#sie-boilerplate .a-icon--rss:before,
.mat-mdc-dialog-container .a-icon--rss:before,
.cdk-overlay-container .a-icon--rss:before,
.cdk-drag-preview .a-icon--rss:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e655";
}
#sie-boilerplate .a-icon--scaling:before,
.mat-mdc-dialog-container .a-icon--scaling:before,
.cdk-overlay-container .a-icon--scaling:before,
.cdk-drag-preview .a-icon--scaling:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e656";
}
#sie-boilerplate .a-icon--search:before,
.mat-mdc-dialog-container .a-icon--search:before,
.cdk-overlay-container .a-icon--search:before,
.cdk-drag-preview .a-icon--search:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e657";
}
#sie-boilerplate .a-icon--share-ios:before,
.mat-mdc-dialog-container .a-icon--share-ios:before,
.cdk-overlay-container .a-icon--share-ios:before,
.cdk-drag-preview .a-icon--share-ios:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e658";
}
#sie-boilerplate .a-icon--share-newton-android:before,
.mat-mdc-dialog-container .a-icon--share-newton-android:before,
.cdk-overlay-container .a-icon--share-newton-android:before,
.cdk-drag-preview .a-icon--share-newton-android:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e659";
}
#sie-boilerplate .a-icon--shopping-cart:before,
.mat-mdc-dialog-container .a-icon--shopping-cart:before,
.cdk-overlay-container .a-icon--shopping-cart:before,
.cdk-drag-preview .a-icon--shopping-cart:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e65a";
}
#sie-boilerplate .a-icon--show-location:before,
.mat-mdc-dialog-container .a-icon--show-location:before,
.cdk-overlay-container .a-icon--show-location:before,
.cdk-drag-preview .a-icon--show-location:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e65b";
}
#sie-boilerplate .a-icon--single-arrow-backward:before,
.mat-mdc-dialog-container .a-icon--single-arrow-backward:before,
.cdk-overlay-container .a-icon--single-arrow-backward:before,
.cdk-drag-preview .a-icon--single-arrow-backward:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e65c";
}
#sie-boilerplate .a-icon--single-arrow-down:before,
.mat-mdc-dialog-container .a-icon--single-arrow-down:before,
.cdk-overlay-container .a-icon--single-arrow-down:before,
.cdk-drag-preview .a-icon--single-arrow-down:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e65d";
}
#sie-boilerplate .a-icon--single-arrow-forward:before,
.mat-mdc-dialog-container .a-icon--single-arrow-forward:before,
.cdk-overlay-container .a-icon--single-arrow-forward:before,
.cdk-drag-preview .a-icon--single-arrow-forward:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e65e";
}
#sie-boilerplate .a-icon--single-arrow-up:before,
.mat-mdc-dialog-container .a-icon--single-arrow-up:before,
.cdk-overlay-container .a-icon--single-arrow-up:before,
.cdk-drag-preview .a-icon--single-arrow-up:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e65f";
}
#sie-boilerplate .a-icon--slider:before,
.mat-mdc-dialog-container .a-icon--slider:before,
.cdk-overlay-container .a-icon--slider:before,
.cdk-drag-preview .a-icon--slider:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e660";
}
#sie-boilerplate .a-icon--social-feed:before,
.mat-mdc-dialog-container .a-icon--social-feed:before,
.cdk-overlay-container .a-icon--social-feed:before,
.cdk-drag-preview .a-icon--social-feed:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e661";
}
#sie-boilerplate .a-icon--speakers-off:before,
.mat-mdc-dialog-container .a-icon--speakers-off:before,
.cdk-overlay-container .a-icon--speakers-off:before,
.cdk-drag-preview .a-icon--speakers-off:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e662";
}
#sie-boilerplate .a-icon--speakers-on:before,
.mat-mdc-dialog-container .a-icon--speakers-on:before,
.cdk-overlay-container .a-icon--speakers-on:before,
.cdk-drag-preview .a-icon--speakers-on:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e663";
}
#sie-boilerplate .a-icon--subscribe:before,
.mat-mdc-dialog-container .a-icon--subscribe:before,
.cdk-overlay-container .a-icon--subscribe:before,
.cdk-drag-preview .a-icon--subscribe:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e664";
}
#sie-boilerplate .a-icon--theenergyblog:before,
.mat-mdc-dialog-container .a-icon--theenergyblog:before,
.cdk-overlay-container .a-icon--theenergyblog:before,
.cdk-drag-preview .a-icon--theenergyblog:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e665";
}
#sie-boilerplate .a-icon--theenergyblog-negative:before,
.mat-mdc-dialog-container .a-icon--theenergyblog-negative:before,
.cdk-overlay-container .a-icon--theenergyblog-negative:before,
.cdk-drag-preview .a-icon--theenergyblog-negative:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e666";
}
#sie-boilerplate .a-icon--tile-view:before,
.mat-mdc-dialog-container .a-icon--tile-view:before,
.cdk-overlay-container .a-icon--tile-view:before,
.cdk-drag-preview .a-icon--tile-view:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e667";
}
#sie-boilerplate .a-icon--twitter:before,
.mat-mdc-dialog-container .a-icon--twitter:before,
.cdk-overlay-container .a-icon--twitter:before,
.cdk-drag-preview .a-icon--twitter:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e668";
}
#sie-boilerplate .a-icon--twitter-negative:before,
.mat-mdc-dialog-container .a-icon--twitter-negative:before,
.cdk-overlay-container .a-icon--twitter-negative:before,
.cdk-drag-preview .a-icon--twitter-negative:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e669";
}
#sie-boilerplate .a-icon--user-account:before,
.mat-mdc-dialog-container .a-icon--user-account:before,
.cdk-overlay-container .a-icon--user-account:before,
.cdk-drag-preview .a-icon--user-account:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e66a";
}
#sie-boilerplate .a-icon--video-chat:before,
.mat-mdc-dialog-container .a-icon--video-chat:before,
.cdk-overlay-container .a-icon--video-chat:before,
.cdk-drag-preview .a-icon--video-chat:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e66b";
}
#sie-boilerplate .a-icon--warning:before,
.mat-mdc-dialog-container .a-icon--warning:before,
.cdk-overlay-container .a-icon--warning:before,
.cdk-drag-preview .a-icon--warning:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e66c";
}
#sie-boilerplate .a-icon--zoom:before,
.mat-mdc-dialog-container .a-icon--zoom:before,
.cdk-overlay-container .a-icon--zoom:before,
.cdk-drag-preview .a-icon--zoom:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e66f";
}
#sie-boilerplate .a-icon--support:before,
.mat-mdc-dialog-container .a-icon--support:before,
.cdk-overlay-container .a-icon--support:before,
.cdk-drag-preview .a-icon--support:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e904";
}
#sie-boilerplate .a-icon--service:before,
.mat-mdc-dialog-container .a-icon--service:before,
.cdk-overlay-container .a-icon--service:before,
.cdk-drag-preview .a-icon--service:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e905";
}
#sie-boilerplate .a-icon--settings:before,
.mat-mdc-dialog-container .a-icon--settings:before,
.cdk-overlay-container .a-icon--settings:before,
.cdk-drag-preview .a-icon--settings:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e90f";
}
#sie-boilerplate .a-icon--vector:before,
.mat-mdc-dialog-container .a-icon--vector:before,
.cdk-overlay-container .a-icon--vector:before,
.cdk-drag-preview .a-icon--vector:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "󫤀";
}
#sie-boilerplate .a-icon--bell-solid:before,
.mat-mdc-dialog-container .a-icon--bell-solid:before,
.cdk-overlay-container .a-icon--bell-solid:before,
.cdk-drag-preview .a-icon--bell-solid:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "󫤁";
}
#sie-boilerplate .a-icon--nav-icon:before,
.mat-mdc-dialog-container .a-icon--nav-icon:before,
.cdk-overlay-container .a-icon--nav-icon:before,
.cdk-drag-preview .a-icon--nav-icon:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "󫤂";
}
#sie-boilerplate .a-icon--back-projects-arrow:before,
.mat-mdc-dialog-container .a-icon--back-projects-arrow:before,
.cdk-overlay-container .a-icon--back-projects-arrow:before,
.cdk-drag-preview .a-icon--back-projects-arrow:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "󫤃";
}
#sie-boilerplate .a-icon--attach_money:before,
.mat-mdc-dialog-container .a-icon--attach_money:before,
.cdk-overlay-container .a-icon--attach_money:before,
.cdk-drag-preview .a-icon--attach_money:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "󫤄";
}
#sie-boilerplate .a-icon--clock:before,
.mat-mdc-dialog-container .a-icon--clock:before,
.cdk-overlay-container .a-icon--clock:before,
.cdk-drag-preview .a-icon--clock:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e907";
}
#sie-boilerplate .a-icon--euro-coin:before,
.mat-mdc-dialog-container .a-icon--euro-coin:before,
.cdk-overlay-container .a-icon--euro-coin:before,
.cdk-drag-preview .a-icon--euro-coin:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "󫤈";
}
#sie-boilerplate .a-icon--toolbox:before,
.mat-mdc-dialog-container .a-icon--toolbox:before,
.cdk-overlay-container .a-icon--toolbox:before,
.cdk-drag-preview .a-icon--toolbox:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "󫤉";
}
#sie-boilerplate .a-button--pwo,
.mat-mdc-dialog-container .a-button--pwo,
.cdk-overlay-container .a-button--pwo,
.cdk-drag-preview .a-button--pwo {
  font-family: "Siemens Sans Bold", sans-serif;
  font-size: 14px;
  line-height: 0;
  letter-spacing: 0;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
  transition: color 0.15s ease-in-out, background 0.15s ease-in-out, border-color 0.15s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  cursor: pointer;
  justify-content: flex-start;
  padding: 9px 15px 9px 10px;
  min-height: 35px;
  border-radius: 0;
  line-height: normal;
  position: relative;
  height: 35px;
  font-size: 14px !important;
  font-weight: 600;
  text-align: center;
  padding: 7px 20px;
  display: flex;
  justify-content: center;
  background-color: var(--ceres-primary-color-1);
  color: var(--ceres-white);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#sie-boilerplate .a-button--pwo.a-icon:before,
.mat-mdc-dialog-container .a-button--pwo.a-icon:before,
.cdk-overlay-container .a-button--pwo.a-icon:before,
.cdk-drag-preview .a-button--pwo.a-icon:before {
  font-size: 18px;
  line-height: normal !important;
  margin: 0 10px 0 0;
  transition: color 0.15s ease-in-out;
  display: inline-block;
  transform: translate(0, -1px);
}
#sie-boilerplate .a-button--pwo:hover,
.mat-mdc-dialog-container .a-button--pwo:hover,
.cdk-overlay-container .a-button--pwo:hover,
.cdk-drag-preview .a-button--pwo:hover {
  background: var(--ceres-primary-color-1);
  color: var(--ceres-white);
}
#sie-boilerplate .a-button--pwo:disabled:hover,
.mat-mdc-dialog-container .a-button--pwo:disabled:hover,
.cdk-overlay-container .a-button--pwo:disabled:hover,
.cdk-drag-preview .a-button--pwo:disabled:hover {
  background: var(--ceres-primary-color-1);
}
#sie-boilerplate .a-button--cancel,
.mat-mdc-dialog-container .a-button--cancel,
.cdk-overlay-container .a-button--cancel,
.cdk-drag-preview .a-button--cancel {
  font-family: "Siemens Sans Bold", sans-serif;
  font-size: 14px;
  line-height: 0;
  letter-spacing: 0;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
  transition: color 0.15s ease-in-out, background 0.15s ease-in-out, border-color 0.15s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  cursor: pointer;
  justify-content: flex-start;
  padding: 9px 15px 9px 10px;
  min-height: 35px;
  border-radius: 0;
  line-height: normal;
  position: relative;
  margin: 0 7px;
  height: 35px;
  font-size: 14px !important;
  font-weight: 600;
  text-align: center;
  padding: 7px 20px;
  background-color: var(--ceres-white);
  color: var(--ceres-primary-color-5);
  border: 1px solid var(--ceres-primary-color-5) !important;
  font-weight: 500;
  justify-content: center;
}
#sie-boilerplate .a-button--cancel.a-icon:before,
.mat-mdc-dialog-container .a-button--cancel.a-icon:before,
.cdk-overlay-container .a-button--cancel.a-icon:before,
.cdk-drag-preview .a-button--cancel.a-icon:before {
  font-size: 18px;
  line-height: normal !important;
  margin: 0 10px 0 0;
  transition: color 0.15s ease-in-out;
  display: inline-block;
  transform: translate(0, -1px);
}
#sie-boilerplate .a-button--cancel:hover,
.mat-mdc-dialog-container .a-button--cancel:hover,
.cdk-overlay-container .a-button--cancel:hover,
.cdk-drag-preview .a-button--cancel:hover {
  background-color: var(--ceres-primary-color-5);
  color: var(--ceres-white);
}
#sie-boilerplate .a-button--warning,
.mat-mdc-dialog-container .a-button--warning,
.cdk-overlay-container .a-button--warning,
.cdk-drag-preview .a-button--warning {
  font-family: "Siemens Sans Bold", sans-serif;
  font-size: 14px;
  line-height: 0;
  letter-spacing: 0;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
  transition: color 0.15s ease-in-out, background 0.15s ease-in-out, border-color 0.15s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  cursor: pointer;
  justify-content: flex-start;
  padding: 9px 15px 9px 10px;
  min-height: 35px;
  border-radius: 0;
  line-height: normal;
  position: relative;
  margin: 0 7px;
  height: 35px;
  font-size: 14px !important;
  font-weight: 600;
  text-align: center;
  padding: 7px 20px;
  background-color: var(--ceres-secondary-color-12);
  color: var(--ceres-white);
  border: none !important;
  font-weight: 500;
  justify-content: center;
}
#sie-boilerplate .a-button--warning.a-icon:before,
.mat-mdc-dialog-container .a-button--warning.a-icon:before,
.cdk-overlay-container .a-button--warning.a-icon:before,
.cdk-drag-preview .a-button--warning.a-icon:before {
  font-size: 18px;
  line-height: normal !important;
  margin: 0 10px 0 0;
  transition: color 0.15s ease-in-out;
  display: inline-block;
  transform: translate(0, -1px);
}
#sie-boilerplate .a-button--warning:hover,
.mat-mdc-dialog-container .a-button--warning:hover,
.cdk-overlay-container .a-button--warning:hover,
.cdk-drag-preview .a-button--warning:hover {
  background-color: var(--ceres-secondary-color-12);
}
#sie-boilerplate .a-button--danger,
.mat-mdc-dialog-container .a-button--danger,
.cdk-overlay-container .a-button--danger,
.cdk-drag-preview .a-button--danger {
  font-family: "Siemens Sans Bold", sans-serif;
  font-size: 14px;
  line-height: 0;
  letter-spacing: 0;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
  transition: color 0.15s ease-in-out, background 0.15s ease-in-out, border-color 0.15s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  cursor: pointer;
  justify-content: flex-start;
  padding: 9px 15px 9px 10px;
  min-height: 35px;
  border-radius: 0;
  line-height: normal;
  position: relative;
  margin: 0 7px;
  height: 35px;
  font-size: 14px !important;
  font-weight: 600;
  text-align: center;
  padding: 7px 20px;
  background-color: var(--ceres-secondary-color-14);
  color: var(--ceres-white);
  border: none !important;
  font-weight: 500;
  justify-content: center;
}
#sie-boilerplate .a-button--danger.a-icon:before,
.mat-mdc-dialog-container .a-button--danger.a-icon:before,
.cdk-overlay-container .a-button--danger.a-icon:before,
.cdk-drag-preview .a-button--danger.a-icon:before {
  font-size: 18px;
  line-height: normal !important;
  margin: 0 10px 0 0;
  transition: color 0.15s ease-in-out;
  display: inline-block;
  transform: translate(0, -1px);
}
#sie-boilerplate .a-button--danger:hover,
.mat-mdc-dialog-container .a-button--danger:hover,
.cdk-overlay-container .a-button--danger:hover,
.cdk-drag-preview .a-button--danger:hover {
  background-color: var(--ceres-secondary-color-14);
}
#sie-boilerplate .menu-btn,
.mat-mdc-dialog-container .menu-btn,
.cdk-overlay-container .menu-btn,
.cdk-drag-preview .menu-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 35px;
  padding: 5px 15px 5px 0px;
  border: none;
  background: transparent;
  font-size: 14px;
  gap: 10px;
}
#sie-boilerplate .menu-btn.menu-btn-quiet,
.mat-mdc-dialog-container .menu-btn.menu-btn-quiet,
.cdk-overlay-container .menu-btn.menu-btn-quiet,
.cdk-drag-preview .menu-btn.menu-btn-quiet {
  background-color: transparent;
  color: var(--ceres-primary-color-1);
  font-weight: bold;
}
#sie-boilerplate .menu-btn.menu-btn-quiet:hover,
.mat-mdc-dialog-container .menu-btn.menu-btn-quiet:hover,
.cdk-overlay-container .menu-btn.menu-btn-quiet:hover,
.cdk-drag-preview .menu-btn.menu-btn-quiet:hover {
  filter: brightness(0.9);
}
#sie-boilerplate .menu-main,
.mat-mdc-dialog-container .menu-main,
.cdk-overlay-container .menu-main,
.cdk-drag-preview .menu-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
#sie-boilerplate .menu-main > li,
.mat-mdc-dialog-container .menu-main > li,
.cdk-overlay-container .menu-main > li,
.cdk-drag-preview .menu-main > li {
  color: var(--ceres-primary-color-5);
}
#sie-boilerplate .menu-main > li > a .head-nav-icon,
.mat-mdc-dialog-container .menu-main > li > a .head-nav-icon,
.cdk-overlay-container .menu-main > li > a .head-nav-icon,
.cdk-drag-preview .menu-main > li > a .head-nav-icon {
  display: flex;
  justify-content: center;
  width: 60px;
  font-size: 36px;
  text-align: center;
  color: var(--ceres-primary-color-5);
}
#sie-boilerplate .menu-main > li > a .head-nav-icon:hover,
.mat-mdc-dialog-container .menu-main > li > a .head-nav-icon:hover,
.cdk-overlay-container .menu-main > li > a .head-nav-icon:hover,
.cdk-drag-preview .menu-main > li > a .head-nav-icon:hover {
  color: var(--ceres-primary-color-1);
}
#sie-boilerplate .menu-main a,
.mat-mdc-dialog-container .menu-main a,
.cdk-overlay-container .menu-main a,
.cdk-drag-preview .menu-main a {
  display: block;
  height: 100%;
  color: var(--ceres-static-white);
  text-decoration: none;
  cursor: pointer;
}
#sie-boilerplate .icon::before,
.mat-mdc-dialog-container .icon::before,
.cdk-overlay-container .icon::before,
.cdk-drag-preview .icon::before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  display: inline-block;
  text-align: center;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  speak: none;
  -webkit-font-smoothing: antialiased;
}
#sie-boilerplate .menu-wrapper,
.mat-mdc-dialog-container .menu-wrapper,
.cdk-overlay-container .menu-wrapper,
.cdk-drag-preview .menu-wrapper {
  position: fixed;
  top: 70px;
  bottom: 60px;
  left: 0;
  overflow: hidden;
  width: 60px;
  z-index: 1;
  background: transparent;
  transition: transform 0.3s, width 0.3s;
}
#sie-boilerplate .menu-wrapper .outer,
.mat-mdc-dialog-container .menu-wrapper .outer,
.cdk-overlay-container .menu-wrapper .outer,
.cdk-drag-preview .menu-wrapper .outer {
  height: 100%;
  width: calc(100vw - 380px);
  position: absolute;
  right: 0px;
  background: transparent;
}
#sie-boilerplate .menu-wrapper.open-all,
.mat-mdc-dialog-container .menu-wrapper.open-all,
.cdk-overlay-container .menu-wrapper.open-all,
.cdk-drag-preview .menu-wrapper.open-all {
  transform: translateX(0px);
  width: 100%;
  z-index: 9;
}
#sie-boilerplate .menu-wrapper.open-all .scroller,
.mat-mdc-dialog-container .menu-wrapper.open-all .scroller,
.cdk-overlay-container .menu-wrapper.open-all .scroller,
.cdk-drag-preview .menu-wrapper.open-all .scroller {
  transform: translateX(0px);
}
#sie-boilerplate .menu-wrapper.open-all .submenu li.active,
.mat-mdc-dialog-container .menu-wrapper.open-all .submenu li.active,
.cdk-overlay-container .menu-wrapper.open-all .submenu li.active,
.cdk-drag-preview .menu-wrapper.open-all .submenu li.active {
  padding-left: 35px;
}
#sie-boilerplate .menu-wrapper .scroller,
.mat-mdc-dialog-container .menu-wrapper .scroller,
.cdk-overlay-container .menu-wrapper .scroller,
.cdk-drag-preview .menu-wrapper .scroller {
  position: absolute;
  overflow-y: scroll;
  width: 380px;
  height: 100%;
  background: var(--ceres-sidenav-color);
  left: 0;
  transform: translateX(0px);
}
#sie-boilerplate .menu-wrapper .scroller .menu,
.mat-mdc-dialog-container .menu-wrapper .scroller .menu,
.cdk-overlay-container .menu-wrapper .scroller .menu,
.cdk-drag-preview .menu-wrapper .scroller .menu {
  text-align: left;
  font-size: 18px;
}
#sie-boilerplate .menu-wrapper li,
.mat-mdc-dialog-container .menu-wrapper li,
.cdk-overlay-container .menu-wrapper li,
.cdk-drag-preview .menu-wrapper li {
  background-color: var(--ceres-sidenav-color);
}
#sie-boilerplate .menu-wrapper li a,
.mat-mdc-dialog-container .menu-wrapper li a,
.cdk-overlay-container .menu-wrapper li a,
.cdk-drag-preview .menu-wrapper li a {
  display: flex;
  flex-direction: row;
  height: 60px;
  padding: 20px 0px !important;
  position: relative;
}
#sie-boilerplate .menu-wrapper li a .a-icon,
.mat-mdc-dialog-container .menu-wrapper li a .a-icon,
.cdk-overlay-container .menu-wrapper li a .a-icon,
.cdk-drag-preview .menu-wrapper li a .a-icon {
  font-size: 24px;
  position: relative;
  top: -3px;
}
#sie-boilerplate .menu-wrapper li a .a-icon::before,
.mat-mdc-dialog-container .menu-wrapper li a .a-icon::before,
.cdk-overlay-container .menu-wrapper li a .a-icon::before,
.cdk-drag-preview .menu-wrapper li a .a-icon::before {
  width: 60px;
}
#sie-boilerplate .menu-wrapper li a .a-icon.a-icon--attach_money,
.mat-mdc-dialog-container .menu-wrapper li a .a-icon.a-icon--attach_money,
.cdk-overlay-container .menu-wrapper li a .a-icon.a-icon--attach_money,
.cdk-drag-preview .menu-wrapper li a .a-icon.a-icon--attach_money {
  font-size: 34px;
  top: -8px;
}
#sie-boilerplate .menu-wrapper li a .arrow,
.mat-mdc-dialog-container .menu-wrapper li a .arrow,
.cdk-overlay-container .menu-wrapper li a .arrow,
.cdk-drag-preview .menu-wrapper li a .arrow {
  position: absolute;
  font-size: 14px;
  right: 0px;
  top: 24px;
}
#sie-boilerplate .menu-wrapper li a:hover,
.mat-mdc-dialog-container .menu-wrapper li a:hover,
.cdk-overlay-container .menu-wrapper li a:hover,
.cdk-drag-preview .menu-wrapper li a:hover {
  background-color: var(--ceres-primary-color-1);
}
#sie-boilerplate .menu-wrapper li .nav-icon-down,
.mat-mdc-dialog-container .menu-wrapper li .nav-icon-down,
.cdk-overlay-container .menu-wrapper li .nav-icon-down,
.cdk-drag-preview .menu-wrapper li .nav-icon-down {
  display: none;
}
#sie-boilerplate .menu-wrapper li .nav-icon-right,
.mat-mdc-dialog-container .menu-wrapper li .nav-icon-right,
.cdk-overlay-container .menu-wrapper li .nav-icon-right,
.cdk-drag-preview .menu-wrapper li .nav-icon-right {
  display: inherit;
}
#sie-boilerplate .menu-wrapper li.open .nav-icon-down,
.mat-mdc-dialog-container .menu-wrapper li.open .nav-icon-down,
.cdk-overlay-container .menu-wrapper li.open .nav-icon-down,
.cdk-drag-preview .menu-wrapper li.open .nav-icon-down {
  display: inherit;
}
#sie-boilerplate .menu-wrapper li.open .nav-icon-right,
.mat-mdc-dialog-container .menu-wrapper li.open .nav-icon-right,
.cdk-overlay-container .menu-wrapper li.open .nav-icon-right,
.cdk-drag-preview .menu-wrapper li.open .nav-icon-right {
  display: none;
}
#sie-boilerplate .menu-wrapper li.open .submenu li,
.mat-mdc-dialog-container .menu-wrapper li.open .submenu li,
.cdk-overlay-container .menu-wrapper li.open .submenu li,
.cdk-drag-preview .menu-wrapper li.open .submenu li {
  height: 60px;
}
#sie-boilerplate .menu-wrapper li.active, #sie-boilerplate .menu-wrapper li.open,
.mat-mdc-dialog-container .menu-wrapper li.active,
.mat-mdc-dialog-container .menu-wrapper li.open,
.cdk-overlay-container .menu-wrapper li.active,
.cdk-overlay-container .menu-wrapper li.open,
.cdk-drag-preview .menu-wrapper li.active,
.cdk-drag-preview .menu-wrapper li.open {
  background-color: var(--ceres-primary-color-1);
}
#sie-boilerplate .menu-wrapper .nav-icon,
.mat-mdc-dialog-container .menu-wrapper .nav-icon,
.cdk-overlay-container .menu-wrapper .nav-icon,
.cdk-drag-preview .menu-wrapper .nav-icon {
  position: relative;
}
#sie-boilerplate .menu-wrapper .nav-icon i,
.mat-mdc-dialog-container .menu-wrapper .nav-icon i,
.cdk-overlay-container .menu-wrapper .nav-icon i,
.cdk-drag-preview .menu-wrapper .nav-icon i {
  position: absolute;
  right: 10px;
  top: 20px;
}
#sie-boilerplate .menu-wrapper .nav-icon::before,
.mat-mdc-dialog-container .menu-wrapper .nav-icon::before,
.cdk-overlay-container .menu-wrapper .nav-icon::before,
.cdk-drag-preview .menu-wrapper .nav-icon::before {
  font-size: 20px;
  padding: 0px 20px;
}
#sie-boilerplate .submenu li,
.mat-mdc-dialog-container .submenu li,
.cdk-overlay-container .submenu li,
.cdk-drag-preview .submenu li {
  height: 0;
  overflow: hidden;
  transition: height 0.3s;
  padding-left: 35px;
}
#sie-boilerplate .submenu li:hover a, #sie-boilerplate .submenu li.active a,
.mat-mdc-dialog-container .submenu li:hover a,
.mat-mdc-dialog-container .submenu li.active a,
.cdk-overlay-container .submenu li:hover a,
.cdk-overlay-container .submenu li.active a,
.cdk-drag-preview .submenu li:hover a,
.cdk-drag-preview .submenu li.active a {
  color: var(--ceres-primary-color-1);
  background-color: var(--ceres-sidenav-color);
}
#sie-boilerplate .submenu li.active,
.mat-mdc-dialog-container .submenu li.active,
.cdk-overlay-container .submenu li.active,
.cdk-drag-preview .submenu li.active {
  background-color: var(--ceres-sidenav-color);
}
#sie-boilerplate .submenu li.active a,
.mat-mdc-dialog-container .submenu li.active a,
.cdk-overlay-container .submenu li.active a,
.cdk-drag-preview .submenu li.active a {
  color: var(--ceres-primary-color-1);
}
#sie-boilerplate .submenu.closed li,
.mat-mdc-dialog-container .submenu.closed li,
.cdk-overlay-container .submenu.closed li,
.cdk-drag-preview .submenu.closed li {
  height: 0px !important;
}
#sie-boilerplate .submenu.closed li.active,
.mat-mdc-dialog-container .submenu.closed li.active,
.cdk-overlay-container .submenu.closed li.active,
.cdk-drag-preview .submenu.closed li.active {
  height: 0px;
}
#sie-boilerplate .mod-navigation,
.mat-mdc-dialog-container .mod-navigation,
.cdk-overlay-container .mod-navigation,
.cdk-drag-preview .mod-navigation {
  /* ICONS */
  /* SETTINGS */
}
#sie-boilerplate .mod-navigation--title,
.mat-mdc-dialog-container .mod-navigation--title,
.cdk-overlay-container .mod-navigation--title,
.cdk-drag-preview .mod-navigation--title {
  cursor: pointer;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
}
#sie-boilerplate .mod-navigation--icons,
.mat-mdc-dialog-container .mod-navigation--icons,
.cdk-overlay-container .mod-navigation--icons,
.cdk-drag-preview .mod-navigation--icons {
  width: auto;
  height: 90px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
#sie-boilerplate .mod-navigation--icons.left,
.mat-mdc-dialog-container .mod-navigation--icons.left,
.cdk-overlay-container .mod-navigation--icons.left,
.cdk-drag-preview .mod-navigation--icons.left {
  right: 180px;
}
#sie-boilerplate .mod-navigation--settings:before, #sie-boilerplate .mod-navigation--document-repository:before, #sie-boilerplate .mod-navigation--faq:before, #sie-boilerplate .mod-navigation--main:before,
.mat-mdc-dialog-container .mod-navigation--settings:before,
.mat-mdc-dialog-container .mod-navigation--document-repository:before,
.mat-mdc-dialog-container .mod-navigation--faq:before,
.mat-mdc-dialog-container .mod-navigation--main:before,
.cdk-overlay-container .mod-navigation--settings:before,
.cdk-overlay-container .mod-navigation--document-repository:before,
.cdk-overlay-container .mod-navigation--faq:before,
.cdk-overlay-container .mod-navigation--main:before,
.cdk-drag-preview .mod-navigation--settings:before,
.cdk-drag-preview .mod-navigation--document-repository:before,
.cdk-drag-preview .mod-navigation--faq:before,
.cdk-drag-preview .mod-navigation--main:before {
  content: "";
  right: -100%;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--ceres-black-opaque-0);
  position: fixed;
  transition: background 0.5s ease-in-out, right 0s ease-in-out;
  transition-delay: 0s, 0.5s;
}
#sie-boilerplate .mod-navigation--settings > .m-content, #sie-boilerplate .mod-navigation--document-repository > .m-content, #sie-boilerplate .mod-navigation--faq > .m-content, #sie-boilerplate .mod-navigation--main > .m-content,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content,
.mat-mdc-dialog-container .mod-navigation--main > .m-content,
.cdk-overlay-container .mod-navigation--settings > .m-content,
.cdk-overlay-container .mod-navigation--document-repository > .m-content,
.cdk-overlay-container .mod-navigation--faq > .m-content,
.cdk-overlay-container .mod-navigation--main > .m-content,
.cdk-drag-preview .mod-navigation--settings > .m-content,
.cdk-drag-preview .mod-navigation--document-repository > .m-content,
.cdk-drag-preview .mod-navigation--faq > .m-content,
.cdk-drag-preview .mod-navigation--main > .m-content {
  top: 0;
  right: -520px;
  width: 520px;
  height: 100%;
  background: var(--ceres-white);
  position: fixed;
  overflow: hidden;
  z-index: 9999;
  transition: right 0.5s ease-in-out;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--settings > .m-content, #sie-boilerplate .mod-navigation--document-repository > .m-content, #sie-boilerplate .mod-navigation--faq > .m-content, #sie-boilerplate .mod-navigation--main > .m-content,
  .mat-mdc-dialog-container .mod-navigation--settings > .m-content,
  .mat-mdc-dialog-container .mod-navigation--document-repository > .m-content,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content,
  .mat-mdc-dialog-container .mod-navigation--main > .m-content,
  .cdk-overlay-container .mod-navigation--settings > .m-content,
  .cdk-overlay-container .mod-navigation--document-repository > .m-content,
  .cdk-overlay-container .mod-navigation--faq > .m-content,
  .cdk-overlay-container .mod-navigation--main > .m-content,
  .cdk-drag-preview .mod-navigation--settings > .m-content,
  .cdk-drag-preview .mod-navigation--document-repository > .m-content,
  .cdk-drag-preview .mod-navigation--faq > .m-content,
  .cdk-drag-preview .mod-navigation--main > .m-content {
    right: -668.2px;
    width: 668.2px;
  }
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-navigation, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-navigation, #sie-boilerplate .mod-navigation--faq > .m-content > .m-navigation, #sie-boilerplate .mod-navigation--main > .m-content > .m-navigation,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-navigation,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-navigation,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-navigation,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-navigation,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-navigation,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-navigation,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-navigation,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-navigation,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-navigation,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-navigation,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-navigation,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-navigation {
  padding: 20px;
  width: 520px;
  height: 90px;
  right: 0;
  top: 0;
  background: var(--ceres-white);
  border-bottom: 1px solid var(--ceres-secondary-color-17);
  display: flex;
  align-items: center;
  position: absolute;
  transition: right 0.5s ease-in-out;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--settings > .m-content > .m-navigation, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-navigation, #sie-boilerplate .mod-navigation--faq > .m-content > .m-navigation, #sie-boilerplate .mod-navigation--main > .m-content > .m-navigation,
  .mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-navigation,
  .mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-navigation,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-navigation,
  .mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-navigation,
  .cdk-overlay-container .mod-navigation--settings > .m-content > .m-navigation,
  .cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-navigation,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-navigation,
  .cdk-overlay-container .mod-navigation--main > .m-content > .m-navigation,
  .cdk-drag-preview .mod-navigation--settings > .m-content > .m-navigation,
  .cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-navigation,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-navigation,
  .cdk-drag-preview .mod-navigation--main > .m-content > .m-navigation {
    width: 668.2px;
    padding-top: 16px;
  }
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-navigation > .mod-close-page, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-navigation > .mod-close-page, #sie-boilerplate .mod-navigation--faq > .m-content > .m-navigation > .mod-close-page, #sie-boilerplate .mod-navigation--main > .m-content > .m-navigation > .mod-close-page,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-navigation > .mod-close-page,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-navigation > .mod-close-page,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-navigation > .mod-close-page,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-navigation > .mod-close-page,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-navigation > .mod-close-page,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-navigation > .mod-close-page,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-navigation > .mod-close-page,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-navigation > .mod-close-page,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-navigation > .mod-close-page,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-navigation > .mod-close-page,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-navigation > .mod-close-page,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-navigation > .mod-close-page {
  right: 20px;
  top: 50%;
  transform: translate(0, -12px);
  position: absolute;
  cursor: pointer;
  z-index: 2;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-navigation > .m-back-button, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-navigation > .m-back-button, #sie-boilerplate .mod-navigation--faq > .m-content > .m-navigation > .m-back-button, #sie-boilerplate .mod-navigation--main > .m-content > .m-navigation > .m-back-button,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-navigation > .m-back-button,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-navigation > .m-back-button,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-navigation > .m-back-button,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-navigation > .m-back-button,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-navigation > .m-back-button,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-navigation > .m-back-button,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-navigation > .m-back-button,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-navigation > .m-back-button,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-navigation > .m-back-button,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-navigation > .m-back-button,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-navigation > .m-back-button,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-navigation > .m-back-button {
  left: 15px;
  top: 50%;
  width: 9px;
  height: 15px;
  width: 18px;
  height: 31px;
  background: url("../../svgs/icon-tile-arrow-dark.svg") no-repeat;
  background-size: 100%;
  background-size: 9px 15px;
  transform: translate(0, -24px) rotate(180deg);
  position: absolute;
  cursor: pointer;
  display: none;
  opacity: 0.8;
  transition: all 0.3s ease-in-out;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-navigation > .m-back-button:before, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-navigation > .m-back-button:before, #sie-boilerplate .mod-navigation--faq > .m-content > .m-navigation > .m-back-button:before, #sie-boilerplate .mod-navigation--main > .m-content > .m-navigation > .m-back-button:before,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-navigation > .m-back-button:before,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-navigation > .m-back-button:before,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-navigation > .m-back-button:before,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-navigation > .m-back-button:before,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-navigation > .m-back-button:before,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-navigation > .m-back-button:before,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-navigation > .m-back-button:before,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-navigation > .m-back-button:before,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-navigation > .m-back-button:before,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-navigation > .m-back-button:before,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-navigation > .m-back-button:before,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-navigation > .m-back-button:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-navigation > .m-back-button:hover, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-navigation > .m-back-button:hover, #sie-boilerplate .mod-navigation--faq > .m-content > .m-navigation > .m-back-button:hover, #sie-boilerplate .mod-navigation--main > .m-content > .m-navigation > .m-back-button:hover,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-navigation > .m-back-button:hover,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-navigation > .m-back-button:hover,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-navigation > .m-back-button:hover,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-navigation > .m-back-button:hover,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-navigation > .m-back-button:hover,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-navigation > .m-back-button:hover,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-navigation > .m-back-button:hover,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-navigation > .m-back-button:hover,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-navigation > .m-back-button:hover,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-navigation > .m-back-button:hover,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-navigation > .m-back-button:hover,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-navigation > .m-back-button:hover {
  opacity: 1;
  transform: translate(0, -24px) rotate(180deg) scale(1.05);
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-navigation > .m-headline, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-navigation > .m-headline, #sie-boilerplate .mod-navigation--faq > .m-content > .m-navigation > .m-headline, #sie-boilerplate .mod-navigation--main > .m-content > .m-navigation > .m-headline,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-navigation > .m-headline,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-navigation > .m-headline,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-navigation > .m-headline,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-navigation > .m-headline,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-navigation > .m-headline,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-navigation > .m-headline,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-navigation > .m-headline,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-navigation > .m-headline,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-navigation > .m-headline,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-navigation > .m-headline,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-navigation > .m-headline,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-navigation > .m-headline {
  padding: 0 30px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Siemens Sans Bold", sans-serif;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-primary-color-3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--settings > .m-content > .m-navigation > .m-headline, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-navigation > .m-headline, #sie-boilerplate .mod-navigation--faq > .m-content > .m-navigation > .m-headline, #sie-boilerplate .mod-navigation--main > .m-content > .m-navigation > .m-headline,
  .mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-navigation > .m-headline,
  .mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-navigation > .m-headline,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-navigation > .m-headline,
  .mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-navigation > .m-headline,
  .cdk-overlay-container .mod-navigation--settings > .m-content > .m-navigation > .m-headline,
  .cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-navigation > .m-headline,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-navigation > .m-headline,
  .cdk-overlay-container .mod-navigation--main > .m-content > .m-navigation > .m-headline,
  .cdk-drag-preview .mod-navigation--settings > .m-content > .m-navigation > .m-headline,
  .cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-navigation > .m-headline,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-navigation > .m-headline,
  .cdk-drag-preview .mod-navigation--main > .m-content > .m-navigation > .m-headline {
    font-family: "Siemens Sans Bold", sans-serif;
    font-size: 23.13px;
    line-height: 30.84px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-primary-color-3);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-navigation > .m-headline.center, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-navigation > .m-headline.center, #sie-boilerplate .mod-navigation--faq > .m-content > .m-navigation > .m-headline.center, #sie-boilerplate .mod-navigation--main > .m-content > .m-navigation > .m-headline.center,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-navigation > .m-headline.center,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-navigation > .m-headline.center,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-navigation > .m-headline.center,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-navigation > .m-headline.center,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-navigation > .m-headline.center,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-navigation > .m-headline.center,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-navigation > .m-headline.center,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-navigation > .m-headline.center,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-navigation > .m-headline.center,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-navigation > .m-headline.center,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-navigation > .m-headline.center,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-navigation > .m-headline.center {
  text-align: center;
  padding-left: 30px;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-bottom-wrapper, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-bottom-wrapper, #sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper, #sie-boilerplate .mod-navigation--main > .m-content > .m-bottom-wrapper,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-bottom-wrapper,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-bottom-wrapper,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-bottom-wrapper,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-bottom-wrapper,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-bottom-wrapper,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-bottom-wrapper,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-bottom-wrapper,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-bottom-wrapper,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-bottom-wrapper {
  width: 520px;
  right: -520px;
  bottom: 0;
  background: var(--ceres-white);
  position: fixed;
  height: auto;
  transition: right 0.5s ease-in-out;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--settings > .m-content > .m-bottom-wrapper, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-bottom-wrapper, #sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper, #sie-boilerplate .mod-navigation--main > .m-content > .m-bottom-wrapper,
  .mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-bottom-wrapper,
  .mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-bottom-wrapper,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper,
  .mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-bottom-wrapper,
  .cdk-overlay-container .mod-navigation--settings > .m-content > .m-bottom-wrapper,
  .cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-bottom-wrapper,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper,
  .cdk-overlay-container .mod-navigation--main > .m-content > .m-bottom-wrapper,
  .cdk-drag-preview .mod-navigation--settings > .m-content > .m-bottom-wrapper,
  .cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-bottom-wrapper,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper,
  .cdk-drag-preview .mod-navigation--main > .m-content > .m-bottom-wrapper {
    width: 668.2px;
    right: -668.2px;
  }
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-bottom-wrapper > .m-bottom--profile, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-bottom-wrapper > .m-bottom--profile, #sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--profile, #sie-boilerplate .mod-navigation--main > .m-content > .m-bottom-wrapper > .m-bottom--profile,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-bottom-wrapper > .m-bottom--profile,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-bottom-wrapper > .m-bottom--profile,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--profile,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-bottom-wrapper > .m-bottom--profile,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-bottom-wrapper > .m-bottom--profile,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-bottom-wrapper > .m-bottom--profile,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--profile,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-bottom-wrapper > .m-bottom--profile,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-bottom-wrapper > .m-bottom--profile,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-bottom-wrapper > .m-bottom--profile,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--profile,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-bottom-wrapper > .m-bottom--profile {
  border-top: 1px solid var(--ceres-secondary-color-17);
  padding: 16px 20px 20px 20px;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user, #sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user, #sie-boilerplate .mod-navigation--main > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user {
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 0;
  padding: 20px 0 17px 20px;
  position: relative;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user:before, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user:before, #sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user:before, #sie-boilerplate .mod-navigation--main > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user:before,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user:before,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user:before,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user:before,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user:before,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user:before,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user:before,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user:before,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user:before,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user:before,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user:before,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user:before,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user:before {
  content: "";
  left: 20px;
  bottom: -1px;
  width: calc(100% - 40px);
  height: 1px;
  background: var(--ceres-primary-color-7);
  position: absolute;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user > .m-image, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user > .m-image, #sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user > .m-image, #sie-boilerplate .mod-navigation--main > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user > .m-image,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user > .m-image,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user > .m-image,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user > .m-image,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user > .m-image,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user > .m-image,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user > .m-image,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user > .m-image,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user > .m-image,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user > .m-image,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user > .m-image,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user > .m-image,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user > .m-image {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  border: 1px solid var(--ceres-secondary-color-17);
  margin: 0 auto 16px auto;
  opacity: 0.7;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info, #sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info, #sie-boilerplate .mod-navigation--main > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info {
  font-family: "Siemens Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-primary-color-3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto 3px auto;
  display: block;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--settings > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info, #sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info, #sie-boilerplate .mod-navigation--main > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info,
  .mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info,
  .mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info,
  .mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info,
  .cdk-overlay-container .mod-navigation--settings > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info,
  .cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info,
  .cdk-overlay-container .mod-navigation--main > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info,
  .cdk-drag-preview .mod-navigation--settings > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info,
  .cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info,
  .cdk-drag-preview .mod-navigation--main > .m-content > .m-bottom-wrapper > .m-bottom--profile .m-user .m-info {
    font-family: "Siemens Sans", sans-serif;
    font-size: 17.99px;
    line-height: 25.7px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-primary-color-3);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-bottom-wrapper.hide, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-bottom-wrapper.hide, #sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper.hide, #sie-boilerplate .mod-navigation--main > .m-content > .m-bottom-wrapper.hide,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-bottom-wrapper.hide,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-bottom-wrapper.hide,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper.hide,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-bottom-wrapper.hide,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-bottom-wrapper.hide,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-bottom-wrapper.hide,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper.hide,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-bottom-wrapper.hide,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-bottom-wrapper.hide,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-bottom-wrapper.hide,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper.hide,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-bottom-wrapper.hide {
  display: none;
}
#sie-boilerplate .mod-navigation--settings--prelogin > .m-content > .m-layer, #sie-boilerplate .mod-navigation--document-repository--prelogin > .m-content > .m-layer, #sie-boilerplate .mod-navigation--faq--prelogin > .m-content > .m-layer, #sie-boilerplate .mod-navigation--main--prelogin > .m-content > .m-layer,
.mat-mdc-dialog-container .mod-navigation--settings--prelogin > .m-content > .m-layer,
.mat-mdc-dialog-container .mod-navigation--document-repository--prelogin > .m-content > .m-layer,
.mat-mdc-dialog-container .mod-navigation--faq--prelogin > .m-content > .m-layer,
.mat-mdc-dialog-container .mod-navigation--main--prelogin > .m-content > .m-layer,
.cdk-overlay-container .mod-navigation--settings--prelogin > .m-content > .m-layer,
.cdk-overlay-container .mod-navigation--document-repository--prelogin > .m-content > .m-layer,
.cdk-overlay-container .mod-navigation--faq--prelogin > .m-content > .m-layer,
.cdk-overlay-container .mod-navigation--main--prelogin > .m-content > .m-layer,
.cdk-drag-preview .mod-navigation--settings--prelogin > .m-content > .m-layer,
.cdk-drag-preview .mod-navigation--document-repository--prelogin > .m-content > .m-layer,
.cdk-drag-preview .mod-navigation--faq--prelogin > .m-content > .m-layer,
.cdk-drag-preview .mod-navigation--main--prelogin > .m-content > .m-layer {
  padding-top: 35px;
}
#sie-boilerplate .mod-navigation--settings.show:before, #sie-boilerplate .mod-navigation--document-repository.show:before, #sie-boilerplate .mod-navigation--faq.show:before, #sie-boilerplate .mod-navigation--main.show:before,
.mat-mdc-dialog-container .mod-navigation--settings.show:before,
.mat-mdc-dialog-container .mod-navigation--document-repository.show:before,
.mat-mdc-dialog-container .mod-navigation--faq.show:before,
.mat-mdc-dialog-container .mod-navigation--main.show:before,
.cdk-overlay-container .mod-navigation--settings.show:before,
.cdk-overlay-container .mod-navigation--document-repository.show:before,
.cdk-overlay-container .mod-navigation--faq.show:before,
.cdk-overlay-container .mod-navigation--main.show:before,
.cdk-drag-preview .mod-navigation--settings.show:before,
.cdk-drag-preview .mod-navigation--document-repository.show:before,
.cdk-drag-preview .mod-navigation--faq.show:before,
.cdk-drag-preview .mod-navigation--main.show:before {
  background: var(--ceres-black-opaque-6);
  right: 0;
  transition: background 0.5s ease-in-out, right 0s ease-in-out;
  transition-delay: 0s, 0s;
}
#sie-boilerplate .mod-navigation--settings.show > .m-content, #sie-boilerplate .mod-navigation--document-repository.show > .m-content, #sie-boilerplate .mod-navigation--faq.show > .m-content, #sie-boilerplate .mod-navigation--main.show > .m-content,
.mat-mdc-dialog-container .mod-navigation--settings.show > .m-content,
.mat-mdc-dialog-container .mod-navigation--document-repository.show > .m-content,
.mat-mdc-dialog-container .mod-navigation--faq.show > .m-content,
.mat-mdc-dialog-container .mod-navigation--main.show > .m-content,
.cdk-overlay-container .mod-navigation--settings.show > .m-content,
.cdk-overlay-container .mod-navigation--document-repository.show > .m-content,
.cdk-overlay-container .mod-navigation--faq.show > .m-content,
.cdk-overlay-container .mod-navigation--main.show > .m-content,
.cdk-drag-preview .mod-navigation--settings.show > .m-content,
.cdk-drag-preview .mod-navigation--document-repository.show > .m-content,
.cdk-drag-preview .mod-navigation--faq.show > .m-content,
.cdk-drag-preview .mod-navigation--main.show > .m-content {
  right: 0;
}
#sie-boilerplate .mod-navigation--settings.show > .m-content > .m-bottom-wrapper, #sie-boilerplate .mod-navigation--document-repository.show > .m-content > .m-bottom-wrapper, #sie-boilerplate .mod-navigation--faq.show > .m-content > .m-bottom-wrapper, #sie-boilerplate .mod-navigation--main.show > .m-content > .m-bottom-wrapper,
.mat-mdc-dialog-container .mod-navigation--settings.show > .m-content > .m-bottom-wrapper,
.mat-mdc-dialog-container .mod-navigation--document-repository.show > .m-content > .m-bottom-wrapper,
.mat-mdc-dialog-container .mod-navigation--faq.show > .m-content > .m-bottom-wrapper,
.mat-mdc-dialog-container .mod-navigation--main.show > .m-content > .m-bottom-wrapper,
.cdk-overlay-container .mod-navigation--settings.show > .m-content > .m-bottom-wrapper,
.cdk-overlay-container .mod-navigation--document-repository.show > .m-content > .m-bottom-wrapper,
.cdk-overlay-container .mod-navigation--faq.show > .m-content > .m-bottom-wrapper,
.cdk-overlay-container .mod-navigation--main.show > .m-content > .m-bottom-wrapper,
.cdk-drag-preview .mod-navigation--settings.show > .m-content > .m-bottom-wrapper,
.cdk-drag-preview .mod-navigation--document-repository.show > .m-content > .m-bottom-wrapper,
.cdk-drag-preview .mod-navigation--faq.show > .m-content > .m-bottom-wrapper,
.cdk-drag-preview .mod-navigation--main.show > .m-content > .m-bottom-wrapper {
  right: 0;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer {
  font-family: "Siemens Sans Bold", sans-serif;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-primary-color-3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
  width: 520px;
  right: 0;
  top: 90px;
  background: var(--ceres-white);
  position: absolute;
  transition: right 0.5s ease-in-out;
  overflow-x: hidden;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--settings > .m-content > .m-layer, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer,
  .mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer,
  .mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer,
  .mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer,
  .cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer,
  .cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer,
  .cdk-overlay-container .mod-navigation--main > .m-content > .m-layer,
  .cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer,
  .cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer,
  .cdk-drag-preview .mod-navigation--main > .m-content > .m-layer {
    font-family: "Siemens Sans Bold", sans-serif;
    font-size: 23.13px;
    line-height: 30.84px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-primary-color-3);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--settings > .m-content > .m-layer, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer,
  .mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer,
  .mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer,
  .mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer,
  .cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer,
  .cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer,
  .cdk-overlay-container .mod-navigation--main > .m-content > .m-layer,
  .cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer,
  .cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer,
  .cdk-drag-preview .mod-navigation--main > .m-content > .m-layer {
    width: 668.2px;
  }
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > .m-form, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > .m-form, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > .m-form,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > .m-form,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > .m-form,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > .m-form,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > .m-form,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > .m-form,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > .m-form,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > .m-form,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > .m-form,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > .m-form {
  padding: 20px;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper {
  margin: 0 0 28px 0;
  width: 100%;
  position: relative;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper label, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper label, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper label,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper label,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper label,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper label,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper label,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper label,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper label,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper label,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper label,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper label {
  font-family: "Siemens Sans", sans-serif;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-primary-color-3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  position: relative;
  color: var(--ceres-secondary-color-16);
  display: block;
  margin: 0 0 15px 4px;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper label, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper label, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper label,
  .mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper label,
  .mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper label,
  .mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper label,
  .cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper label,
  .cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper label,
  .cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper label,
  .cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper label,
  .cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper label,
  .cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper label {
    font-family: "Siemens Sans", sans-serif;
    font-size: 23.13px;
    line-height: 30.84px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-primary-color-3);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper select::-ms-expand, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper select::-ms-expand, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper select::-ms-expand,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper select::-ms-expand,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper select::-ms-expand,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper select::-ms-expand,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper select::-ms-expand,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper select::-ms-expand,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper select::-ms-expand,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper select::-ms-expand,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper select::-ms-expand,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper select::-ms-expand {
  display: none;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper select, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper select, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper select,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper select,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper select,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper select,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper select,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper select,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper select,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper select,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper select,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper select {
  width: 100%;
  height: 42px;
  background: transparent;
  outline: none;
  border: 1px solid var(--ceres-primary-color-7);
  display: block;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  padding: 2px 12px 0 12px;
  font-family: "Siemens Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-primary-color-3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper select, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper select, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper select,
  .mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper select,
  .mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper select,
  .mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper select,
  .cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper select,
  .cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper select,
  .cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper select,
  .cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper select,
  .cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper select,
  .cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper select {
    font-family: "Siemens Sans", sans-serif;
    font-size: 17.99px;
    line-height: 25.7px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-primary-color-3);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper select:hover, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper select:hover, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper select:hover,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper select:hover,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper select:hover,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper select:hover,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper select:hover,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper select:hover,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper select:hover,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper select:hover,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper select:hover,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper select:hover {
  background: var(--ceres-black-opaque-1);
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper select:hover:after, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper select:hover:after, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper select:hover:after,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper select:hover:after,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper select:hover:after,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper select:hover:after,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper select:hover:after,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper select:hover:after,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper select:hover:after,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper select:hover:after,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper select:hover:after,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper select:hover:after {
  opacity: 1;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper select:focus, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper select:focus, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper select:focus,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper select:focus,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper select:focus,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper select:focus,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper select:focus,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper select:focus,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper select:focus,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > .m-form form .m-select-wrapper select:focus,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > .m-form form .m-select-wrapper select:focus,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > .m-form form .m-select-wrapper select:focus {
  -webkit-tap-highlight-color: transparent;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul {
  counter-reset: counter-first-layer;
  transform: translate(0, 0);
  transition: transform 0.5s ease-in-out;
  width: 520px;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul,
  .mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul,
  .mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul,
  .mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul,
  .cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul,
  .cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul,
  .cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul,
  .cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul,
  .cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul,
  .cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul {
    width: 668.2px;
  }
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li {
  width: 100%;
  font-family: "Siemens Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-secondary-color-16);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.15s ease-in-out;
  cursor: pointer;
  position: relative;
  padding: 0 20px;
  width: 520px;
}
@media print, only screen and (min-width: 1024px) {
  #sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li,
  .mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li,
  .mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li,
  .mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li,
  .cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li,
  .cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li,
  .cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li,
  .cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li,
  .cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li,
  .cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li {
    font-size: 18px;
    line-height: 24px;
  }
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li,
  .mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li,
  .mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li,
  .mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li,
  .cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li,
  .cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li,
  .cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li,
  .cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li,
  .cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li,
  .cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li {
    font-family: "Siemens Sans", sans-serif;
    font-size: 17.99px;
    line-height: 25.7px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-secondary-color-16);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li a,
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li label, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li a,
#sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li label, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li a,
#sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li label,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li a,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li label,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li a,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li label,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li a,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li label,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li a,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li label,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li a,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li label,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li a,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li label,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li a,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li label,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li a,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li label,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li a,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li label {
  font-family: "Siemens Sans", sans-serif;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-secondary-color-16);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li a,
  #sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li label, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li a,
  #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li label, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li a,
  #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li label,
  .mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li a,
  .mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li label,
  .mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li a,
  .mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li label,
  .mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li a,
  .mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li label,
  .cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li a,
  .cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li label,
  .cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li a,
  .cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li label,
  .cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li a,
  .cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li label,
  .cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li a,
  .cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li label,
  .cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li a,
  .cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li label,
  .cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li a,
  .cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li label {
    font-family: "Siemens Sans", sans-serif;
    font-size: 23.13px;
    line-height: 30.84px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-secondary-color-16);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li > a, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li > a,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li > a,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li > a,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li > a,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li > a,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li > a,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li > a {
  width: 100%;
  padding: 20px 50px 17px 0;
  display: block;
  transition: all 0.15s ease-in-out;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li input[type=checkbox] + label:before, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li input[type=checkbox] + label:before, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li input[type=checkbox] + label:before,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li input[type=checkbox] + label:before,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li input[type=checkbox] + label:before,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li input[type=checkbox] + label:before,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li input[type=checkbox] + label:before,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li input[type=checkbox] + label:before,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li input[type=checkbox] + label:before,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li input[type=checkbox] + label:before,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li input[type=checkbox] + label:before,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li input[type=checkbox] + label:before {
  border: 1px solid var(--ceres-secondary-color-16) !important;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li input[type=checkbox] + label:after, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li input[type=checkbox] + label:after, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li input[type=checkbox] + label:after,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li input[type=checkbox] + label:after,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li input[type=checkbox] + label:after,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li input[type=checkbox] + label:after,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li input[type=checkbox] + label:after,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li input[type=checkbox] + label:after,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li input[type=checkbox] + label:after,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li input[type=checkbox] + label:after,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li input[type=checkbox] + label:after,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li input[type=checkbox] + label:after {
  width: 15px;
  height: 12px;
  width: 15px;
  height: 12px;
  background: url("../../svgs/icon-checkbox-check.svg") no-repeat;
  background-size: 100%;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li input[type=checkbox] + label:after:before, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li input[type=checkbox] + label:after:before, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li input[type=checkbox] + label:after:before,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li input[type=checkbox] + label:after:before,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li input[type=checkbox] + label:after:before,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li input[type=checkbox] + label:after:before,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li input[type=checkbox] + label:after:before,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li input[type=checkbox] + label:after:before,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li input[type=checkbox] + label:after:before,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li input[type=checkbox] + label:after:before,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li input[type=checkbox] + label:after:before,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li input[type=checkbox] + label:after:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li:before, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li:before, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li:before,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li:before,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:before,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li:before,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li:before,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:before,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li:before,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li:before,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li:before,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li:before {
  content: "";
  left: 20px;
  bottom: -1px;
  width: calc(100% - 40px);
  height: 1px;
  background: var(--ceres-primary-color-7);
  position: absolute;
  pointer-events: none;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li:after, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li:after, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li:after,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li:after,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:after,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li:after,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li:after,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:after,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li:after,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li:after,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li:after,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li:after {
  content: "";
  top: 50%;
  right: 19px;
  width: 9px;
  height: 15px;
  width: 18px;
  height: 31px;
  background: url("../../svgs/icon-tile-arrow-dark.svg") no-repeat;
  background-size: 100%;
  opacity: 0.2;
  background-size: 9px 15px;
  transform: translate(0, -8px);
  position: absolute;
  transition: opacity 0.15s ease-in-out;
  pointer-events: none;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li:after:before, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li:after:before, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li:after:before,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li:after:before,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:after:before,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li:after:before,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li:after:before,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:after:before,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li:after:before,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li:after:before,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li:after:before,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li:after:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li:hover, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li:hover,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li:hover,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li:hover,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li:hover,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li:hover,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li:hover,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li:hover {
  background: var(--ceres-secondary-color-5);
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li:hover:before, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover:before, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li:hover:before,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li:hover:before,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover:before,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li:hover:before,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li:hover:before,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover:before,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li:hover:before,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li:hover:before,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover:before,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li:hover:before {
  content: none;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li:hover:after, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover:after, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li:hover:after,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li:hover:after,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover:after,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li:hover:after,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li:hover:after,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover:after,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li:hover:after,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li:hover:after,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover:after,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li:hover:after {
  opacity: 1;
  width: 18px;
  height: 31px;
  background: url("../../svgs/icon-tile-arrow-light.svg") no-repeat;
  background-size: 100%;
  background-size: 9px 15px;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li:hover:after:before, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover:after:before, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li:hover:after:before,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li:hover:after:before,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover:after:before,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li:hover:after:before,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li:hover:after:before,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover:after:before,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li:hover:after:before,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li:hover:after:before,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover:after:before,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li:hover:after:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li:hover a,
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li:hover label, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover a,
#sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover label, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li:hover a,
#sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li:hover label,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li:hover a,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li:hover label,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover a,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover label,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li:hover a,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li:hover label,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li:hover a,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li:hover label,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover a,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover label,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li:hover a,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li:hover label,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li:hover a,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li:hover label,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover a,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover label,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li:hover a,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li:hover label {
  font-family: "Siemens Sans", sans-serif;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li:hover a,
  #sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li:hover label, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover a,
  #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover label, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li:hover a,
  #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li:hover label,
  .mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li:hover a,
  .mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li:hover label,
  .mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover a,
  .mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover label,
  .mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li:hover a,
  .mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li:hover label,
  .cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li:hover a,
  .cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li:hover label,
  .cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover a,
  .cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover label,
  .cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li:hover a,
  .cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li:hover label,
  .cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li:hover a,
  .cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li:hover label,
  .cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover a,
  .cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover label,
  .cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li:hover a,
  .cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li:hover label {
    font-family: "Siemens Sans", sans-serif;
    font-size: 23.13px;
    line-height: 30.84px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-white);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:before, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:before, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:before,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:before,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:before,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:before,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:before,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:before,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:before,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:before,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:before,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:before {
  border: 1px solid var(--ceres-white) !important;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:after, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:after, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:after,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:after,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:after,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:after,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:after,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:after,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:after,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:after,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:after,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:after {
  width: 15px;
  height: 12px;
  width: 15px;
  height: 12px;
  background: url("../../svgs/icon-checkbox-check-white.svg") no-repeat;
  background-size: 100%;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:after:before, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:after:before, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:after:before,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:after:before,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:after:before,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:after:before,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:after:before,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:after:before,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:after:before,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:after:before,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:after:before,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li:hover input[type=checkbox] + label:after:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li,
  .mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li,
  .mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li,
  .mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li,
  .cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li,
  .cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li,
  .cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li,
  .cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li,
  .cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li,
  .cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li {
    width: 668.2px;
  }
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li > a, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li > a,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li > a,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li > a,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li > a,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li > a,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li > a,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li > a {
  padding-left: 32px;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li > a, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li > a,
  .mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li > a,
  .mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a,
  .mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li > a,
  .cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li > a,
  .cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a,
  .cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li > a,
  .cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li > a,
  .cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a,
  .cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li > a {
    padding-left: 44px;
  }
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li > a:before, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a:before, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li > a:before,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li > a:before,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a:before,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li > a:before,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li > a:before,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a:before,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li > a:before,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li > a:before,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a:before,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li > a:before {
  margin: 0 0 0 -32px;
  content: counter(counter-first-layer) ".";
  counter-increment: counter-first-layer;
  position: absolute;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li > a:before, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a:before, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li > a:before,
  .mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li > a:before,
  .mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a:before,
  .mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li > a:before,
  .cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li > a:before,
  .cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a:before,
  .cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li > a:before,
  .cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li > a:before,
  .cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a:before,
  .cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li > a:before {
    margin-left: -44px;
  }
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li > a > .m-desc, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a > .m-desc, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li > a > .m-desc,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li > a > .m-desc,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a > .m-desc,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li > a > .m-desc,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li > a > .m-desc,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a > .m-desc,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li > a > .m-desc,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li > a > .m-desc,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a > .m-desc,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li > a > .m-desc {
  width: auto;
  position: relative;
}
#sie-boilerplate .mod-navigation--settings > .m-content > .m-layer > ul > li > a > .m-desc > .m-notification, #sie-boilerplate .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a > .m-desc > .m-notification, #sie-boilerplate .mod-navigation--main > .m-content > .m-layer > ul > li > a > .m-desc > .m-notification,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content > .m-layer > ul > li > a > .m-desc > .m-notification,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a > .m-desc > .m-notification,
.mat-mdc-dialog-container .mod-navigation--main > .m-content > .m-layer > ul > li > a > .m-desc > .m-notification,
.cdk-overlay-container .mod-navigation--settings > .m-content > .m-layer > ul > li > a > .m-desc > .m-notification,
.cdk-overlay-container .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a > .m-desc > .m-notification,
.cdk-overlay-container .mod-navigation--main > .m-content > .m-layer > ul > li > a > .m-desc > .m-notification,
.cdk-drag-preview .mod-navigation--settings > .m-content > .m-layer > ul > li > a > .m-desc > .m-notification,
.cdk-drag-preview .mod-navigation--document-repository > .m-content > .m-layer > ul > li > a > .m-desc > .m-notification,
.cdk-drag-preview .mod-navigation--main > .m-content > .m-layer > ul > li > a > .m-desc > .m-notification {
  right: 0;
  top: 0;
  min-width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 2px solid var(--ceres-white);
  background: var(--ceres-feedback-orange);
  position: absolute;
  font-family: "Siemens Sans Bold", sans-serif;
  font-size: 11px;
  line-height: 1;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(22px, -7px);
}
#sie-boilerplate .mod-navigation--settings > .m-content.show-second-layer, #sie-boilerplate .mod-navigation--document-repository > .m-content.show-second-layer, #sie-boilerplate .mod-navigation--main > .m-content.show-second-layer,
.mat-mdc-dialog-container .mod-navigation--settings > .m-content.show-second-layer,
.mat-mdc-dialog-container .mod-navigation--document-repository > .m-content.show-second-layer,
.mat-mdc-dialog-container .mod-navigation--main > .m-content.show-second-layer,
.cdk-overlay-container .mod-navigation--settings > .m-content.show-second-layer,
.cdk-overlay-container .mod-navigation--document-repository > .m-content.show-second-layer,
.cdk-overlay-container .mod-navigation--main > .m-content.show-second-layer,
.cdk-drag-preview .mod-navigation--settings > .m-content.show-second-layer,
.cdk-drag-preview .mod-navigation--document-repository > .m-content.show-second-layer,
.cdk-drag-preview .mod-navigation--main > .m-content.show-second-layer {
  right: 520px;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--settings > .m-content.show-second-layer, #sie-boilerplate .mod-navigation--document-repository > .m-content.show-second-layer, #sie-boilerplate .mod-navigation--main > .m-content.show-second-layer,
  .mat-mdc-dialog-container .mod-navigation--settings > .m-content.show-second-layer,
  .mat-mdc-dialog-container .mod-navigation--document-repository > .m-content.show-second-layer,
  .mat-mdc-dialog-container .mod-navigation--main > .m-content.show-second-layer,
  .cdk-overlay-container .mod-navigation--settings > .m-content.show-second-layer,
  .cdk-overlay-container .mod-navigation--document-repository > .m-content.show-second-layer,
  .cdk-overlay-container .mod-navigation--main > .m-content.show-second-layer,
  .cdk-drag-preview .mod-navigation--settings > .m-content.show-second-layer,
  .cdk-drag-preview .mod-navigation--document-repository > .m-content.show-second-layer,
  .cdk-drag-preview .mod-navigation--main > .m-content.show-second-layer {
    right: 668.2px;
  }
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer {
  right: 0;
  height: 100%;
  position: relative;
  transition: all 0.5s ease-in-out;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer {
  font-family: "Siemens Sans Bold", sans-serif;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-primary-color-3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
  width: 520px;
  right: 0;
  top: 90px;
  background: var(--ceres-white);
  position: absolute;
  z-index: 2;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer {
    font-family: "Siemens Sans Bold", sans-serif;
    font-size: 23.13px;
    line-height: 30.84px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-primary-color-3);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer {
    width: 668.2px;
  }
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul {
  counter-reset: counter-first-layer;
  transform: translate(0, 0);
  transition: transform 0.5s ease-in-out;
  width: 520px;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul {
    width: 668.2px;
  }
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li {
  width: 100%;
  font-family: "Siemens Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-secondary-color-16);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.15s ease-in-out;
  cursor: pointer;
  position: relative;
  padding: 0 20px;
  width: 520px;
}
@media print, only screen and (min-width: 1024px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li {
    font-size: 18px;
    line-height: 24px;
  }
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li {
    font-family: "Siemens Sans", sans-serif;
    font-size: 17.99px;
    line-height: 25.7px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-secondary-color-16);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li a,
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li label,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li a,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li label,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li a,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li label,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li a,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li label {
  font-family: "Siemens Sans", sans-serif;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-secondary-color-16);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li a,
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li label,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li a,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li label,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li a,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li label,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li a,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li label {
    font-family: "Siemens Sans", sans-serif;
    font-size: 23.13px;
    line-height: 30.84px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-secondary-color-16);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li > a,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li > a,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li > a,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li > a {
  width: 100%;
  padding: 20px 50px 17px 0;
  display: block;
  transition: all 0.15s ease-in-out;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li input[type=checkbox] + label:before,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li input[type=checkbox] + label:before,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li input[type=checkbox] + label:before,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li input[type=checkbox] + label:before {
  border: 1px solid var(--ceres-secondary-color-16) !important;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li input[type=checkbox] + label:after,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li input[type=checkbox] + label:after,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li input[type=checkbox] + label:after,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li input[type=checkbox] + label:after {
  width: 15px;
  height: 12px;
  width: 15px;
  height: 12px;
  background: url("../../svgs/icon-checkbox-check.svg") no-repeat;
  background-size: 100%;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li input[type=checkbox] + label:after:before,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li input[type=checkbox] + label:after:before,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li input[type=checkbox] + label:after:before,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li input[type=checkbox] + label:after:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:before,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:before,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:before,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:before {
  content: "";
  left: 20px;
  bottom: -1px;
  width: calc(100% - 40px);
  height: 1px;
  background: var(--ceres-primary-color-7);
  position: absolute;
  pointer-events: none;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:after,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:after,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:after,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:after {
  content: "";
  top: 50%;
  right: 19px;
  width: 9px;
  height: 15px;
  width: 18px;
  height: 31px;
  background: url("../../svgs/icon-tile-arrow-dark.svg") no-repeat;
  background-size: 100%;
  opacity: 0.2;
  background-size: 9px 15px;
  transform: translate(0, -8px);
  position: absolute;
  transition: opacity 0.15s ease-in-out;
  pointer-events: none;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:after:before,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:after:before,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:after:before,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:after:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover {
  background: var(--ceres-secondary-color-5);
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover:before,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover:before,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover:before,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover:before {
  content: none;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover:after,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover:after,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover:after,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover:after {
  opacity: 1;
  width: 18px;
  height: 31px;
  background: url("../../svgs/icon-tile-arrow-light.svg") no-repeat;
  background-size: 100%;
  background-size: 9px 15px;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover:after:before,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover:after:before,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover:after:before,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover:after:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover a,
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover label,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover a,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover label,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover a,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover label,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover a,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover label {
  font-family: "Siemens Sans", sans-serif;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover a,
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover label,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover a,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover label,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover a,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover label,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover a,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover label {
    font-family: "Siemens Sans", sans-serif;
    font-size: 23.13px;
    line-height: 30.84px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-white);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover input[type=checkbox] + label:before,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover input[type=checkbox] + label:before,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover input[type=checkbox] + label:before,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover input[type=checkbox] + label:before {
  border: 1px solid var(--ceres-white) !important;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover input[type=checkbox] + label:after,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover input[type=checkbox] + label:after,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover input[type=checkbox] + label:after,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover input[type=checkbox] + label:after {
  width: 15px;
  height: 12px;
  width: 15px;
  height: 12px;
  background: url("../../svgs/icon-checkbox-check-white.svg") no-repeat;
  background-size: 100%;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover input[type=checkbox] + label:after:before,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover input[type=checkbox] + label:after:before,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover input[type=checkbox] + label:after:before,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li:hover input[type=checkbox] + label:after:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li {
    width: 668.2px;
  }
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li > a,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li > a,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li > a,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li > a {
  padding-left: 32px;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li > a,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li > a,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li > a,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li > a {
    padding-left: 44px;
  }
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li > a:before,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li > a:before,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li > a:before,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li > a:before {
  margin: 0 0 0 -32px;
  content: counter(counter-first-layer) ".";
  counter-increment: counter-first-layer;
  position: absolute;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li > a:before,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li > a:before,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li > a:before,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-topic-layer > ul > li > a:before {
    margin-left: -44px;
  }
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content {
  font-family: "Siemens Sans Bold", sans-serif;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-primary-color-3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
  width: 520px;
  top: 90px;
  right: -520px;
  background: var(--ceres-white);
  position: absolute;
  z-index: 1;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content {
    font-family: "Siemens Sans Bold", sans-serif;
    font-size: 23.13px;
    line-height: 30.84px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-primary-color-3);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content {
    width: 668.2px;
    right: -668.2px;
  }
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul {
  position: relative;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li {
  width: 100%;
  font-family: "Siemens Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-secondary-color-16);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.15s ease-in-out;
  cursor: pointer;
  position: relative;
  padding: 0 20px;
}
@media print, only screen and (min-width: 1024px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li {
    font-size: 18px;
    line-height: 24px;
  }
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li {
    font-family: "Siemens Sans", sans-serif;
    font-size: 17.99px;
    line-height: 25.7px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-secondary-color-16);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li a,
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li label,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li a,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li label,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li a,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li label,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li a,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li label {
  font-family: "Siemens Sans", sans-serif;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-secondary-color-16);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li a,
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li label,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li a,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li label,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li a,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li label,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li a,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li label {
    font-family: "Siemens Sans", sans-serif;
    font-size: 23.13px;
    line-height: 30.84px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-secondary-color-16);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li > a,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li > a,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li > a,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li > a {
  width: 100%;
  padding: 20px 50px 17px 0;
  display: block;
  transition: all 0.15s ease-in-out;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li input[type=checkbox] + label:before,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li input[type=checkbox] + label:before,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li input[type=checkbox] + label:before,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li input[type=checkbox] + label:before {
  border: 1px solid var(--ceres-secondary-color-16) !important;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li input[type=checkbox] + label:after,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li input[type=checkbox] + label:after,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li input[type=checkbox] + label:after,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li input[type=checkbox] + label:after {
  width: 15px;
  height: 12px;
  width: 15px;
  height: 12px;
  background: url("../../svgs/icon-checkbox-check.svg") no-repeat;
  background-size: 100%;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li input[type=checkbox] + label:after:before,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li input[type=checkbox] + label:after:before,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li input[type=checkbox] + label:after:before,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li input[type=checkbox] + label:after:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:before,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:before,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:before,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:before {
  content: "";
  left: 20px;
  bottom: -1px;
  width: calc(100% - 40px);
  height: 1px;
  background: var(--ceres-primary-color-7);
  position: absolute;
  pointer-events: none;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:after,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:after,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:after,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:after {
  content: "";
  top: 50%;
  right: 19px;
  width: 9px;
  height: 15px;
  width: 18px;
  height: 31px;
  background: url("../../svgs/icon-tile-arrow-dark.svg") no-repeat;
  background-size: 100%;
  opacity: 0.2;
  background-size: 9px 15px;
  transform: translate(0, -8px);
  position: absolute;
  transition: opacity 0.15s ease-in-out;
  pointer-events: none;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:after:before,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:after:before,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:after:before,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:after:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover {
  background: var(--ceres-secondary-color-5);
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover:before,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover:before,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover:before,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover:before {
  content: none;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover:after,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover:after,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover:after,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover:after {
  opacity: 1;
  width: 18px;
  height: 31px;
  background: url("../../svgs/icon-tile-arrow-light.svg") no-repeat;
  background-size: 100%;
  background-size: 9px 15px;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover:after:before,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover:after:before,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover:after:before,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover:after:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover a,
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover label,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover a,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover label,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover a,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover label,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover a,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover label {
  font-family: "Siemens Sans", sans-serif;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover a,
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover label,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover a,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover label,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover a,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover label,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover a,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover label {
    font-family: "Siemens Sans", sans-serif;
    font-size: 23.13px;
    line-height: 30.84px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-white);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover input[type=checkbox] + label:before,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover input[type=checkbox] + label:before,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover input[type=checkbox] + label:before,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover input[type=checkbox] + label:before {
  border: 1px solid var(--ceres-white) !important;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover input[type=checkbox] + label:after,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover input[type=checkbox] + label:after,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover input[type=checkbox] + label:after,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover input[type=checkbox] + label:after {
  width: 15px;
  height: 12px;
  width: 15px;
  height: 12px;
  background: url("../../svgs/icon-checkbox-check-white.svg") no-repeat;
  background-size: 100%;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover input[type=checkbox] + label:after:before,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover input[type=checkbox] + label:after:before,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover input[type=checkbox] + label:after:before,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-question-layer > .m-items > .m-content > ul > li:hover input[type=checkbox] + label:after:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content {
  font-family: "Siemens Sans Bold", sans-serif;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-primary-color-3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
  width: 520px;
  top: 90px;
  right: -1040px;
  padding: 20px 20px 40px 20px;
  background: var(--ceres-white);
  position: absolute;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content {
    font-family: "Siemens Sans Bold", sans-serif;
    font-size: 23.13px;
    line-height: 30.84px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-primary-color-3);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content {
    width: 668.2px;
    right: -1336.4px;
  }
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content > h4, #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content > .h4,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content > h4,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content > .h4,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content > h4,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content > .h4,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content > h4,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content > .h4 {
  font-family: "Siemens Sans", sans-serif;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-secondary-color-16);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--ceres-primary-color-3);
  margin: 0 0 10px 0;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content > h4, #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content > .h4,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content > h4,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content > .h4,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content > h4,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content > .h4,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content > h4,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content > .h4 {
    font-family: "Siemens Sans", sans-serif;
    font-size: 23.13px;
    line-height: 30.84px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-secondary-color-16);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content > p,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content > p,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content > p,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content > p {
  font-family: "Siemens Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-secondary-color-16);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content > p,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content > p,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content > p,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer > .m-answer-layer > .m-items > .m-items > .m-content > p {
    font-family: "Siemens Sans", sans-serif;
    font-size: 17.99px;
    line-height: 25.7px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-secondary-color-16);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer.show-second-layer,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer.show-second-layer,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer.show-second-layer,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer.show-second-layer {
  right: 520px;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer.show-second-layer,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer.show-second-layer,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer.show-second-layer,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer.show-second-layer {
    right: 668.2px;
  }
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-layer.show-answer-layer,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer.show-answer-layer,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer.show-answer-layer,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer.show-answer-layer {
  right: 1040px;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-layer.show-answer-layer,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-layer.show-answer-layer,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-layer.show-answer-layer,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-layer.show-answer-layer {
    right: 1336.4px;
  }
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq {
  padding: 20px;
  border-top: 1px solid var(--ceres-secondary-color-17);
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-equate,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-equate,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-equate,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-equate {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response {
  display: none;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response > span,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response > span,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response > span,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response > span {
  font-family: "Siemens Sans Bold", sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-primary-color-3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--ceres-primary-color-3);
  display: block;
  margin: 0 0 12px 0;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response > span,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response > span,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response > span,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response > span {
    font-family: "Siemens Sans Bold", sans-serif;
    font-size: 17.99px;
    line-height: 25.7px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-primary-color-3);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response > .m-button-wrapper,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response > .m-button-wrapper,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response > .m-button-wrapper,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response > .m-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response > .m-button-wrapper button,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response > .m-button-wrapper button,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response > .m-button-wrapper button,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response > .m-button-wrapper button {
  width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response > .m-button-wrapper button:first-of-type,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response > .m-button-wrapper button:first-of-type,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response > .m-button-wrapper button:first-of-type,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response > .m-button-wrapper button:first-of-type {
  margin-right: 10px;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response > .m-button-wrapper button:last-of-type,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response > .m-button-wrapper button:last-of-type,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response > .m-button-wrapper button:last-of-type,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-response > .m-button-wrapper button:last-of-type {
  margin-left: 10px;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form {
  display: none;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > span,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > span,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > span,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > span {
  font-family: "Siemens Sans Bold", sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-primary-color-3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--ceres-primary-color-3);
  display: block;
  margin: 0 0 14px 0;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > span,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > span,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > span,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > span {
    font-family: "Siemens Sans Bold", sans-serif;
    font-size: 17.99px;
    line-height: 25.7px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-primary-color-3);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > span span,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > span span,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > span span,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > span span {
  font-family: "Siemens Sans Bold", sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-primary-color-3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--ceres-primary-color-3);
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > span span,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > span span,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > span span,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > span span {
    font-family: "Siemens Sans Bold", sans-serif;
    font-size: 17.99px;
    line-height: 25.7px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-primary-color-3);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-radio-wrapper,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-radio-wrapper,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-radio-wrapper,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-radio-wrapper {
  margin: 0 0 30px 0;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-radio-wrapper:before, #sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-radio-wrapper:after,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-radio-wrapper:before,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-radio-wrapper:after,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-radio-wrapper:before,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-radio-wrapper:after,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-radio-wrapper:before,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-radio-wrapper:after {
  content: " ";
  display: table;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-radio-wrapper:after,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-radio-wrapper:after,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-radio-wrapper:after,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-radio-wrapper:after {
  clear: both;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-radio-wrapper > div,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-radio-wrapper > div,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-radio-wrapper > div,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-radio-wrapper > div {
  float: left;
  margin: 0 20px;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-radio-wrapper > div:first-of-type,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-radio-wrapper > div:first-of-type,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-radio-wrapper > div:first-of-type,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-radio-wrapper > div:first-of-type {
  margin-left: 0;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-text-wrapper,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-text-wrapper,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-text-wrapper,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-text-wrapper {
  margin: 0 0 30px 0;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-text-wrapper:before, #sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-text-wrapper:after,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-text-wrapper:before,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-text-wrapper:after,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-text-wrapper:before,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-text-wrapper:after,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-text-wrapper:before,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-text-wrapper:after {
  content: " ";
  display: table;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-text-wrapper:after,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-text-wrapper:after,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-text-wrapper:after,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-text-wrapper:after {
  clear: both;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-text-wrapper textarea,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-text-wrapper textarea,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-text-wrapper textarea,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-text-wrapper textarea {
  width: 100%;
  min-height: 145px;
  resize: none;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper button,
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper input,
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper a,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper button,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper input,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper a,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper button,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper input,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper a,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper button,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper input,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper a {
  width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper button,
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper input,
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper a,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper button,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper input,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper a,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper button,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper input,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper a,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper button,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper input,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper a {
    width: 270px;
  }
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper button:first-child,
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper input:first-child,
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper a:first-child,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper button:first-child,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper input:first-child,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper a:first-child,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper button:first-child,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper input:first-child,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper a:first-child,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper button:first-child,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper input:first-child,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper a:first-child {
  margin-right: 10px;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper button:last-child,
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper input:last-child,
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper a:last-child,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper button:last-child,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper input:last-child,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper a:last-child,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper button:last-child,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper input:last-child,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper a:last-child,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper button:last-child,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper input:last-child,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-form > form > .m-button-wrapper a:last-child {
  text-align: center;
  margin-left: 10px;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-thanks,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-thanks,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-thanks,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-thanks {
  display: none;
}
#sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-thanks > span,
.mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-thanks > span,
.cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-thanks > span,
.cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-thanks > span {
  font-family: "Siemens Sans Bold", sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-primary-color-3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--ceres-primary-color-3);
  display: block;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-thanks > span,
  .mat-mdc-dialog-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-thanks > span,
  .cdk-overlay-container .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-thanks > span,
  .cdk-drag-preview .mod-navigation--faq > .m-content > .m-bottom-wrapper > .m-bottom--faq .m-thanks > span {
    font-family: "Siemens Sans Bold", sans-serif;
    font-size: 17.99px;
    line-height: 25.7px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-primary-color-3);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .mod-navigation--main,
.mat-mdc-dialog-container .mod-navigation--main,
.cdk-overlay-container .mod-navigation--main,
.cdk-drag-preview .mod-navigation--main {
  height: 100%;
}
#sie-boilerplate .mod-navigation--main.hide,
.mat-mdc-dialog-container .mod-navigation--main.hide,
.cdk-overlay-container .mod-navigation--main.hide,
.cdk-drag-preview .mod-navigation--main.hide {
  display: none;
}
#sie-boilerplate .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul,
.mat-mdc-dialog-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul,
.cdk-overlay-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul,
.cdk-drag-preview .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul {
  list-style: none;
  position: relative;
  width: 100%;
}
@media print, only screen and (min-width: 1024px) {
  #sie-boilerplate .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul,
  .mat-mdc-dialog-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul,
  .cdk-overlay-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul,
  .cdk-drag-preview .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul {
    flex-direction: row;
  }
}
#sie-boilerplate .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li,
.mat-mdc-dialog-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li,
.cdk-overlay-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li,
.cdk-drag-preview .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li {
  position: relative;
  width: 100%;
  font-family: "Siemens Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-secondary-color-16);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.15s ease-in-out;
  cursor: pointer;
  position: relative;
  padding: 0 20px;
}
@media print, only screen and (min-width: 1024px) {
  #sie-boilerplate .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li,
  .mat-mdc-dialog-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li,
  .cdk-overlay-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li,
  .cdk-drag-preview .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li {
    font-size: 18px;
    line-height: 24px;
  }
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li,
  .mat-mdc-dialog-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li,
  .cdk-overlay-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li,
  .cdk-drag-preview .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li {
    font-family: "Siemens Sans", sans-serif;
    font-size: 17.99px;
    line-height: 25.7px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-secondary-color-16);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li > a,
.mat-mdc-dialog-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li > a,
.cdk-overlay-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li > a,
.cdk-drag-preview .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li > a {
  width: 100%;
  padding: 20px 50px 17px 0;
  display: block;
  transition: all 0.15s ease-in-out;
}
#sie-boilerplate .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li input[type=checkbox] + label:after,
.mat-mdc-dialog-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li input[type=checkbox] + label:after,
.cdk-overlay-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li input[type=checkbox] + label:after,
.cdk-drag-preview .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li input[type=checkbox] + label:after {
  width: 15px;
  height: 12px;
}
#sie-boilerplate .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:before,
.mat-mdc-dialog-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:before,
.cdk-overlay-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:before,
.cdk-drag-preview .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:before {
  content: "";
  left: 20px;
  bottom: -1px;
  width: calc(100% - 40px);
  height: 1px;
  background: var(--ceres-primary-color-7);
  position: absolute;
  pointer-events: none;
}
#sie-boilerplate .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:after,
.mat-mdc-dialog-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:after,
.cdk-overlay-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:after,
.cdk-drag-preview .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:after {
  content: "";
  top: 50%;
  right: 19px;
  width: 9px;
  height: 15px;
  background-size: 9px 15px;
  transform: translate(0, -8px);
  position: absolute;
  transition: opacity 0.15s ease-in-out;
  pointer-events: none;
}
#sie-boilerplate .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover,
.mat-mdc-dialog-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover,
.cdk-overlay-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover,
.cdk-drag-preview .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover {
  background: var(--ceres-secondary-color-5);
}
#sie-boilerplate .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover:before,
.mat-mdc-dialog-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover:before,
.cdk-overlay-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover:before,
.cdk-drag-preview .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover:before {
  content: none;
}
#sie-boilerplate .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover:after,
.mat-mdc-dialog-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover:after,
.cdk-overlay-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover:after,
.cdk-drag-preview .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover:after {
  opacity: 1;
  width: 18px;
  height: 31px;
  background: url("../../svgs/icon-tile-arrow-light.svg") no-repeat;
  background-size: 100%;
  background-size: 9px 15px;
}
#sie-boilerplate .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover:after:before,
.mat-mdc-dialog-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover:after:before,
.cdk-overlay-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover:after:before,
.cdk-drag-preview .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover:after:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#sie-boilerplate .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover a,
#sie-boilerplate .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover label,
.mat-mdc-dialog-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover a,
.mat-mdc-dialog-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover label,
.cdk-overlay-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover a,
.cdk-overlay-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover label,
.cdk-drag-preview .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover a,
.cdk-drag-preview .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover label {
  font-family: "Siemens Sans", sans-serif;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover a,
  #sie-boilerplate .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover label,
  .mat-mdc-dialog-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover a,
  .mat-mdc-dialog-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover label,
  .cdk-overlay-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover a,
  .cdk-overlay-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover label,
  .cdk-drag-preview .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover a,
  .cdk-drag-preview .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover label {
    font-family: "Siemens Sans", sans-serif;
    font-size: 23.13px;
    line-height: 30.84px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-white);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover input[type=checkbox] + label:before,
.mat-mdc-dialog-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover input[type=checkbox] + label:before,
.cdk-overlay-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover input[type=checkbox] + label:before,
.cdk-drag-preview .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover input[type=checkbox] + label:before {
  border: 1px solid var(--ceres-white) !important;
}
#sie-boilerplate .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover input[type=checkbox] + label:after,
.mat-mdc-dialog-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover input[type=checkbox] + label:after,
.cdk-overlay-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover input[type=checkbox] + label:after,
.cdk-drag-preview .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover input[type=checkbox] + label:after {
  width: 15px;
  height: 12px;
  width: 15px;
  height: 12px;
  background: url("../../svgs/icon-checkbox-check-white.svg") no-repeat;
  background-size: 100%;
}
#sie-boilerplate .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover input[type=checkbox] + label:after:before,
.mat-mdc-dialog-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover input[type=checkbox] + label:after:before,
.cdk-overlay-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover input[type=checkbox] + label:after:before,
.cdk-drag-preview .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li:hover input[type=checkbox] + label:after:before {
  font-family: "siemens-webicons-webfont", sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#sie-boilerplate .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li > span,
.mat-mdc-dialog-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li > span,
.cdk-overlay-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li > span,
.cdk-drag-preview .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li > span {
  position: relative;
}
#sie-boilerplate .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li > span > .m-notification,
.mat-mdc-dialog-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li > span > .m-notification,
.cdk-overlay-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li > span > .m-notification,
.cdk-drag-preview .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li > span > .m-notification {
  right: 0;
  top: 0;
  min-width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 2px solid var(--ceres-white);
  background: var(--ceres-feedback-orange);
  position: absolute;
  font-family: "Siemens Sans Bold", sans-serif;
  font-size: 11px;
  line-height: 1;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(14px, 7px);
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li,
  .mat-mdc-dialog-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li,
  .cdk-overlay-container .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li,
  .cdk-drag-preview .mod-navigation--main-mobile > .m-content > .m-layer > .m-nav > ul > li {
    transform: translateY(-4px);
  }
}
#sie-boilerplate .mod-navigation--main-desktop,
.mat-mdc-dialog-container .mod-navigation--main-desktop,
.cdk-overlay-container .mod-navigation--main-desktop,
.cdk-drag-preview .mod-navigation--main-desktop {
  width: auto;
  display: none;
}
@media print, only screen and (min-width: 1024px) {
  #sie-boilerplate .mod-navigation--main-desktop,
  .mat-mdc-dialog-container .mod-navigation--main-desktop,
  .cdk-overlay-container .mod-navigation--main-desktop,
  .cdk-drag-preview .mod-navigation--main-desktop {
    display: flex;
    width: 60%;
    justify-content: center;
  }
}
#sie-boilerplate .mod-navigation--main-desktop > ul,
.mat-mdc-dialog-container .mod-navigation--main-desktop > ul,
.cdk-overlay-container .mod-navigation--main-desktop > ul,
.cdk-drag-preview .mod-navigation--main-desktop > ul {
  list-style: none;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  /*> .m-active-line {
    bottom: 0;
    height: 4px;
    background: var(--ceres-secondary-color-6);
    position: absolute;
    transition: left 0.4s ease-in-out, width 0.4s ease-in-out;
    display: none;

    @include mq(desktop-s) {
      display: block;
    }
  }*/
}
#sie-boilerplate .mod-navigation--main-desktop > ul > li,
.mat-mdc-dialog-container .mod-navigation--main-desktop > ul > li,
.cdk-overlay-container .mod-navigation--main-desktop > ul > li,
.cdk-drag-preview .mod-navigation--main-desktop > ul > li {
  position: relative;
  margin: 0 15px;
  height: 100%;
  display: flex;
  align-items: center;
}
#sie-boilerplate .mod-navigation--main-desktop > ul > li > span,
.mat-mdc-dialog-container .mod-navigation--main-desktop > ul > li > span,
.cdk-overlay-container .mod-navigation--main-desktop > ul > li > span,
.cdk-drag-preview .mod-navigation--main-desktop > ul > li > span {
  position: relative;
}
#sie-boilerplate .mod-navigation--main-desktop > ul > li > span > a,
.mat-mdc-dialog-container .mod-navigation--main-desktop > ul > li > span > a,
.cdk-overlay-container .mod-navigation--main-desktop > ul > li > span > a,
.cdk-drag-preview .mod-navigation--main-desktop > ul > li > span > a {
  font-family: "Siemens Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-secondary-color-16);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  position: relative;
  display: block;
  transition: color 0.15s ease-in-out;
}
@media print, only screen and (min-width: 1024px) {
  #sie-boilerplate .mod-navigation--main-desktop > ul > li > span > a,
  .mat-mdc-dialog-container .mod-navigation--main-desktop > ul > li > span > a,
  .cdk-overlay-container .mod-navigation--main-desktop > ul > li > span > a,
  .cdk-drag-preview .mod-navigation--main-desktop > ul > li > span > a {
    font-size: 18px;
    line-height: 24px;
  }
}
@media print, only screen and (min-width: 1920px) {
  #sie-boilerplate .mod-navigation--main-desktop > ul > li > span > a,
  .mat-mdc-dialog-container .mod-navigation--main-desktop > ul > li > span > a,
  .cdk-overlay-container .mod-navigation--main-desktop > ul > li > span > a,
  .cdk-drag-preview .mod-navigation--main-desktop > ul > li > span > a {
    font-family: "Siemens Sans", sans-serif;
    font-size: 17.99px;
    line-height: 25.7px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--ceres-secondary-color-16);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
#sie-boilerplate .mod-navigation--main-desktop > ul > li > span > .m-notification,
.mat-mdc-dialog-container .mod-navigation--main-desktop > ul > li > span > .m-notification,
.cdk-overlay-container .mod-navigation--main-desktop > ul > li > span > .m-notification,
.cdk-drag-preview .mod-navigation--main-desktop > ul > li > span > .m-notification {
  right: 0;
  top: 0;
  min-width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 2px solid var(--ceres-white);
  background: var(--ceres-feedback-orange);
  position: absolute;
  font-family: "Siemens Sans Bold", sans-serif;
  font-size: 11px;
  line-height: 1;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--ceres-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(14px, -16px);
}
#sie-boilerplate .mod-navigation--main-desktop > ul > li:hover > a,
.mat-mdc-dialog-container .mod-navigation--main-desktop > ul > li:hover > a,
.cdk-overlay-container .mod-navigation--main-desktop > ul > li:hover > a,
.cdk-drag-preview .mod-navigation--main-desktop > ul > li:hover > a {
  color: var(--ceres-primary-color-3);
}
#sie-boilerplate .mod-logo,
.mat-mdc-dialog-container .mod-logo,
.cdk-overlay-container .mod-logo,
.cdk-drag-preview .mod-logo {
  display: block;
  width: 154px;
  height: 24px;
  background: url("../../svgs/logo-siemens.svg") no-repeat;
  background-size: cover;
}

html,
body {
  height: 100%;
}

html {
  width: 100%;
}

body {
  background: var(--ceres-white);
  margin: 0;
  display: flex;
  flex-direction: column;
  font-family: "Siemens Sans", sans-serif !important;
}
body .mat-mdc-card,
body .mat-mdc-select {
  font-family: "Siemens Sans", sans-serif !important;
}
body.new-wrapper .lay-wrapper--content {
  padding-top: 90px;
  padding-left: 0px !important;
  margin-left: 0px !important;
  padding-bottom: 30px;
  /* width: 95% !important; */
  width: 100% !important;
  padding-right: 60px !important;
}
body.projects {
  background-color: var(--ceres-primary-color-7);
}
body.projects .lay-wrapper--content {
  top: 90px !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  margin-left: 0px !important;
  /* width: 95% !important; */
  width: 100% !important;
  padding-right: 60px !important;
}

.filter-container {
  margin-bottom: 12px;
  padding: 32px;
  background-color: var(--ceres-primary-color-7);
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.filter-container .title {
  min-height: 34px;
  color: var(--ceres-primary-color-2);
  font-size: 20px;
  line-height: 24px;
  border-bottom: 2px solid var(--ceres-primary-color-1);
}
.filter-container .search-container {
  width: 100%;
  max-width: 250px;
  position: relative;
  padding-top: 8px;
}
.filter-container .search-container input {
  padding: 0 30px 10px 0;
  background: transparent;
  border: none;
  border-bottom: 2px solid var(--bs-gray-500);
  width: 100%;
  color: var(--ceres-input);
}
.filter-container .search-container input:focus {
  outline: none;
  border-bottom: 2px solid var(--ceres-primary-color-1);
}
.filter-container .search-container .icon {
  position: absolute;
  right: 5px;
}

.inline-navbar .navbar-nav-item {
  background-color: var(--ceres-primary-color-7);
  color: var(--ceres-primary-color-2);
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  padding-top: 8px;
  padding-left: 12px;
  padding-right: 12px;
  border-bottom: 2px solid var(--bs-gray-500);
}
.inline-navbar .navbar-nav-item.active {
  border-bottom: 2px solid var(--ceres-primary-color-1);
}
.inline-navbar .navbar-nav-item:hover {
  cursor: pointer;
  filter: brightness(0.95);
}
.inline-navbar .navbar-actions {
  background-color: var(--ceres-primary-color-7);
  border-bottom: 2px solid var(--ceres-primary-color-1);
}
.inline-navbar .navbar-actions:hover {
  cursor: pointer;
  filter: brightness(0.95);
}

.default-title {
  min-height: 34px;
  color: var(--ceres-primary-color-2);
  font-size: 20px;
  line-height: 24px;
  border-bottom: 2px solid var(--ceres-primary-color-1);
}

.search-container-standalone {
  width: 100%;
  max-width: 250px;
  position: relative;
}
.search-container-standalone input {
  padding: 0 30px 10px 0;
  background: transparent;
  border: none;
  border-bottom: 2px solid var(--bs-gray-500);
  width: 100%;
}
.search-container-standalone input:focus {
  outline: none;
  border-bottom: 2px solid var(--ceres-primary-color-1);
}
.search-container-standalone .icon {
  position: absolute;
  right: 5px;
}

.list-filter-row {
  min-height: 60px;
}
.list-filter-row .title {
  color: var(--ceres-primary-color-2);
  font-size: 24px;
}
.list-filter-row .button-container {
  height: 50px;
  justify-content: flex-end;
  display: flex;
  padding-right: 0 !important;
}
.list-filter-row .search-container {
  margin-right: auto;
  width: 300px;
}

.list-container {
  margin-left: auto;
  margin-right: auto;
}

.list-container-tiles {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

/* Disbaled button */
button:disabled {
  cursor: default !important;
  opacity: 0.6;
}

[ng-reflect-router-link] {
  cursor: pointer;
}

.mat-mdc-checkbox-checked .mat-mdc-checkbox-background,
.mat-mdc-checkbox-indeterminate .mat-mdc-checkbox-background {
  background-color: var(--ceres-primary-color-3) !important;
}

.mat-pseudo-checkbox-checked,
.mat-ink-bar {
  background-color: var(--ceres-primary-color-3) !important;
}

.mat-mdc-checkbox.mat-mdc-checkbox-semichecked .mat-mdc-checkbox-background {
  z-index: 1;
  background-color: var(--ceres-primary-color-3);
  margin: 4px;
}
.mat-mdc-checkbox.mat-mdc-checkbox-semichecked .mat-mdc-checkbox-frame {
  z-index: 2;
  border-color: var(--ceres-black-opaque-54);
}

#contentRow #sideNavBox {
  display: none;
}
#contentRow #contentBox {
  margin-left: 0;
}

.mat-mdc-form-field span {
  overflow: visible;
}

.mat-mdc-tooltip.bigger {
  font-size: 1rem;
}

#sie-boilerplate .mat-mdc-header-row,
.mat-mdc-dialog-container .mat-mdc-header-row {
  position: sticky;
  top: 0;
  background: var(--ceres-white);
  z-index: 100;
}
#sie-boilerplate .mat-column-MPNumber,
.mat-mdc-dialog-container .mat-column-MPNumber {
  flex-basis: 2.5rem;
}
#sie-boilerplate mat-header-cell .mat-sort-header-container,
.mat-mdc-dialog-container mat-header-cell .mat-sort-header-container {
  display: inline-flex;
}
#sie-boilerplate mat-header-cell.filter-active,
.mat-mdc-dialog-container mat-header-cell.filter-active {
  color: var(--ceres-primary-color-1);
}
#sie-boilerplate mat-header-cell.pull-right,
.mat-mdc-dialog-container mat-header-cell.pull-right {
  float: right;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  text-align: right;
}
#sie-boilerplate ceres-collapse-item mat-form-field.mat-custom .mat-mdc-form-field-wrapper,
.mat-mdc-dialog-container ceres-collapse-item mat-form-field.mat-custom .mat-mdc-form-field-wrapper {
  width: 100% !important;
}
#sie-boilerplate mat-cell .mat-mdc-form-field-wrapper,
#sie-boilerplate mat-footer-cell .mat-mdc-form-field-wrapper,
.mat-mdc-dialog-container mat-cell .mat-mdc-form-field-wrapper,
.mat-mdc-dialog-container mat-footer-cell .mat-mdc-form-field-wrapper {
  width: 95%;
}
#sie-boilerplate mat-cell .mat-mdc-form-field-infix,
#sie-boilerplate mat-footer-cell .mat-mdc-form-field-infix,
.mat-mdc-dialog-container mat-cell .mat-mdc-form-field-infix,
.mat-mdc-dialog-container mat-footer-cell .mat-mdc-form-field-infix {
  width: auto;
}
#sie-boilerplate mat-cell .mat-mdc-checkbox-layout,
#sie-boilerplate mat-footer-cell .mat-mdc-checkbox-layout,
.mat-mdc-dialog-container mat-cell .mat-mdc-checkbox-layout,
.mat-mdc-dialog-container mat-footer-cell .mat-mdc-checkbox-layout {
  display: table-row;
}
#sie-boilerplate mat-cell .pull-right,
#sie-boilerplate mat-footer-cell .pull-right,
.mat-mdc-dialog-container mat-cell .pull-right,
.mat-mdc-dialog-container mat-footer-cell .pull-right {
  float: right;
  padding-right: 1.2rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
#sie-boilerplate mat-cell .center-checkbox,
#sie-boilerplate mat-footer-cell .center-checkbox,
.mat-mdc-dialog-container mat-cell .center-checkbox,
.mat-mdc-dialog-container mat-footer-cell .center-checkbox {
  display: block;
  text-align: center;
}

pwo-filter button {
  cursor: pointer !important;
}

.cdk-overlay-pane {
  max-width: 100% !important;
}
.cdk-overlay-pane mat-dialog-content {
  max-height: 100%;
}

.mat-mdc-button-toggle-label-content {
  line-height: 28px !important;
}

ceres-partial-occupation-chart .mat-mdc-select {
  background-color: var(--ceres-primary-color-4);
  height: 35px;
}
ceres-partial-occupation-chart .mat-mdc-select:hover {
  background-color: var(--ceres-secondary-color-6);
  color: var(--ceres-white);
}
ceres-partial-occupation-chart .mat-mdc-select:hover .mat-mdc-select-value-text {
  color: var(--ceres-white);
}
ceres-partial-occupation-chart .mat-mdc-select .mat-mdc-select-value {
  padding: 10px !important;
}
ceres-partial-occupation-chart .mat-mdc-select .mat-mdc-select-value-text {
  font-size: 13px !important;
}
ceres-partial-occupation-chart .mat-mdc-select .mat-mdc-select-arrow {
  margin: 0px 10px 0px 4px;
}
ceres-partial-occupation-chart .mat-mdc-select i {
  padding: 5px 10px 0px 0px;
}

ceres-gms-reporting .mat-mdc-card-header-text {
  margin: 0px !important;
}

.mat-mdc-progress-bar-fill::after {
  background-color: var(--ceres-secondary-color-5);
}

.mat-mdc-progress-bar-buffer {
  background: var(--ceres-primary-color-7);
}

.mat-mdc-progress-bar {
  border-radius: 2px;
}

.mat-mdc-progress-bar-background {
  fill: none;
}

.label {
  font-size: 12px;
  color: var(--ceres-primary-color-5);
}
.label.notValid {
  color: var(--ceres-secondary-color-14);
}

.mat-mdc-card {
  background: transparent;
}

.mod-header {
  width: 92.5%;
  display: block;
  transition: max-width 0.25s ease-in-out;
}

.custom-dialog mat-dialog-container {
  padding: 0;
  overflow: auto;
  background: var(--ceres-black-opaque-0);
  box-shadow: unset;
}

.content-form-container .pwo-form .mat-mdc-form-field-label-wrapper {
  box-sizing: border-box !important;
}

.templates-spinner-container circle {
  stroke: var(--ceres-white) !important;
}

.offerTemplateTooltip {
  font-size: 15px !important;
}

mat-tree-node mat-checkbox.mat-mdc-checkbox label.mat-mdc-checkbox-layout span.mat-mdc-checkbox-label {
  bottom: 2px;
  left: 12px;
  position: relative;
}

mat-tree-node mat-checkbox.mat-mdc-checkbox span.mat-mdc-checkbox-inner-container {
  margin-top: 8px;
}

.nav-list {
  width: 90%;
}
.nav-list .nav-tile {
  cursor: pointer;
}
.nav-list .nav-tile mat-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.nav-list .nav-tile mat-card mat-card-content {
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  -webkit-box-shadow: 3px 12px 19px -4px var(--ceres-black-opaque-35);
  -moz-box-shadow: 3px 12px 19px -4px var(--ceres-black-opaque-35);
  box-shadow: 3px 12px 19px -4px var(--ceres-black-opaque-35);
  background: var(--ceres-primary-color-3);
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ceres-white);
  overflow: hidden;
}
.nav-list .nav-tile mat-card mat-card-content .tile-icon {
  text-align: center;
  width: 100%;
  display: inline-block;
  padding: 0;
}
.nav-list .nav-tile mat-card mat-card-content .tile-icon h4, .nav-list .nav-tile mat-card mat-card-content .tile-icon .h4 {
  color: var(--ceres-white) !important;
}
.nav-list .nav-tile mat-card mat-card-content .tile-icon i {
  font-size: 3.5vw;
}
.nav-list .nav-tile mat-card mat-card-content .tile-icon .caption {
  display: none;
  font-size: 14px !important;
  padding: 0 20px !important;
  color: var(--ceres-white) !important;
}
.nav-list .nav-tile mat-card mat-card-content:hover .tile-icon {
  width: 100%;
}
.nav-list .nav-tile mat-card mat-card-content:hover .icon.a-icon {
  animation-name: slideOutLeft;
  animation-duration: 0.6s;
  animation-fill-mode: both;
}
.nav-list .nav-tile mat-card mat-card-content:hover .icon.a-icon::before {
  animation-name: resize;
  animation-duration: 0.6s;
  animation-fill-mode: both;
}
.nav-list .nav-tile mat-card mat-card-content:hover .icon.material-icons {
  animation-name: slideOutLeft, resize;
  animation-duration: 0.6s, 0.6s;
  animation-fill-mode: both, both;
}
@keyframes slideOutLeft {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
@keyframes resize {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.7);
  }
}
.nav-list .nav-tile mat-card mat-card-content:hover h4, .nav-list .nav-tile mat-card mat-card-content:hover .h4 {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-30%);
    transform: translateY(-30%);
  }
}
@keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-30%);
    transform: translateY(-30%);
  }
}
.nav-list .nav-tile mat-card mat-card-content:hover .caption {
  display: block !important;
  width: 100%;
  float: left;
  text-align: left;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes Wait-And-Start {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(200%);
  }
}
.nav-list .nav-tile mat-card mat-card-actions {
  padding-top: 0 !important;
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  font-size: small;
}

.justify-self-end {
  justify-self: end;
}

ceres-value-picker.custom-style-picker input.mat-input-element {
  height: 12px !important;
}
ceres-value-picker.custom-style-picker button.ceres-button {
  margin-right: -20px;
}

[appearance=table-date-range] .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex {
  background-color: var(--ceres-white);
  align-items: center;
  justify-content: center;
}

[appearance=table-date-range-footer] .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex {
  background-color: var(--ceres-shaded-color);
  align-items: center;
  justify-content: center;
}

.custom-input-invalid .mat-mdc-text-field-wrapper {
  border: 1px solid var(--ceres-feedback-red) !important;
}

.custom-static-white {
  color: var(--ceres-static-white) !important;
}

.custom-text-color {
  color: var(--ceres-input) !important;
}

body[data-theme=dark] #sie-boilerplate .table-container {
  border: none;
}
body[data-theme=dark] #sie-boilerplate .table-container .mat-mdc-table {
  background: transparent;
}
body[data-theme=dark] #sie-boilerplate .table-container .mat-mdc-table .mat-mdc-cell {
  color: var(--ceres-static-white);
}
body[data-theme=dark] #sie-boilerplate .table-container .mat-mdc-table mat-header-row {
  background: var(--ceres-white);
  border: none;
}
body[data-theme=dark] #sie-boilerplate .table-container .mat-mdc-table mat-row {
  background: var(--ceres-white);
  border-top: 1px solid var(--ceres-primary-color-8);
  border-bottom: none;
}
body[data-theme=dark] #sie-boilerplate .table-container .mat-mdc-table:not(.not-striped) .mat-mdc-row:nth-child(even) {
  background-color: var(--ceres-primary-color-8);
}
body[data-theme=dark] #sie-boilerplate .table-container .mat-mdc-table:not(.not-striped) .mat-mdc-row:nth-child(odd) {
  background-color: var(--ceres-white);
}
body[data-theme=dark] #sie-boilerplate mat-paginator {
  background: transparent;
  color: var(--ceres-input);
}
body[data-theme=dark] #sie-boilerplate mat-paginator .mat-mdc-select-min-line,
body[data-theme=dark] #sie-boilerplate mat-paginator .mat-mdc-select-arrow {
  color: var(--ceres-input);
}
body[data-theme=dark] #sie-boilerplate .menu-wrapper li a:hover,
body[data-theme=dark] #sie-boilerplate .menu-wrapper li.active,
body[data-theme=dark] #sie-boilerplate .menu-wrapper li.open {
  background: transparent;
  color: var(--ceres-primary-color-1);
}
body[data-theme=dark] #sie-boilerplate .menu-wrapper li a div:hover,
body[data-theme=dark] #sie-boilerplate .menu-wrapper li.active a div {
  background: transparent;
  color: var(--ceres-primary-color-1);
  border-color: var(--ceres-primary-color-1);
}
body[data-theme=dark] #sie-boilerplate .menu-wrapper li.active a,
body[data-theme=dark] #sie-boilerplate .menu-wrapper li.open > a {
  color: var(--ceres-primary-color-1);
}
body[data-theme=dark] #sie-boilerplate .tile-container .tile-content {
  background: var(--ceres-sidenav-color);
}
body[data-theme=dark] #sie-boilerplate .filter-container {
  background: transparent;
}
body[data-theme=dark] #sie-boilerplate .inline-navbar .navbar-nav-item {
  background: var(--ceres-white);
}
body[data-theme=dark] #sie-boilerplate .inline-navbar .navbar-nav-item:hover {
  filter: brightness(0.8);
}
body[data-theme=dark] #sie-boilerplate .inline-navbar .navbar-actions {
  background: var(--ceres-white);
  color: var(--ceres-input);
}
body[data-theme=dark] #sie-boilerplate .inline-navbar .navbar-actions:hover {
  filter: brightness(0.8);
}
body[data-theme=dark] #sie-boilerplate .form-container .options-form {
  background-color: var(--ceres-sidenav-color);
}
body[data-theme=dark] #sie-boilerplate .mat-custom.mat-mdc-form-field-appearance-fill mat-datepicker-toggle button {
  color: var(--ceres-primary-color-1);
}
body[data-theme=dark] #sie-boilerplate mat-checkbox .mat-mdc-checkbox-label {
  color: var(--ceres-input);
}
body[data-theme=dark] #sie-boilerplate mat-checkbox .mat-mdc-checkbox-frame {
  border-color: var(--ceres-input);
}
body[data-theme=dark] #sie-boilerplate mat-checkbox.mat-mdc-checkbox-disabled .mat-mdc-checkbox-label {
  color: var(--ceres-white-opaque);
}
body[data-theme=dark] #sie-boilerplate mat-checkbox.mat-mdc-checkbox-disabled .mat-mdc-checkbox-frame {
  border-color: var(--ceres-white-opaque);
}
body[data-theme=dark] #sie-boilerplate mat-chip-grid.mat-mdc-form-field-label {
  color: var(--ceres-input);
}
body[data-theme=dark] #sie-boilerplate .search-container-standalone mat-icon,
body[data-theme=dark] #sie-boilerplate .search-container-standalone input[type=search],
body[data-theme=dark] #sie-boilerplate .search-container mat-icon,
body[data-theme=dark] #sie-boilerplate .search-container input[type=search] {
  color: var(--ceres-input);
}
body[data-theme=dark] #sie-boilerplate .new-view-container .tab-content-container {
  background-color: var(--ceres-sidenav-color);
}
body[data-theme=dark] #sie-boilerplate .back-button,
body[data-theme=dark] #sie-boilerplate .project-info .back-button {
  background-color: var(--ceres-sidenav-color);
}
body[data-theme=dark] #sie-boilerplate ceres-layout.service .content--container {
  background-color: var(--ceres-sidenav-color);
}
body[data-theme=dark] #sie-boilerplate .mod-logo {
  filter: brightness(10) !important;
}
body[data-theme=dark] #sie-boilerplate .mat-radio-label-content,
body[data-theme=dark] #sie-boilerplate .mat-mdc-slide-toggle-content {
  color: var(--ceres-static-white);
}
body[data-theme=dark] #sie-boilerplate ceres-module .module-container .task-container .percentage,
body[data-theme=dark] #sie-boilerplate ceres-module .module-container .module .part2 {
  background-color: var(--ceres-sidenav-color);
}
body[data-theme=dark] #sie-boilerplate ceres-module .module-container .task-container .activityTitle {
  color: var(--ceres-static-white);
}
body[data-theme=dark] #sie-boilerplate ceres-edit-psp-element mat-table .mat-mdc-row.selected mat-cell {
  backdrop-filter: brightness(1.6);
}
body[data-theme=dark] #sie-boilerplate ceres-edit-psp-element mat-table .mat-mdc-row:hover mat-cell {
  backdrop-filter: brightness(1.35);
}

body[data-theme=dark].projects {
  background-color: var(--ceres-sidenav-color) !important;
}

body[data-theme=dark] mat-dialog-container .table-container mat-header-row {
  background: var(--ceres-white);
  border: none;
}
body[data-theme=dark] mat-dialog-container .form-container .options-form {
  background-color: var(--ceres-white);
}
body[data-theme=dark] mat-dialog-container .form-container .options-form--header {
  background-color: var(--ceres-white);
}
body[data-theme=dark] .list-filter-row {
  background-color: transparent !important;
}

.mod-logo > a {
  width: 121px;
  height: 19px;
  background: url("../../../../generic/svgs/logo-siemens.svg") no-repeat;
  background-size: 100%;
}